<template>
  <div class="main">
    <top-header :title="$t('trainStation')" />
    <div class="container-fluid">
      <box style="margin-top: 15px; position: relative">
        <div class="row">
          <div class="col-md-6">
            <search-input :searched="tpl" :datum="date" @onPress="searchTrainStation" :showDatePicker="false"
              @toggle="toggleStation" />
            <radio-input :selected="typ" @changed="typChanged" />
          </div>
        </div>
      </box>
      <p class="lastupdate" v-if="updated"> <img src="@/assets/pin-yellow.png" alt="1409" height="22"
          style="margin-top: -3px" />

        {{ tpl }}. <span style="font-weight: 300;"> {{ $t("curUpdate") }}:
          {{ updated }}</span>
      </p>
      <div class="train-station table-responsive-md">
        <table class="table table-striped" :class="lang" v-if="typ === 'avg'">
          <thead>
            <tr>
              <th scope="col">{{ $t("table.avg") }}</th>
              <th scope="col">{{ $t("table.till") }}</th>
              <th scope="col">{{ $t("table.spar") }}</th>
              <th scope="col">{{ $t("table.operator") }}</th>
              <th scope="col" style="text-align: right">
                {{ $t("table.tag") }}
              </th>
              <th scope="col" class="optional"></th>
              <th scope="col">{{ $t("table.pos") }}</th>
              <th scope="col">{{ $t("table.status") }}</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody v-if="avgdata.length > 0">
            <tr v-for="(avg, index) in avgdata" :key="index">
              <td>
                <span :class="{ str: avg.bertid || avg.sp == 'Inst.' }">{{
                  avg.avgtid
                }}</span><br v-if="avg.bertid" />
                <em><strong>{{ avg.bertid ? avg.bertid : "" }}</strong></em>
              </td>
              <td>{{ avg.station }}</td>
              <td>
                <strong>{{ avg.sp }}</strong>
              </td>
              <td class="koncept">
                <img width="24" :src="getOperatorImage(avg.operator)" />
                {{ avg.koncept }}
              </td>
              <td class="trainNr" style="text-align: right">
                <router-link :to="{
                  name: 'position',
                  query: { tagNr: avg.tagNr, datum: avg.datum, lang: lang },
                }">{{ avg.tagNr }}</router-link>
                <span class="nobr" id="mobilerating">
                  <br />
                  <i class="bi bi-star-half" v-if="avg.rating == 1"></i>
                  <i class="bi bi-star-fill" v-if="avg.rating > 1"></i>
                  <i class="bi bi-star-half" v-if="avg.rating == 3"></i>
                  <i class="bi bi-star-fill" v-if="avg.rating > 3"></i>
                  <i class="bi bi-star-half" v-if="avg.rating == 5"></i>
                  <i class="bi bi-star-fill" v-if="avg.rating > 5"></i>
                  <i class="bi bi-star-half" v-if="avg.rating == 7"></i>
                  <i class="bi bi-star-fill" v-if="avg.rating > 7"></i>
                  <i class="bi bi-star-half" v-if="avg.rating == 9"></i>
                  <i class="bi bi-star-fill" v-if="avg.rating > 9"></i>
                </span>
              </td>
              <td class="optional">
                <span class="nobr">
                  <i class="bi bi-star-half" v-if="avg.rating == 1"></i>
                  <i class="bi bi-star-fill" v-if="avg.rating > 1"></i>
                  <i class="bi bi-star-half" v-if="avg.rating == 3"></i>
                  <i class="bi bi-star-fill" v-if="avg.rating > 3"></i>
                  <i class="bi bi-star-half" v-if="avg.rating == 5"></i>
                  <i class="bi bi-star-fill" v-if="avg.rating > 5"></i>
                  <i class="bi bi-star-half" v-if="avg.rating == 7"></i>
                  <i class="bi bi-star-fill" v-if="avg.rating > 7"></i>
                  <i class="bi bi-star-half" v-if="avg.rating == 9"></i>
                  <i class="bi bi-star-fill" v-if="avg.rating > 9"></i>
                </span>
              </td>
              <td>
                {{ avg.curtpl ? avg.curtpl : ""
                }}{{ avg.nexttpl ? " - " + avg.nexttpl : "" }}
                {{ avg.speed ? "(" + avg.speed + " km/h)" : "" }}
              </td>
              <td class="ti" v-bind:class="{ sen: avg.delay < 0, tidig: avg.delay >= 0 }">
                {{
                  avg.curtpl
                  ? avg.delay < 0 ? avg.delay == -1 ? "1" + $t("table.enminsen") : Math.abs(avg.delay) + $t("table.minsen") :
                    avg.delay == 0 ? $t("table.itid") : avg.delay == 1 ? avg.delay + $t("table.enmintid") : avg.delay +
                      $t("table.mintid") : "" }} <span v-if="avg.inst && false" class="sen"><br
                    v-if="avg.delay != null" />Inställt {{ avg.inst }}.</span>
              </td>
              <td :class="lang">{{ avg.beskrivning }}</td>
            </tr>
            <tr class="loading" v-if="avgdata.length == 0 && loading">
              <td colspan="10">{{ $t("app.loading") }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table table-striped" :class="lang" v-if="typ == 'ank'">
          <thead>
            <tr>
              <th scope="col">{{ $t("table.ank") }}</th>
              <th scope="col">{{ $t("table.fran") }}</th>
              <th scope="col">{{ $t("table.spar") }}</th>
              <th scope="col">{{ $t("table.operator") }}</th>
              <th scope="col" style="text-align: right">
                {{ $t("table.tag") }}
              </th>
              <th scope="col" class="optional"></th>
              <th scope="col">{{ $t("table.pos") }}</th>
              <th scope="col">{{ $t("table.status") }}</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody v-if="ankdata.length > 0">
            <tr v-for="(ank, index) in ankdata" :key="index">
              <td>
                <span :class="{ str: ank.bertid || ank.sp == 'Inst.' }">{{
                  ank.avgtid
                }}</span><br v-if="ank.bertid" />
                <em><strong>{{ ank.bertid ? ank.bertid : "" }}</strong></em>
              </td>
              <td>{{ ank.station }}</td>
              <td>
                <strong>{{ ank.sp }}</strong>
              </td>
              <td class="koncept">
                <img width="24" :src="getOperatorImage(ank.operator)" />
                {{ ank.koncept }}
              </td>
              <td class="trainNr" style="text-align: right">
                <router-link :to="{
                  name: 'position',
                  query: { tagNr: ank.tagNr, datum: ank.datum, lang: lang },
                }">{{ ank.tagNr }}</router-link>
                <span class="nobr" id="mobilerating">
                  <br />
                  <i class="bi bi-star-half" v-if="ank.rating == 1"></i>
                  <i class="bi bi-star-fill" v-if="ank.rating > 1"></i>
                  <i class="bi bi-star-half" v-if="ank.rating == 3"></i>
                  <i class="bi bi-star-fill" v-if="ank.rating > 3"></i>
                  <i class="bi bi-star-half" v-if="ank.rating == 5"></i>
                  <i class="bi bi-star-fill" v-if="ank.rating > 5"></i>
                  <i class="bi bi-star-half" v-if="ank.rating == 7"></i>
                  <i class="bi bi-star-fill" v-if="ank.rating > 7"></i>
                  <i class="bi bi-star-half" v-if="ank.rating == 9"></i>
                  <i class="bi bi-star-fill" v-if="ank.rating > 9"></i>
                </span>
              </td>
              <td class="optional">
                <i class="bi bi-star-half" v-if="ank.rating == 1"></i>
                <i class="bi bi-star-fill" v-if="ank.rating > 1"></i>
                <i class="bi bi-star-half" v-if="ank.rating == 3"></i>
                <i class="bi bi-star-fill" v-if="ank.rating > 3"></i>
                <i class="bi bi-star-half" v-if="ank.rating == 5"></i>
                <i class="bi bi-star-fill" v-if="ank.rating > 5"></i>
                <i class="bi bi-star-half" v-if="ank.rating == 7"></i>
                <i class="bi bi-star-fill" v-if="ank.rating > 7"></i>
                <i class="bi bi-star-half" v-if="ank.rating == 9"></i>
                <i class="bi bi-star-fill" v-if="ank.rating > 9"></i>
              </td>
              <td>
                {{ ank.curtpl ? ank.curtpl : ""
                }}{{ ank.nexttpl ? " - " + ank.nexttpl : "" }}
                {{ ank.speed ? "(" + ank.speed + " km/h)" : "" }}
              </td>
              <td class="ti" v-bind:class="{ sen: ank.delay < 0, tidig: ank.delay >= 0 }">
                {{
                  ank.curtpl
                  ? ank.delay < 0 ? ank.delay == -1 ? "1" + $t("table.enminsen") : Math.abs(ank.delay) + $t("table.minsen") :
                    ank.delay == 0 ? $t("table.itid") : ank.delay == 1 ? ank.delay + $t("table.enmintid") : ank.delay +
                      $t("table.mintid") : "" }} </td>
              <td>{{ ank.beskrivning }}</td>
            </tr>
            <tr class="loading" v-if="avgdata.length == 0 && loading">
              <td colspan="10">{{ $t("app.loading") }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TopHeader from "@/components/TopHeader";
import SearchInput from "@/components/SearchInput";
import RadioInput from "@/components/RadioInput";
import SelectLanguage from "@/components/SelectLanguage";
import Box from "@/components/Box";
import { mapGetters, mapState } from "vuex";
export default {
  name: "station",
  props: ["urltpl", "urltyp"],
  metaInfo() {
    const title = this.title;
    return {
      title: title,
    };
  },

  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      tpl: "",
      typ: "",
      avgdata: [],
      ankdata: [],
      loading: false,
      title: this.$i18n.t("trainStation"),
      spinfoInterval: null,
      updated: "",
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
    ...mapGetters({
      lang: "getLang",
    }),
  },

  components: {
    TopHeader,
    Box,
    SearchInput,
    RadioInput,
    SelectLanguage,
  },

  created() {
    if (this.$route.query.lang) {
      this.$store.commit("SET_LOCAL_LANGUAGE", this.$route.query.lang);
    }
    this.tpl = this.urltpl;
    this.typ = this.urltyp;
    this.$i18n.local = this.lang;
    this.fetchTrainStations();
  },

  beforeUnmount() {
    clearTimeout(this.spinfoInterval);
  },

  methods: {
    getOperatorImage(operator) {
      var image = "";
      switch (operator) {
        case "FlixTrain":
          image = "ft";
          break;
        case "Krösatåg (JLT)":
          image = "kt";
          break;
        case "MTRX":
          image = "mx";
          break;
        case "Mälardalstrafik":
          image = "mt";
          break;
        case "SJ":
          image = "sj";
          break;
        case "Skånetrafiken":
          image = "sk";
          break;
        case "SL":
          image = "sl";
          break;
        case "Snälltåget":
          image = "st";
          break;
        case "TÅGAB":
          image = "tb";
          break;
        case "Upptåget":
          image = "ul";
          break;
        case "Vy":
          image = "vy";
          break;
        case "Västtrafik":
          image = "vt";
          break;
        case "Öresundståg":
          image = "ot";
          break;
        case "Östgötatrafiken":
          image = "op";
          break;
        default:
          image = "1409";
          break;
      }
      return require("@/assets/operators/" + image + ".png");
    },
    toggleStation(val, selectedDate) {
      if (val && !isNaN(val)) {
        this.$router.push({
          name: "position",
          query: { tagNr: val, datum: selectedDate, lang: this.lang },
        });
      }
      if (val && isNaN(val)) {
        this.tpl = val;
        this.date = selectedDate;
        this.fetchTrainStations();
        this.$router.push({
          name: "station",
          params: { urltpl: val, urltyp: this.typ },
          query: { lang: this.lang },
        });
      }
    },

    typChanged(val) {
      this.typ = val;
      this.$router.push({
        name: "station",
        params: { urltpl: this.tpl, urltyp: val },
        query: { lang: this.lang },
      });
      this.updateTitle();
    },

    searchTrainStation(value, selectedDate) {
      if (value && !isNaN(value)) {
        this.$router.push({
          name: "position",
          query: { tagNr: value, datum: selectedDate, lang: this.lang },
        });
      }
      if (value && isNaN(value)) {
        this.tpl = value;
        this.date = selectedDate;
        this.fetchTrainStations();
        this.$router.push({
          name: "station",
          params: { urltpl: this.tpl, urltyp: this.typ },
          query: { lang: this.lang },
        });
      }
    },

    fetchTrainStations() {
      if (this.tpl) {
        this.$axios
          .get(
            this.baseUrl + "spinfo.php?tpl=" + this.tpl + "&lang=" + this.lang,
            { withCredentials: true }
          )
          .then((res) => {
            this.avgdata = res.data.avg;
            this.ankdata = res.data.ank;
            this.tpl = res.data.tpl;
            this.updateTitle();
            this.updated = moment().format("HH:mm:ss");
            this.spinfoInterval = setTimeout(() => {
              this.fetchTrainStations();
            }, 60000);
          });
      }
    },

    updateTitle() {
      if (this.typ === "ank") {
        this.title = this.$i18n.t("table.anktag") + " " + this.tpl + " - 1409";
      } else {
        this.title = this.$i18n.t("table.avgtag") + " " + this.tpl + " - 1409";
      }
    },

    setLanguage(lang) {
      this.$i18n.local = lang;
    },

    onLanguageSelected(lang) {
      this.$i18n.local = lang;
      this.$store.commit("SET_LOCAL_LANGUAGE", lang);
      this.updateTitle();
      this.fetchTrainStations();
    },
  },
};
</script>

<style lang="scss" scoped>
#mobilerating {
  display: none;
}

.nobr {
  white-space: nowrap;
}

.bi {
  color: #ffcc00bd;
  font-size: 8px;
}

.str {
  text-decoration: line-through;
}

.container-fluid {
  padding-bottom: 150px;

  .lastupdate {
    background: var(--background-color-secondary);
    padding: 10px;
    border-radius: 2px;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;

    span {
      font-weight: 600;
      font-size: 18px;
    }
  }

  .train-station {
    background: var(--background-color-secondary);
    padding: 15px;
    margin-top: 15px;

    .table {
      color: var(--text-primary-color);

      thead th {
        border-bottom: 0;
      }

      tbody tr {

        .sen,
        .tidig {
          font-weight: 600;
          font-size: 15px;
        }

        .sen {
          color: #e74c3c;
        }

        .tidig {
          color: #27ae60;
        }

        .koncept {
          font-size: 15px;
        }

        .trainNr {
          a {
            font-weight: 600;
            color: #148f77;
          }
        }
      }
    }

    .table.ti {

      tr th,
      tr .ti {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 675.98px) {
    padding: 0px;

    .train-station {
      margin: 15px 0;
      padding: 5px 3px;
    }

    .table {
      thead th {
        font-size: 0.59rem;
        padding: 10px 2px;
        border: 0;
      }

      tbody {
        tr>td {
          font-size: 0.72rem;
          padding: 5px 1px;
        }

        tr>th {
          padding: 5px 1px;
        }

        .trainNr {
          font-size: 0.57rem;
        }
      }
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    .optional {
      display: none;
    }

    #inlinerating {
      display: inline;
    }
  }

  @media (max-width: 1199.98px) {
    .select {
      float: none;
      min-width: 100%;

      select {
        min-width: 100%;
      }
    }
  }
}
</style>
