<template>
  <div class="main" :class="lang">
    <top-header title="Trafikstörningsarkiv" />
    <div class="d-flex justify-content-start align-items-center select-date">
      <label for="date">Välj datum:</label>
      <input
        type="date"
        class="form-control"
        v-model="selectedDate"
        @change="dateChanged()"
        :max="maxDate"
      />
    </div>
    <div class="container-fluid">
      <div class="operator table-responsive-md">
        <table
          class="table table-striped"
          v-if="events != null && events.length > 0"
        >
          <thead>
            <tr>
              <th scope="col">{{ $t("disruptions.starttime") }}</th>
              <th scope="col">Plats</th>
              <th scope="col">{{ $t("disruptions.msg") }}</th>
            </tr>
          </thead>
          <tbody v-if="events != null && events.length > 0">
            <tr v-for="(msg, index) in events" :key="index">
              <td>
                <router-link
                  :to="{ name: 'event', params: { eventID: msg.eventID } }"
                  >{{ timeFormat(msg.start) }}</router-link
                >
              </td>
              <td>{{ msg.plats }}</td>
              <td>{{ msg.msg }}</td>
            </tr>
          </tbody>
        </table>
        <span v-if="events.length == null || events.length == 0">
          <hr />
          <p>{{ $t("disruptions.none") }}</p>
        </span>
        <div class="text-center text-secondary" v-if="loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopHeader from "@/components/TopHeader";
import moment from "moment";

export default {
  name: "event",
  props: ["date"],
  data() {
    return {
      events: [],
      selectedDate: null,
      history: [],
      maxDate: moment().format('YYYY-MM-DD')
    };
  },

  computed: {
    ...mapState(["lang", "loading", "baseUrl", "apiKey"]),
  },

  components: {
    TopHeader,
  },

  created() {
    if (this.$route.query.lang) {
      this.$store.commit("SET_LOCAL_LANGUAGE", this.$route.query.lang);
    }

    this.$i18n.locale = this.lang;
  },

  mounted() {
    if (this.date) {
      this.selectedDate = this.date;
      this.fetchOperator();
    }
  },

  beforeUnmount() {
    clearTimeout(this.operatorInterval);
  },

  methods: {
    timeFormat(date) {
      return moment(date).format("HH:mm");
    },

    dateChanged() {
      this.$router.push({
        name: "eventArchive",
        params: { date: this.selectedDate },
      });
      this.fetchOperator();
    },

    fetchOperator() {
      this.$store.commit("LOADING_STATE", true);
      this.$axios
        .get(
          this.baseUrl +
            "eventArchive.php?key=" +
            this.apiKey +
            "&date=" +
            this.selectedDate,
          { withCredentials: true }
        )
        .then((res) => {
          this.$store.commit("LOADING_STATE", false);
          this.events = res.data;
        })
        .catch((err) => {
          this.$store.commit("LOADING_STATE", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-date {
  margin-left: 15px;
  margin-top: 25px;
  label {
    margin-right: 10px;
  }
  input {
    max-width: 200px;
  }
}
.container-fluid {
  padding-bottom: 150px;
}
.ti {
  .table {
    thead th {
      font-size: 0.8rem;
    }
    tbody .late,
    tbody .early {
      font-size: 0.73rem;
    }
  }
}
.operator {
  padding: 25px 20px 1px;
  margin: 20px 0;
  background: var(--background-color-secondary);
  .table {
    color: var(--text-primary-color);
    thead th {
      border-bottom: 0;
      svg {
        margin-left: 10px;
        font-size: 20px;
      }
    }
    td {
      .status {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .red,
      .orange,
      .yellow,
      .green {
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }
      .green {
        background: #27ae60;
      }
      .yellow {
        background: #f1c40f;
      }
      .orange {
        background: #e67e22;
      }
      .red {
        background: #e74c3c;
      }
    }
    .trainNr {
      a {
        font-weight: 600;
        font-size: 15px;
      }
    }
    .late,
    .early {
      font-size: 14px;
      font-weight: 600;
    }
    .late {
      color: #e74c3c;
    }
    .early {
      color: #27ae60;
    }
  }
}
@media (max-width: 575.98px) {
  .container-fluid {
    padding: 0;
  }
  .operator {
    padding: 0;
    .table {
      padding: 0;
      thead th {
        font-size: 0.59rem;
        // font-weight: 600;
        padding: 10px 2px;
      }
      tbody {
        tr {
          td {
            font-size: 0.68rem;
            padding: 5px 1px;
            &:last-child {
              padding-right: 5px;
            }
          }
        }
        tr > th {
          padding: 5px 1px;
        }
        .status {
          padding: 0.05rem 0.3rem;
          margin-top: 0.1rem;
          .green,
          .yellow,
          .orange,
          .red {
            width: 10px;
            height: 10px;
          }
        }
        .trainNr a {
          font-size: 0.7rem;
        }
        .early,
        .late {
          font-size: 0.55rem;
        }
      }
    }
  }
}
@media (max-width: 1199.98px) {
  .h4 {
    font-size: 17px;
  }
  .table {
    th {
      font-size: 14px;
    }
  }
}
</style>
