<template>
  <div class="main">
    <top-header :title="$t('feedbackPosts')" />
    <div class="container-fluid">
      <div class="row feedbacks" v-if="feedbacks.length > 0">
        <div
          class="col-sm-10 feedback"
          v-for="(fd, index) in feedbacks"
          :key="index"
        >
          <p class="fd">{{ fd.question }}</p>
          <div class="dialogbox">
            <span><img :src="require('@/assets/1409T.png')" alt="" /></span>
            <div class="ans">
              <span class="tip left"></span>
              <div class="message">
                <span>{{ fd.answer }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader";
import Box from "@/components/Box";
import { mapState } from "vuex";

export default {
  name: "posts",
  metaInfo() {
    const title = this.title;
    return {
      title: title,
    };
  },

  data() {
    return {
      feedbacks: [],
      title: this.$i18n.t("feedbackPosts"),
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  components: {
    TopHeader,
    Box,
  },

  mounted() {
    this.fetchFeedbacks();
  },

  methods: {
    fetchFeedbacks() {
      this.$axios.get(this.baseUrl + "feedback.php").then((res) => {
        this.feedbacks = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.feedbacks {
  margin: 20px 0;
  .feedback {
    background: var(--background-color-secondary);
    padding: 15px 15px 25px 15px;
    margin-bottom: 15px;
    border-radius: 4px;

    .fd {
      font-size: 15px;
      color: #989898;
      font-weight: 600;
      padding: 10px;
      margin: 0;
    }

    .dialogbox {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      // align-items: center;
      // align-content: center;
      img {
        width: 30px;
        margin-right: 10px;
        padding-top: 30px;
      }
      .ans {
        position: relative;
        max-width: 900px;
        height: auto;
        margin: 20px 10px;
        padding: 10px 15px;
        background-color: var(--accent-color);
        border-radius: 3px;
        .tip {
          width: 0px;
          height: 0px;
          position: absolute;
          background: transparent;
          border: 10px solid var(--accent-color);
        }
        .left {
          top: 15px;
          left: -20px;
          border-top-color: transparent;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }
        .message {
          border-radius: 5px;
          min-height: 30px;
          font-family: Arial;
          font-size: 15px;
          line-height: 1.5;
          color: var(--text-primary-color);
        }
      }
    }
  }
}
</style>


