<template>
  <div class="main" :class="lang">
    <top-header
      icon="map-marker-alt"
      :title="$t('trainPosition')"
      @changed="onLanguageSelected"
    />
    <div class="container-fluid">
      <box style="margin-top: 15px">
        <div class="row">
          <div class="col-md-6">
            <search-input
              :searched="trainNr"
              :datum="date"
              :key="trainNr"
              @onPress="searchTrainPosition"
              :showDatePicker="true"
              @toggle="selectedStation"
            />
          </div>
        </div>
      </box>
      <div class="mt-2 info-box"  >
      <h6><ul v-if="traindata"> 
        <li 
          v-for="(td, index) in traindata"
          :key="index"
          :class="td.type == 'nok' ? 'cancelled' : ''"
        >
          {{ td.slag }} {{ td.tagNr }} {{ td.type == "nok" ? "inställt" : "" }}
          {{ td.from }} - {{ td.to }}
        </li>
      </ul></h6> 
      <p class="lastupdate" v-if="updated">
        {{ speed ? speed + " km/h. " : "" }} {{ $t("curUpdate") }}:
        <span>{{ updated }}</span>
      </p></div>
      <p
        v-if="
          annonseras > 0 && $store.state.authenticated && annonseras != trainNr
        "
      >
        Annonseras som
        <a href="#" @click="searchTrainPosition(annonseras, date)">{{
          annonseras
        }}</a>
      </p>
      <section class="train-position table-responsive-md">
        <table class="table table-striped" v-if="!error">
          <thead>
            <tr>
              <th scope="col" colspan="3" class="head">
                {{ titleTrain }} {{ operator }}
              </th>
              <th scope="col" colspan="2" class="head centered">
                {{ $t("table.plan") }}
              </th>
              <th scope="col" colspan="2" class="head centered">
                {{ $t("table.diff") }}
              </th>
              <th scope="col" colspan="2" class="head centered">
                {{ $t("table.verklig_tid") }}
              </th>
              <th scope="col"></th>
            </tr>
            <tr>
              <th scope="col">{{ $t("table.km") }}</th>
              <th scope="col">{{ $t("table.plats") }}</th>
              <th scope="col" class="head spar">{{ $t("table.spar") }}</th>
              <th scope="col">{{ $t("table.ank") }}</th>
              <th scope="col" class="head">{{ $t("table.avg") }}</th>
              <th scope="col" style="text-align: center">
                {{ $t("table.ank") }}
              </th>
              <th scope="col" class="head" style="text-align: center">
                {{ $t("table.avg") }}
              </th>
              <th scope="col">{{ $t("table.ank") }}</th>
              <th scope="col" class="head">{{ $t("table.avg") }}</th>
              <th scope="col">{{ $t("table.orsak") }}</th>
            </tr>
          </thead>
          <tbody v-if="positions.length > 0">
            <tr v-for="(pos, index) in positions" :key="index">
              <td class="km">{{ pos.km }}</td>
              <td class="station">
                <router-link
                  v-if="pos.plattform"
                  :to="{
                    name: 'station',
                    params: { urltpl: pos.tpl, urltyp: 'avg' },
                    query: { lang: lang },
                  }"
                  >{{ pos.tpl }}</router-link
                >
                <span v-if="!pos.plattform">{{ pos.tpl }}</span>
              </td>
              <td
                class="spar"
                v-html="
                  pos.spAvg === pos.spAnk
                    ? pos.spAvg
                    : pos.spAnk && pos.spAvg
                    ? '<strong>' +
                      $t('table.ank') +
                      ':</strong> ' +
                      pos.spAnk +
                      '<br><strong>' +
                      $t('table.avg') +
                      '</strong>: ' +
                      pos.spAvg
                    : pos.spAvg
                    ? pos.spAvg
                    : pos.spAnk
                "
              ></td>
              <td class="tider">{{ pos.anktid ? pos.anktid : "" }}</td>
              <td class="tider">
                {{
                  pos.annAvg && pos.avgtid
                    ? pos.annAvg + " (" + pos.avgtid + ")"
                    : pos.avgtid
                    ? pos.avgtid
                    : ""
                }}
              </td>
              <td
                style="text-align: center"
                :class="{ sen: pos.statusAnk < 0, tidig: pos.statusAnk >= 0 }"
              >
                {{
                  pos.statusAnk < 0 ? Math.abs(pos.statusAnk) : pos.statusAnk
                }}
              </td>
              <td
                style="text-align: center"
                :class="{ sen: pos.statusAvg < 0, tidig: pos.statusAvg >= 0 }"
              >
                {{
                  pos.statusAvg < 0 ? Math.abs(pos.statusAvg) : pos.statusAvg
                }}
              </td>
              <td class="tider">
                <strong>{{
                  pos.verkAnk
                    ? pos.verkAnk
                    : pos.bertidAnk
                    ? $t("table.ber") + pos.bertidAnk
                    : ""
                }}</strong>
              </td>
              <td class="tider">
                <strong>{{
                  pos.verkAvg
                    ? pos.verkAvg
                    : pos.bertidAvg
                    ? $t("table.ber") + pos.bertidAvg
                    : ""
                }}</strong>
              </td>
              <td :class="lang" v-if="pos.events == null">
                {{
                  pos.beskrivning && pos.beskrivning.length
                    ? pos.beskrivning
                    : ""
                }}
              </td>
              <td :class="lang" v-if="pos.events != null">
                <p v-for="(event, idx) in pos.events" :key="idx">
                  {{ event.from }}
                  {{
                    event.to != null && event.to != event.from
                      ? "- " + event.to
                      : ""
                  }}
                  ({{ event.code }} {{ event.description }})
                  <br />
                  <small>{{ event.internal }}</small>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <template v-if="error">
          <div class="alert alert-info" role="alert">
            {{ trainres.length > 0 ? $t("error_2") : $t("error_1") }}
          </div>
          <TrainPicker
            @newTrain="newTrain"
            :trainres="trainres"
            :tagNr="trainNr"
            :date="date"
            v-if="trainres.length > 0"
          />
        </template>
      </section>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TopHeader from "@/components/TopHeader";
import Box from "@/components/Box";
import SearchInput from "@/components/SearchInput";
import TrainPicker from "@/components/TrainPicker";
import SelectLanguage from "@/components/SelectLanguage";
import { mapGetters } from "vuex";
import { mapState } from "vuex";

export default {
  name: "position",
  metaInfo() {
    const title = this.title;
    return {
      title: title,
    };
  },

  data() {
    return {
      selected: null,
      trainNr: "",
      trainID: "",
      speed: "",
      titleTrain: "",
      trainres: [],
      title: this.$i18n.t("trainPosition"),
      operator: "",
      updated: "",
      positions: [],
      date: new Date().toLocaleDateString(),
      tforInterval: null,
      error: false,
      traindata: null,
      annonseras: 0,
    };
  },

  computed: {
    ...mapGetters({
      lang: "getLang",
    }),
    ...mapState(["apiKey", "baseUrl"]),
  },

  components: {
    TopHeader,
    TrainPicker,
    Box,
    SearchInput,
    SelectLanguage,
  },

  created() {
    if (this.$route.query.lang) {
      this.$store.commit("SET_LOCAL_LANGUAGE", this.$route.query.lang);
    }

    if (this.$route.query.tagNr) {
      this.trainNr = this.$route.query.tagNr;
    }
    if (this.$route.query.datum) {
      this.date = this.$route.query.datum;
    }
    if (this.$route.query.trainID) {
      this.trainID = this.$route.query.trainID;
    }
  },

  mounted() {
    if (this.datum) {
      this.date = this.datum;
    }
    this.fetchTrainPosition();
  },

  beforeUnmount() {
    clearTimeout(this.tforInterval);
  },

  methods: {
    newTrain(trainID) {
      this.trainID = trainID;
      this.$router.push({
        name: "position",
        query: {
          tagNr: this.trainNr,
          trainID: this.trainID,
          datum: moment(this.date).format("YYYY-MM-DD"),
          lang: this.lang,
        },
      });
      this.fetchTrainPosition();
    },

    fetchTrainPosition() {
      this.trainres = [];
      if (this.trainNr) {
        if (this.tforInterval != null) {
          clearTimeout(this.tforInterval);
        }
        this.title = this.trainNr + " - " + this.$i18n.t("trainPosition");
        this.$axios
          .get(
            this.baseUrl +
              "tfor3.php?tagNr=" +
              this.trainNr +
              "&trainID=" +
              (this.trainID ?? "") +
              "&datum=" +
              moment(this.date).format("YYYY-MM-DD") +
              "&lang=" +
              this.lang +
              "&key=" +
              this.apiKey,
            { withCredentials: true }
          )
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              this.error = false;
              this.titleTrain = res.data.tagNr;
              this.operator = res.data.operator;
              this.updated = res.data.ts;
              this.annonseras = res.data.annonseras;
              this.traindata = res.data.traindata;
              this.positions = res.data.result;
              this.speed = res.data.speed;
              this.tforInterval = setTimeout(() => {
                this.fetchTrainPosition();
              }, 60000);
            }
          })
          .catch((err) => {
            this.positions = [];
            this.error = true;
          });
      }
    },

    selectedStation(val, selectedDate) {
      this.searchTrainPosition(val, selectedDate);
    },

    searchTrainPosition(value, selectedDate) {
      if (selectedDate) {
        this.date = selectedDate;
      } else {
        this.date = new Date().toLocaleDateString();
      }

      if (value) {
        this.$axios
          .get(
            this.baseUrl +
              "trainsearch.php?search=" +
              value +
              "&datum=" +
              moment(this.date).format("YYYY-MM-DD")
          )
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              if (res.data.data.length == 1) {
                this.trainNr = value;
                this.trainID = res.data.data[0].trainID;
                this.$router.push({
                  name: "position",
                  query: {
                    tagNr: value,
                    trainID: this.trainID,
                    datum: moment(this.date).format("YYYY-MM-DD"),
                    lang: this.lang,
                  },
                });
                this.fetchTrainPosition();
              } else if (res.data.data.length > 1) {
                this.error = true;
                this.trainres = res.data.data;
                this.trainNr = value;
              } else {
                if (isNaN(value)) {
                  this.$router.push({
                    name: "station",
                    params: { urltpl: value, urltyp: "avg" },
                    query: { lang: this.lang },
                  });
                }
              }
            }
          })
          .catch((err) => {
            this.positions = [];
            this.error = true;
          });
      }
    },

    onLanguageSelected() {
      this.$router.push({
        name: "position",
        query: {
          tagNr: this.trainNr,
          datum: moment(this.date).format("YYYY-MM-DD"),
          lang: this.lang,
        },
      });
      this.fetchTrainPosition();
    },
  },
};
</script>

<style lang="scss">

.info-box{
  background: var(--background-color-secondary);
  padding: 3px; 
  border-radius: 2px;
}
.cancelled {
  color: red;
}

.ti .container-fluid {
  .lastupdate {
    font-size: 10px;
  }

  .table {
    tr th,
    tr .ti {
      font-size: 11px;
    }

    tr {
      .spar strong {
        font-size: 10px;
      }
    }
  }
}

.container-fluid {
  padding-bottom: 150px;

  .lastupdate {
    margin: 0;
    font-size: 13px;
    margin-top: 15px;
    padding-left: 5px;

    span {
      font-weight: 600;
      font-size: 12px;
    }
  }

  .train-position {
    background: var(--background-color-secondary);
    padding: 15px 15px 1px;
    margin-top: 15px;

    .table {
      color: var(--text-primary-color);

      thead {
        tr th {
          font-weight: 600;
        }

        .head {
          font-weight: 600;
          border-right-style: solid;
          border-right-width: 1px;
          border-right-color: #dee2e6;
        }

        .head.centered {
          text-align: center;
        }

        th {
          border-bottom: 0;
        }
      }

      tbody tr {
        .station {
          a {
            font-weight: 600;
            color: #148f77;

            &:hover {
              color: #1abc9c;
            }
          }
        }
      }

      .sen,
      .tidig {
        font-weight: 600;
      }

      .sen {
        color: #e74c3c;
      }

      .tidig {
        color: #27ae60;
      }
    }
  }

  @media (max-width: 775.98px) {
    padding: 0;

    .train-position {
      padding: 0;
    }

    .box {
      height: 190px;
    }

    .select {
      margin-top: 15px;
      float: none;
      min-width: 100%;

      select {
        min-width: 100%;
      }
    }

    .table {
      thead {
        th {
          font-size: 0.5rem;
          // font-weight: 600;
          padding: 10px 2px;
        }

        .spar {
          text-align: center;
        }
      }

      tbody {
        tr > td {
          font-size: 0.8rem;
          padding: 5px 0;
        }

        tr > th {
          padding: 5px 1px;
        }

        td.km {
          padding: 0 5px;
        }

        .station,
        .tider {
          font-size: 0.55rem;
        }

        .spar {
          text-align: center;
          min-width: 55px;

          strong {
            font-size: 0.5rem;
          }

          span {
            font-size: 0.55rem;
          }
        }
      }
    }

    .ti .container-fluid {
      .table {
        tr th {
          font-size: 8px;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media (max-width: 1199.98px) {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
</style>
