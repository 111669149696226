<template>
  <canvas class="canvas" ref="canvasID"></canvas>
</template>

<script>
import moment from "moment";
export default {
  name: "stats",
  props: {
    start: {
      type: String,
      required: true,
      default: () => null,
    },
    stats: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      labels: [],
      data: [],
      width: 600,
      height: 400,
      canvas: null,
      context: null,
    };
  },

  mounted() {
    this.splitTimeData();
    this.init();
  },

  methods: {
    splitTimeData() {
      let endTime = null;
      this.stats.forEach((stat, index) => {
        this.labels.push(parseInt(stat.antal));
        if (index == 0) {
          this.data.push(moment(this.start).format("HH:mm"));
          this.data.push(moment(this.start).add(30, "minute").format("HH:mm"));
        } else if (stat.start != endTime) {
          this.data.push(moment(stat.start).format("HH:mm"));
          this.data.push(moment(stat.slut).format("HH:mm"));
        } else {
          this.data.push(moment(stat.slut).format("HH:mm"));
        }
        endTime = stat.slut;
      });
    },

    init() {
      this.canvas = this.$refs.canvasID;
      this.canvas.width = this.width;
      this.canvas.height = this.height;
      // this.canvas.style.width = '100%'
      // this.canvas.style.height = 'auto'
      // this.canvas.style.background = '#f1f1f1'
      this.context = this.canvas.getContext("2d");

      this.drawAxes();
      this.drawGrid();
      this.draw();
    },

    drawAxes() {
      this.context.strokeStyle = "#ABB2B9";
      this.context.lineWidth = 1;
      this.context.beginPath();

      // X-axis
      this.context.moveTo(50, 0);
      this.context.lineTo(50, 350);
      this.context.stroke();

      // Y-axis
      this.context.moveTo(50, 350);
      this.context.lineTo(600, 350);
      this.context.stroke();
    },

    drawGrid() {
      // Vertical grid
      let width = Math.round(this.width / this.data.length) - 1;
      let w = 0;
      for (let i = 0; i < this.data.length; i++) {
        this.context.strokeStyle = "#ABB2B9";
        this.context.lineWidth = 0.3;
        this.context.beginPath();
        this.context.moveTo(w + 50, 360);
        this.context.lineTo(w + 50, 0);
        this.context.stroke();

        this.context.font = "11px serif";
        this.context.fillText(this.data[i], w + 29, 375);
        w += Math.round(width);
      }

      // Horizontal grid
      let max = Math.max(...this.labels);
      let numbers = this.splitLabels(max);
      let height = Math.round(this.height / numbers.length);
      let h = 0;

      // zero antal
      this.context.moveTo(40, 350);
      this.context.lineTo(50, 350);
      this.context.stroke();
      this.context.font = "11px serif";
      this.context.fillText("0%", 20, 355);

      // Max antal
      this.context.moveTo(40, 10);
      this.context.lineTo(600, 10);
      this.context.stroke();
      this.context.font = "11px serif";
      this.context.fillText(max + "%", 20, 10);

      for (let i = 0; i < numbers.length; i++) {
        this.context.strokeStyle = "#ABB2B9";
        this.context.lineWidth = 0.3;
        this.context.beginPath();

        if (numbers[i] == max) {
          this.context.moveTo(40, 10);
          this.context.lineTo(600, 10);
          this.context.stroke();
          this.context.font = "11px serif";
          this.context.fillText(numbers[i] + "%", 20, 10);
        } else {
          let x = Math.round((this.height - 60) * (numbers[i] / max));

          this.context.moveTo(40, this.height - x - 50);
          this.context.lineTo(600, this.height - x - 50);
          this.context.stroke();
          this.context.font = "12px serif";
          this.context.fillText(numbers[i] + "%", 20, this.height - x - 50);
          h += height;
        }
      }
    },

    draw() {
      let width = Math.round(this.width / this.data.length) - 1;
      let w = 0;
      let numbers = this.uniqueLabels(this.labels);
      let max = Math.max(...numbers);
      for (let i = 0; i < this.data.length; i++) {
        for (let x = 0; x < this.stats.length; x++) {
          if (this.data[i] == moment(this.stats[x].start).format("HH:mm")) {
            let h = Math.round(this.height - 60) * (this.stats[x].antal / max);
            this.context.fillStyle = "#808B96";
            this.context.fillRect(w + 50, this.height - 50 - h, width, h);
          }
        }
        w += width;
      }
    },

    reset() {
      this.context.clearRect(0, 0, this.width, this.height);
    },

    uniqueLabels(data) {
      let sortedData = data.sort((a, b) => a - b);
      return [...new Set(sortedData.map((a) => a))];
    },

    splitLabels(num) {
      let n = num <= 15 ? num : 5;
      let sum = 0;
      let res = [];
      for (let i = 1; i < n; i++) {
        if (num % 2 == 0) {
          res.push(Math.floor(num / n) * i);
        } else {
          res.push(Math.ceil(num / n) * i);
        }
      }
      return res;
    },
  },
};
</script>

