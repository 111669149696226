<template>
  <div class="image-zone">
    <div class="field imageDropzone">
      <small class="text-muted"
        >Filformat som stöds är jpg, jpeg, png, pdf, doc och docx.</small
      >
      <div
        id="dropzone"
        :class="['dropzone', dragging ? 'dragover' : '']"
        @drop="handleImageDrop"
        @dragstart="dragging = true"
        @dragover="dragging = true"
        @dragenter="dragging = true"
        @dragleave="dragging = false"
        @dragend="dragging = false"
      >
        <h2 class="title fs-6 text-muted">Dra och släpp filen här</h2>
        <p>eller</p>
        <div class="custom-file mb-3 text-center">
          <button
            type="button"
            class="btn btn-light px-5 fs-6 rounded"
            onclick="document.getElementById('file').click()"
          >
            Klicka för att ladda upp
          </button>
          <input
            type="file"
            class="custom-file-input"
            id="file"
            style="display: none"
            @change="onFileChange"
            required
            multiple
          />
        </div>
      </div>
    </div>
    <div class="image-preview" v-if="files.length > 0">
      <div class="img-holder" v-for="(file, index) in files" :key="index">
        <span @click="removeImage(index)">
          <i class="bi bi-x-circle-fill"></i>
        </span>
        <div
          v-if="fileType(file.file.type) == 'IMAGE'"
          class="img"
          :style="{
            background: 'url(' + createURL(file.file) + ')',
            height: '60px',
            width: '60px',
            backgroundSize: 'cover',
          }"
        ></div>
        <div
          v-if="fileType(file.file.type) == 'PDF'"
          class="img"
          :style="{
            background: 'url(' + require('@/assets/pdf.png') + ')',
            height: '60px',
            width: '60px',
            backgroundSize: 'cover',
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "file-attachments",
  data() {
    return {
      dropzone: false,
      dragging: false,
      alertMassage: null,
      loading: false,
      extensions: ["png", "jpg", "jpeg", "pdf", "doc", "docx"],
      errorMessage: null,
      url: null,
      files: [],
    };
  },

  mounted() {
    document.addEventListener("drop", function (e) {
      e.preventDefault();
    });
    document.addEventListener("dragover", function (e) {
      e.preventDefault();
    });
  },

  methods: {
    handleImageDrop(e) {
      this.errorMessage = null;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      for (let i = 0; i < files.length; i++) {
        if (this.isImageValidity(files[i])) {
          let file = files[i];
          let fileName = file.name.split(".");
          this.files.push({
            file: file,
            name: fileName[0],
            description: null,
          });
          this.$emit("change", this.files);
        } else {
          this.errorMessage = "En eller några filer är inte tillåtna att välja";
        }
      }
    },

    createURL(file) {
      let url = URL.createObjectURL(file);

      return url;
    },

    resetDropzone() {
      this.dropzone = !this.dropzone;
      this.files = [];
      this.errorMessage = null;
    },

    removeImage(selectedIndex) {
      this.files.splice(selectedIndex, 1);
    },

    onFileChange(e) {
      this.errorMessage = null;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      for (let i = 0; i < files.length; i++) {
        if (this.isImageValidity(files[i])) {
          let file = files[i];
          let fileName = file.name.split(".");
          this.files.push({
            file: file,
            name: fileName[0],
            description: null,
          });
          this.$emit("change", this.files);
        } else {
          this.errorMessage = "En eller några filer är inte tillåtna att välja";
        }
      }
    },

    isImageValidity(file) {
      const name = file.name.split(".");
      const extension = name[name.length - 1];
      const check = this.extensions.includes(extension);

      if (check) {
        return true;
      }

      return false;
    },

    fileType(mimetype) {
      if (
        mimetype == "image/png" ||
        mimetype == "image/jpeg" ||
        mimetype == "image/jpg"
      ) {
        return "IMAGE";
      } else if (mimetype == "application/pdf") {
        return "PDF";
      } else if (mimetype == "application/msword") {
        return "DOC";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image-zone {
  .imageDropzone {
    margin: 15px 0;
  }

  small {
    font-size: 13px;
  }

  .dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px dashed #8e8e8e;
    border-radius: 5px;
    padding: 35px 0;
    .custom-file {
      width: 400px;
      button {
        border: 1px solid #b2babb;
      }
    }
    .title {
      text-transform: uppercase;
      color: #6f6f6f;
      font-size: 22px;
    }
    .file {
      align-items: center;
      justify-content: center;
    }
  }
  .dropzone.dragover {
    border-color: #209cee;
    background: #efefef;
  }

  .image-preview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .img-holder {
      position: relative;
      margin: 0 15px 15px 0;
      border: 2px solid #b2babb;
      border-radius: 4px;
      padding: 5px;
      &:hover {
        border-color: #209cee;
        span {
          display: block;
        }
      }
      span {
        display: none;
        position: absolute;
        top: -12px;
        right: -10px;
        cursor: pointer;
        // background: #c0392b;
        .bi {
          color: #c0392b;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
