<template>
    <div class="box" :style="{styles}">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ['styles']
}
</script>


<style lang="scss" scoped>
.box {
    padding: 15px;
	background: var(--background-color-secondary);
    // height: 100%;

    @media (max-width: 1199.98px) { 
        
    }
}
</style>

