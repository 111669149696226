<template>
  <div class="main" :class="lang">
    <top-header :title="$t('cancel.title')" />
    <select style="margin-left: 16px;"
      v-model="templateID"
      v-if="templates.length > 0"
      @change="fetchOperator()"
    >
      <option
        v-for="(t, index) in templates"
        :key="index"
        :value="t.templateID"
      >
        {{ t.template }}
      </option>
    </select>
    <select v-model="datum" @change="fetchOperator()">
      <option value="-1">I går</option>
      <option value="0">I dag</option>
      <option value="1">I morgon</option>
    </select>
    <div class="container-fluid">
      <div class="operator table-responsive-md">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">{{ $t("operator.trainnumber") }}</th>
              <th scope="col">{{ $t("operator.distance") }}</th>
              <th scope="col">{{ $t("table.avg") }}</th>
              <th scope="col">{{ $t("cancel.atTime") }}</th>
            </tr>
          </thead>
          <tbody v-if="upp.length > 0 || ned.length > 0">
            <tr v-for="(train, index) in ned" :key="'ned' + index">
              <td class="trainNr">
                {{ train.tagNr }}
              </td>
              <td>{{ train.fromtpl }} - {{ train.totpl }}</td>
              <td>
                {{ train.avgtid }}
              </td>
              <td>
                {{
                  train.ts.substring(0, 2) == "20"
                    ? timeFormat(train.ts)
                    : train.ts
                }}
              </td>
            </tr>
            <tr>
              <td colspan="4"><hr /></td>
            </tr>
            <tr v-for="(train, index) in upp" :key="'upp' + index">
              <td class="trainNr">
                {{ train.tagNr }}
              </td>
              <td>{{ train.fromtpl }} - {{ train.totpl }}</td>
              <td>
                {{ train.avgtid }}
              </td>
              <td>
                {{
                  train.ts.substring(0, 2) == "20"
                    ? timeFormat(train.ts)
                    : train.ts
                }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">{{ $t('cancel.notFound') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center text-secondary" v-if="loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopHeader from "@/components/TopHeader";
import moment from "moment";
export default {
  name: "operator",
  metaInfo() {
    const title = this.title;
    return {
      title: title,
    };
  },

  data() {
    return {
      operator: null,
      upp: [],
      ned: [],
      title: this.$i18n.t("cancel.title"),
      operatorInterval: null,
      templates: [],
      datum: 0,
      templateID: null,
    };
  },

  computed: {
    ...mapState(["lang", "loading", "baseUrl", "apiKey"]),
  },

  components: {
    TopHeader,
  },

  created() {
    if (this.$route.query.lang) {
      this.$store.commit("SET_LOCAL_LANGUAGE", this.$route.query.lang);
    }
    this.$i18n.locale = this.lang;
    this.fetchTemplates();
  },

  mounted() {},

  beforeUnmount() {
    clearTimeout(this.operatorInterval);
  },

  methods: {
    getTemplate() {
      for (var i = 0; i < this.templates.length; i++) {
        if (this.templateID == this.templates[i].templateID) {
          return this.templates[i].template;
        }
      }
    },
    getTemplateID(template) {
      console.log(this.templates);
      for (var i = 0; i < this.templates.length; i++) {
        console.log(
          "Jämför " + this.templates[i].template + " med " + template
        );
        if (this.templates[i].template == template) {
          return this.templates[i].templateID;
        }
      }
    },
    fetchTemplates() {
      this.$axios.get(this.baseUrl + "templates.php").then((res) => {
        this.templates = res.data.result;
        if (this.$route.params.template && this.$route.params.dag) {
          this.templateID = this.getTemplateID(this.$route.params.template);
          if (this.$route.params.dag == "igar") {
            this.datum = -1;
          } else if (this.$route.params.dag == "idag") {
            this.datum = 0;
          } else if (this.$route.params.dag == "imorgon") {
            this.datum = 1;
          }
          this.fetchOperator();
        }
      });
    },
    fetchOperator() {
      if (!this.templateID || this.datum == null) {
        return;
      }
      var dag = "";
      if (this.datum == 0) {
        dag = "idag";
      } else if (this.datum == -1) {
        dag = "igar";
      } else if (this.datum == 1) {
        dag = "imorgon";
      }
      if (
        this.$route.params.template != this.getTemplate() ||
        this.$route.params.dag != dag
      ) {
        this.$router.push({
          name: "cancel",
          params: { template: this.getTemplate(), dag: dag },
        });
      }

      this.$store.commit("LOADING_STATE", true);
      this.$axios
        .get(
          this.baseUrl +
            "inst.php?templateID=" +
            this.templateID +
            "&datum=" +
            this.datum
        )
        .then((res) => {
          this.$store.commit("LOADING_STATE", false);
          this.upp = res.data.upp;
          this.ned = res.data.ned;
          this.operatorInterval = setTimeout(() => {
            this.fetchOperator();
          }, 60000);
        })
        .catch((err) => {
          this.$store.commit("LOADING_STATE", false);
        });
    },

    timeDelay(time) {
      if (time == 0) {
        return this.$t("table.itid");
      } else if (time == 1) {
        return time + this.$t("table.enmintid");
      } else if (time > 1) {
        return time + this.$t("table.mintid");
      } else if (time == -1) {
        return Math.abs(time) + this.$t("table.enminsen");
      } else if (time < -1) {
        return Math.abs(time) + this.$t("table.minsen");
      }
    },

    timeFormat(date) {
      if (date) {
        return moment(date).format("HH:mm");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding-bottom: 150px;
}
.ti {
  .table {
    thead th {
      font-size: 0.8rem;
    }
    tbody .late,
    tbody .early {
      font-size: 0.73rem;
    }
  }
}
.operator {
  padding: 25px 20px 1px;
  margin: 20px 0;
  background: var(--background-color-secondary);
  .table {
    color: var(--text-primary-color);
    thead th {
      border-bottom: 0;
      svg {
        margin-left: 10px;
        font-size: 20px;
      }
    }
    td {
      .status {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .red,
      .orange,
      .yellow,
      .green {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        // margin-right: 10px;
      }
      .green {
        background: #27ae60;
      }
      .yellow {
        background: #f1c40f;
      }
      .orange {
        background: #e67e22;
      }
      .red {
        background: #e74c3c;
      }
    }
    .trainNr {
      a {
        font-weight: 600;
        font-size: 15px;
      }
    }
    .late,
    .early {
      font-size: 14px;
      font-weight: 600;
    }
    .late {
      color: #e74c3c;
    }
    .early {
      color: #27ae60;
    }
  }
}
@media (max-width: 575.98px) {
  .container-fluid {
    padding: 0;
  }
  .operator {
    padding: 0;
    .table {
      padding: 0;
      thead th {
        font-size: 0.59rem;
        padding: 10px 2px;
      }
      tbody {
        tr {
          td {
            font-size: 0.68rem;
            padding: 5px 1px;
            &:last-child {
              padding-right: 5px;
            }
          }
        }
        tr > th {
          padding: 5px 1px;
        }
        .status {
          padding: 0.05rem 0.3rem;
          margin-top: 0.1rem;
          .green,
          .yellow,
          .orange,
          .red {
            width: 10px;
            height: 10px;
          }
        }
        .trainNr a {
          font-size: 0.7rem;
        }
        .early,
        .late {
          font-size: 0.55rem;
        }
      }
    }
  }
}
@media (max-width: 1199.98px) {
  .h4 {
    font-size: 17px;
  }
  .table {
    th {
      font-size: 14px;
    }
  }
}
</style>


