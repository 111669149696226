<template>
    <div class="main">
        <top-header :title="$t('navbar.faq')" />
        <div class="container-fluid">
            <div class="faq">
                <h1> {{ $t("faq.header") }} <button type="button" class="btn btn-outline-dark offset-3"
                        style="font-weight: 500; font-size: large;">Dokumentation <i class="bi bi-box-arrow-up-right"></i>
                    </button></h1>
                <h5 class="answer">{{ $t("faq.content") }}</h5>
                <div class="questions p-3">
                    <div class="row mt-5">
                        <div class="col">
                            <div class="row">
                                <div class="col-1"><i class="bi bi-emoji-smile bi-icon"></i>
                                </div>
                                <div class="col-5">
                                    <div class="row">
                                        <h4>Rubrik</h4>
                                    </div>
                                    <div class="row">
                                        <h5 class="answer">Svar</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row mt-3">
                                <div class="col-1"><i class="bi bi-phone-flip bi-icon"></i>
                                </div>
                                <div class="col-5">
                                    <div class="row">
                                        <h4>Rubrik</h4>
                                    </div>
                                    <div class="row">
                                        <h5 class="answer">Svar</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <div class="col-1"><i class="bi bi-person-fill-gear bi-icon"></i>
                                </div>
                                <div class="col-5">
                                    <div class="row">
                                        <h4>Rubrik</h4>
                                    </div>
                                    <div class="row">
                                        <h5 class="answer">Svar</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row mt-3">
                                <div class="col-1"><i class="bi bi-stoplights-fill bi-icon"></i>
                                </div>
                                <div class="col-5">
                                    <div class="row">
                                        <h4>Rubrik</h4>
                                    </div>
                                    <div class="row">
                                        <h5 class="answer">Svar</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col">
                            <div class="row">
                                <div class="col-1"><i class="bi bi-box-arrow-in-left bi-icon"></i>
                                </div>
                                <div class="col-5">
                                    <div class="row">
                                        <h4>Rubrik</h4>
                                    </div>
                                    <div class="row">
                                        <h5 class="answer">Svar</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row mt-3">
                                <div class="col-1"><i class="bi bi-geo-alt bi-icon"></i>
                                </div>
                                <div class="col-5">
                                    <div class="row">
                                        <h4>Rubrik</h4>
                                    </div>
                                    <div class="row">
                                        <h5 class="answer">Svar</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="contact-support-team">
                    <div class="row">
                        <div class="col-1" style="margin:25px;"><img src="../assets/img/l_c.png"></div>
                        <div class="col-8 p-3">
                            <div class="row">
                                <h3>{{ $t("faq.find-answer") }}</h3>
                            </div>
                            <div class="row">
                                <p>{{ $t("faq.support-help") }}</p>
                            </div>
                            <div class="row">
                                <p>{{ $t("faq.opening-hours") }}</p>
                            </div>
                        </div>
                        <div class="col p-5">
                            <button type="button" class="btn btn-dark" style="font-weight: 500; font-size: x-large;">{{
                                $t("faq.contact-button") }}</button>

                        </div>
                    </div>

                </div>
            </div>


        </div>
    </div>
</template>
  
<script>
import TopHeader from "@/components/TopHeader";
import { mapGetters } from "vuex";

export default {
    metaInfo() {
        const title = this.title;
        return {
            title: title,
            meta: [
                {
                    vmid: "og:title",
                    property: "og:title",
                    name: "title",
                    content: "1409 | " + title,
                },
                {
                    vmid: "og:description",
                    property: "og:description",
                    name: "description",
                    content:
                        "Faq.",
                },
            ],
        };
    },

    data() {
        return {
            title: this.$i18n.t("faq.header"),
        };
    },

    components: {
        TopHeader,
    },
    computed: {
        ...mapGetters({
            lang: "getLang",
        }),
    },
};
</script>
  
<style lang="scss" scoped>
a {
    color: grey;
}

.faq {
    background-color: white;
    padding: 25px;
    border-radius: 4px;
}

.contact-support-team {
    background-color: #F9F9F9;
    border-radius: 6px;
    border: 2px solid #C1C1C1;
    padding: 10px;
}

.bi-icon {
    padding: 10px 15px 10px 15px;
    font-size: 36px;
    border: 1px solid #EAEAEA;
    border-radius: 10px;
}

.answer {
    color: #5B5960;
}
</style>
  
  
  