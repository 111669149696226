export const langs = [
    {
        code: 'sv',
        lang: 'Svenska',
        img: require('./assets/lang/sv.png')
    },
    {
        code: 'fi',
        lang: 'Suomeksi',
        img: require('./assets/lang/fi.png')
    },
    {
        code: 'en',
        lang: 'English',
        img: require('./assets/lang/en.png')
    },
    {
        code: 'fr',
        lang: 'Français',
        img: require('./assets/lang/fr.png')
    },
    {
        code: 'es',
        lang: 'Español',
        img: require('./assets/lang/es.png')
    },
    {
        code: 'de',
        lang: 'Deutsch',
        img: require('./assets/lang/de.png')
    },
    {
        code: 'ru',
        lang: 'Русский',
        img: require('./assets/lang/ru.png')
    },
    {
        code: 'ar',
        lang: 'عربى',
        img: require('./assets/lang/ar.png')
    },
    {
        code: 'ti',
        lang: 'ትግርኛ',
        img: require('./assets/lang/ti.png')
    },
]