<template>
  <div class="main" :class="lang">
    <top-header :title="$t('operator.title', [operator])" />
    <div class="container-fluid">
      <div class="operator table-responsive-md">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" class="th-icons" @click="sortBy('delay')">
                &nbsp; ⇅
              </th>
              <th scope="col" @click="sortBy('tagNr')">
                {{ $t("operator.trainnumber") }} ⇅
              </th>
              <th scope="col" @click="sortBy('fromtpl')">
                {{ $t("operator.distance") }} ⇅
              </th>
              <th scope="col" @click="sortBy('curtpl')">
                {{ $t("operator.position") }} ⇅
              </th>
              <th scope="col" colspan="2" @click="sortBy('timeAtLocation')">
                {{ $t("operator.report") }} ⇅
              </th>
              <th scope="col" @click="sortBy('nexttime')">
                {{ $t("operator.next") }} ⇅
              </th>
            </tr>
          </thead>
          <tbody v-if="sorted.length > 0">
            <tr v-for="(train, index) in sorted" :key="index">
              <td>
                <span class="status">
                  <span
                    :class="{
                      green: train.delay > -5,
                      yellow: train.delay <= -5 && train.delay > -15,
                      orange: train.delay <= -15 && train.delay > -30,
                      red: train.delay <= -30,
                    }"
                    >&nbsp;</span
                  >
                </span>
              </td>
              <td
                class="trainNr"
                v-if="
                  $store.state.railway == 'y' ||
                  !$store.state.authenticated ||
                  train.slag == 'Rst'
                "
              ><span v-if="$store.state.authenticated">{{ train.slag }}</span>&nbsp;
                <router-link
                  :to="{
                    name: 'position',
                    query: {
                      trainID: train.trainID,
                      tagNr: train.tagNr,
                      datum: train.datum,
                      lang: lang,
                    },
                  }"
                  >{{ train.tagNr }}</router-link
                >
              </td>
              <td
                class="trainNr"
                v-if="
                  train.slag != 'Rst' &&
                  $store.state.authenticated &&
                  $store.state.railway != 'y'
                "
              >{{ train.slag }}
                {{ train.tagNr }}
                <small v-if="train.ttagNr != train.tagNr"
                  >({{ train.ttagNr }})</small
                >
              </td>
              <td>{{ train.fromtpl }} - {{ train.totpl }}</td>
              <td>
                {{ train.curtpl }}
                {{
                  train.curtrack
                    ? "(" + $t("table.spar") + " " + train.curtrack + ")"
                    : ""
                }}
                {{ train.nexttpl ? "-" : "" }} {{ train.nexttpl }}
                {{ train.speed ? "(" + train.speed + " km/h)" : "" }}
              </td>
              <td>{{ timeFormat(train.timeAtLocation) }}</td>
              <td :class="{ late: train.delay < 0, early: train.delay >= 0 }">
                {{ timeDelay(train.delay) }}
              </td>
              <td>{{ timeFormat(train.nexttime) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center text-secondary" v-if="loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopHeader from "@/components/TopHeader";
import moment from "moment";
export default {
  name: "operator",
  props: ["operatorID"],
  metaInfo() {
    const title = this.title;
    return {
      title: title,
    };
  },

  data() {
    return {
      operator: null,
      trains: [],
      title: this.$i18n.t("operator.title", { 0: this.$route.query.name }),
      operatorInterval: null,
      sortDirection: "asc",
      sortColumn: "tagNr",
    };
  },

  computed: {
    ...mapState(["lang", "loading", "baseUrl", "apiKey"]),
    sorted() {
      let data = this.trains.slice().sort((a, b) => {
        let modifier = 1;
        if (this.sortDirection == "desc") {
          modifier = -1;
        }
        if (a[this.sortColumn] < b[this.sortColumn]) {
          return -1 * modifier;
        } else if (a[this.sortColumn] > b[this.sortColumn]) {
          return 1 * modifier;
        } else {
          return 0;
        }
      });
      return data;
    },
  },

  components: {
    TopHeader,
  },

  created() {
    if (this.$route.query.lang) {
      this.$store.commit("SET_LOCAL_LANGUAGE", this.$route.query.lang);
    }

    this.operator = this.$route.query.name;
    this.$i18n.locale = this.lang;
  },

  mounted() {
    this.fetchOperator();
  },

  beforeUnmount() {
    clearTimeout(this.operatorInterval);
  },

  methods: {
    fetchOperator() {
      this.$store.commit("LOADING_STATE", true);
      this.$axios
        .get(
          this.baseUrl +
            "new_operator.php?key=" +
            this.apiKey +
            "&id=" +
            this.operatorID,
          { withCredentials: true }
        )
        .then((res) => {
          this.$store.commit("LOADING_STATE", false);
          this.trains = res.data;
          this.operatorInterval = setTimeout(() => {
            this.fetchOperator();
          }, 60000);
        })
        .catch((err) => {
          this.$store.commit("LOADING_STATE", false);
        });
    },

    sortBy(column) {
      if (column == this.sortColumn) {
        this.sortDirection = this.sortDirection == "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
      }
    },

    timeDelay(time) {
      if (time == 0) {
        return this.$t("table.itid");
      } else if (time == 1) {
        return time + this.$t("table.enmintid");
      } else if (time > 1) {
        return time + this.$t("table.mintid");
      } else if (time == -1) {
        return Math.abs(time) + this.$t("table.enminsen");
      } else if (time < -1) {
        return Math.abs(time) + this.$t("table.minsen");
      }
    },

    timeFormat(date) {
      if (date) {
        return moment(date).format("HH:mm");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a{ color: #148f77;}
a:hover {color: #31b096;}

.container-fluid {
  padding-bottom: 150px;
}
th {
  cursor: pointer;
}
.ti {
  .table {
    thead th {
      font-size: 0.8rem;
    }
    tbody .late,
    tbody .early {
      font-size: 0.73rem;
    }
  }
}
.operator {
  padding: 25px 20px 1px;
  margin: 20px 0;
  background: var(--background-color-secondary);
  .table {
    color: var(--text-primary-color);
    thead th {
      border-bottom: 0;
      svg {
        margin-left: 10px;
        font-size: 20px;
      }
    }
    td {
      .status {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .red,
      .orange,
      .yellow,
      .green {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        // margin-right: 10px;
      }
      .green {
        background: #27ae60;
      }
      .yellow {
        background: #f1c40f;
      }
      .orange {
        background: #e67e22;
      }
      .red {
        background: #e74c3c;
      }
    }
    .trainNr {
      a {
        font-weight: 600;
        // color: #148F77;
        font-size: 15px;
      }
    }
    .late,
    .early {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
@media (max-width: 575.98px) {
  .container-fluid {
    padding: 0;
  }
  .operator {
    padding: 0;
    .table {
      padding: 0;
      thead th {
        font-size: 0.59rem;
        padding: 10px 2px;
      }
      tbody {
        tr {
          td {
            font-size: 0.68rem;
            padding: 5px 1px;
            &:last-child {
              padding-right: 5px;
            }
          }
        }
        tr > th {
          padding: 5px 1px;
        }
        .status {
          padding: 0.05rem 0.3rem;
          margin-top: 0.1rem;
          .green,
          .yellow,
          .orange,
          .red {
            width: 10px;
            height: 10px;
          }
        }
        .trainNr a {
          font-size: 0.7rem;
        }
        .early,
        .late {
          font-size: 0.55rem;
        }
      }
    }
  }
}
@media (max-width: 1199.98px) {
  .h4 {
    font-size: 17px;
  }
  .table {
    th {
      font-size: 14px;
    }
  }
}
</style>


