<template>
  <div class="main">
    <top-header title="Nytt lösenord" />
    <div class="container-fluid">
      <div class="row">
        <div class="
            col-sm-12 col-md-12 col-lg-6
            offset-lg-3 offset-ms-0 offset-md-0
          ">
          <div class="register">
            <div class="header">
              <h3> <i class="bi bi-geo-alt-fill"></i>1409</h3>
            </div>
            <div class="form">
              <div class="alert alert-success" role="alert" v-if="successMessage != null && submitStatus == 'OK'">
                {{ successMessage }}
              </div>
              <p class="small">Mata in det lösenord du vill ha.</p>
              <div class="mb-3">
                <input type="password" class="form-control form-control-lg" id="password" placeholder="Välj lösenord"
                  v-model="password" :class="{
                    'is-invalid': submitStatus == 'ERROR' && v$.password.$error,
                  }" />
                <div class="invalid-feedback" v-if="v$.password.$error">
                  Du måste ange lösenord
                </div>
                <div class="invalid-feedback" v-if="v$.password.minLength">
                  Minst sex tecken
                </div>
              </div>
              <div class="mb-3">
                <input type="password" class="form-control form-control-lg" id="password2" placeholder="Upprepa lösenord"
                  v-model="password2" :class="{
                    'is-invalid':
                      submitStatus == 'ERROR' && v$.password2.$error,
                  }" />
                <div class="invalid-feedback" v-if="v$.password2.$error">
                  Du måste ange lösenordet igen
                </div>
                <div class="invalid-feedback" v-if="v$.password2.sameAsPassword">
                  Lösenorden är inte likadana
                </div>
              </div>
              <div class="alert alert-danger" role="alert" v-if="errorMessage != null && submitStatus == 'ERROR'">
                {{ errorMessage }}
              </div>
              <div class="d-grid gap-3">
                <button class="btn btn-success btn-block btn-lg mt-3" @click="register()">
                  Spara
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopHeader from "@/components/TopHeader";
import Box from "@/components/Box";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
export default {
  name: "register",
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      password: null,
      password2: null,
      submitStatus: null,
      errorMessage: null,
      successMessage: null,
      loading: false,
      reportButtonEnabled: true,
      showLinks: false,
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  validations() {
    return {
      password: { required, minLength: minLength(6) },
      password2: { sameAsPassword: sameAs(this.password), required },
    };
  },

  components: {
    TopHeader,
    Box,
  },

  methods: {
    register() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        this.$axios
          .post(this.baseUrl + "resetPassword.php", {
            password: this.password,
            key: this.$route.query.key,
          })
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "OK";
              this.$router.push({
                name: "login",
                query: { validationStatus: 9 },
              });
            } else if (response.data.status == "error") {
              this.submitStatus = "ERROR";
              if (response.data.error == 1) {
                this.errorMessage =
                  "Länken är felaktig, för gammal eller redan använd. Beställ en ny.";
              } else if (response.data.error == 2) {
                this.errorMessage =
                  "Länken är inte komplett, testa den igen eller beställ en ny.";
              }
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  background: var(--background-color-secondary);
  padding: 3rem 30px;

  .form {
    max-width: 60%;
    margin: 0 auto;

    input::placeholder {
      font-size: 15px;
    }

    small,
    .invalid-feedback {
      text-align: left;
      padding-left: 5px;
    }

    .alert {
      text-align: left;
    }

  }

  button {
    font-weight: 600;
    background-color: #f5dc2a;
    border-color: #f5dc2a;

    &:focus {
      box-shadow: none;
    }

    &:active {
      background-color: #f5dc2a;
      border-color: #f5dc2a;
    }
  }

  .header {
    text-align: center;
  }

  .login {
    font-size: 14px;
    font-weight: 600;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .form {
      max-width: 100%;
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    .form {
      max-width: 100%;
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    .form {
      max-width: 100%;
    }
  }

  @media (max-width: 1199.98px) {
    .form {
      max-width: 100%;
    }
  }
}
</style>
