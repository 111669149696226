<template>
  <div class="main">
    <top-header :title="$t('navbar.login')" />
    <div class="container-fluid login-page">
      <div class="row">
        <div class="
              col-sm-12 col-md-12 col-lg-6
              offset-lg-3 offset-ms-0 offset-md-0
             ">
          <div class="login mt-5">
            <div class="header mb-5">
             <h3> <i class="bi bi-geo-alt-fill"></i>1409</h3>

            </div>
            <div class="form">
              <div class="alert alert-success" role="alert" v-if="successMessage != null">
                {{ successMessage }}
              </div>
              <div class="alert alert-danger" role="alert" v-if="errorMessage != null">
                {{ errorMessage }}
                <p v-if="noVerfication">
                  Klicka
                  <a href="#" @click="goToValidate()">här</a> om du vill få ett
                  nytt mail med valideringslänk.
                </p>
              </div>
              <div class="mb-3">
                <input type="email" class="form-control form-control-lg" id="email"
                  @keyup.enter="validate ? sendMail() : login()" placeholder="Ange e-postadress" v-model="email" :class="{
                    'is-invalid': submitStatus == 'ERROR' && v$.email.$error,
                  }" />
                <div class="invalid-feedback" v-if="v$.email.$error">
                  Du måste ange e-postadress
                </div>
                <div class="invalid-feedback" v-if="v$.email.email">
                  Ange giltig e-postadress
                </div>
              </div>
              <div class="mb-3" v-if="!validate">
                <input type="password" class="form-control form-control-lg" id="password" @keyup.enter="login()"
                  placeholder="Lösenord" v-model="password" :class="{
                    'is-invalid': submitStatus == 'ERROR' && v$.password.$error,
                  }" />
                <div class="invalid-feedback" v-if="v$.password.$error">
                  Du måste ange lösenord
                </div>
              </div>
              <div class="d-grid gap-2">
                <button class="btn btn-success btn-lg mt-3" @click="validate ? sendMail() : login()">
                  {{ validate ? "Skicka länk" : "Logga in" }}
                </button>
              </div>
              <!-- Har du redan ett konto? Logga in. -->
              <p class="register mt-3">
                Har du inget konto än?
                <router-link :to="{ name: 'register' }">Registrera dig.</router-link>
                <br />
                Behöver du ett nytt lösenord?
                <router-link :to="{ name: 'passwordrequest' }">Beställ nytt lösenord.</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopHeader from "@/components/TopHeader";
import Box from "@/components/Box";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
export default {
  name: "login",
  metaInfo() {
    const title = this.title;
    return {
      title: title,
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      email: null,
      password: null,
      submitStatus: null,
      errorMessage: null,
      successMessage: null,
      validate: false,
      noVerfication: false,
      title: this.$i18n.t("navbar.login"),
    };
  },

  validations() {
    return {
      email: { required, email },
      password: { required },
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  components: {
    TopHeader,
    Box,
  },

  mounted() {
    this.validateAuth();
    var vs = this.$route.query.validationStatus;
    if (vs != null) {
      if (vs == "ok") {
        this.successMessage =
          "Din e-postadress har validerats och du kan nu logga in!";
      } else if (vs == 1) {
        this.errorMessage =
          "Valideringslänken har redan använts eller är för gammal, testa om du kan logga in.";
      } else if (vs == 2) {
        this.errorMessage =
          "Kontrollera valideringslänken och försök igen, alla tecken kom inte med.";
      } else if (vs == 6) {
        this.successMessage =
          "Du är registrerad och ett mail har skickats med en aktiveringslänk. Efter att du klickat på den kan du logga in.";
      } else if (vs == 8) {
        this.successMessage =
          "Ange din e-postadress så får du en länk för att välja ett nytt lösenord.";
      } else if (vs == 9) {
        this.successMessage = "Logga in med ditt nya lösenord.";
      } else {
        this.errorMessage =
          "Ett fel har inträffat som kräver att du kontaktar oss på synpunkter@tydalsystems.se";
      }
    }
  },

  methods: {
    validateAuth() {
      if (this.$store.state.apiKey) {
        this.$router.push({ name: "home" });
      }
    },

    goToValidate() {
      this.noVerfication = false;
      this.validate = true;
      this.errorMessage = null;
    },

    sendMail() {
      this.errorMessage = null;
      this.$axios
        .post(this.baseUrl + "mail.php", {
          email: this.email,
        })
        .then((response) => {
          if (response.data.status == "ok") {
            this.submitStatus = "OK";
            this.successMessage =
              "Klicka på länken i e-postmeddelandet för att aktivera ditt konto.";
          } else if (response.data.status == "error") {
            this.submitStatus = "ERROR";
            this.errorMessage =
              "E-postadressen finns inte registrerad. Prova en annan eller registrera dig.";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    login() {
      this.successMessage = null;
      this.noVerfication = false;
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        this.$axios
          .post(this.baseUrl + "login.php", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "OK";
              this.$store.commit("AUTH_LOGIN", response.data);
              location.reload(true);
            } else if (response.data.status == "error") {
              this.submitStatus = "ERROR";
              if (response.data.error == 1) {
                this.errorMessage = "Felaktigt användarnamn eller lösenord.";
              } else if (response.data.error == 2) {
                this.errorMessage = "Du har inte validerat din e-postadress.";
                this.noVerfication = true;
              } else if (response.data.error == 3) {
                this.errorMessage = "Felaktigt användarnamn eller lösenord.";
              } else if (response.data.error == 4) {
                this.errorMessage =
                  "Redan inloggad. Logga ut från den andra enheten/webbläsaren eller vänta någon minut på timeout. Misstänker du att någon annan använder ditt konto: byt lösenord efter att du loggat in.";
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: grey;
}

.login {
  background: var(--background-color-secondary);
  padding: 3rem 30px;
  text-align: center;

  .form {
    max-width: 60%;
    margin: 0 auto;

    input {
      color: var(--text-primary-color);

      &:focus {
        color: var(--text-primary-color);
      }
    }

    input::placeholder {
      color: var(--text-primary-color);
      font-size: 15px;
    }

    .invalid-feedback {
      text-align: left;
      padding-left: 10px;
    }

    .alert {
      text-align: left;
    }
  }

  button {
    font-weight: 600;
    background-color: #f5dc2a;
    border-color: #f5dc2a;
    color: #373737;

    &:focus {
      box-shadow: none;
    }

    &:active {
      background-color: #f1c40f;
      border-color: #f1c40f;

    }
  }

  .btn-success:not(:disabled):not(.disabled):active {
    background-color: #f1c40f;
    border-color: #f1c40f;

  }

  .register {
    font-size: 14px;
    font-weight: 600;

  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .form {
      max-width: 100%;
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    .form {
      max-width: 100%;
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    .form {
      max-width: 100%;
    }
  }

  @media (max-width: 1199.98px) {
    .form {
      max-width: 100%;
    }
  }
}
</style>
