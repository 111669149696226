<template>
  <div class="feedback" :class="{ active: active }">
    <div class="wedget" @click="toggle">
      <span class="comment">
        <i class="bi bi-chat-fill"></i>
      </span>
      <p class="h5">
        {{ active ? $t("feedback.active") : $t("feedback.closed") }}
      </p>
      <span class="expand">
        <i class="bi bi-dash" v-if="active"></i>
        <i class="bi bi-plus" v-else></i>
      </span>
    </div>
    <div class="form-fields" v-show="active">
      <div class="alert alert-success" role="alert" v-if="success">
        {{ message }}
      </div>
      <div class="mb-3">
        <textarea
          class="form-control"
          id="message"
          rows="5"
          :placeholder="$t('feedback.placeholder')"
          v-model="msg"
        ></textarea>
      </div>
      <div class="d-grid gap-2">
        <button type="button" class="btn btn-secondary" @click="send">
          {{ $t("feedback.send") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

var config = {
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
  withCredentials: true,
};
export default {
  data() {
    return {
      active: false,
      msg: "",
      success: false,
      message: "",
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  methods: {
    toggle() {
      this.active = !this.active;
    },

    send() {
      if (this.msg) {
        this.$axios
          .post(
            this.baseUrl + "feedback.php",
            {
              msg: this.msg,
            },
            config
          )
          .then((res) => {
            if (res.status == 200 && res.status < 300) {
              this.success = true;
              this.message = this.$t("feedback.success");
              this.msg = "";
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  width: 300px;
  position: fixed;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 10000;
  background: var(--accent-color);
  border-top-left-radius: 4px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  .wedget {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    .h5 {
      margin: 0;
      font-size: 0.8rem;
      font-weight: 600;
    }
    .expand {
      .bi {
        font-size: 1.25rem;
      }
    }
  }
  .form-fields {
    background: #f1f1f1;
    padding: 15px;
    textarea {
      margin-bottom: 10px;
      &::placeholder {
        font-size: 15px;
        font-weight: 600;
        color: #afafaf;
      }
      &:focus {
        outline: 0;
        border-color: #ddd;
        box-shadow: none;
      }
    }
    button {
      font-weight: 600;
      font-size: 18px;
    }
  }
}
.feedback.active {
  width: 500px;
  .wedget {
    padding: 12px;
    border-bottom: 1px solid #d1d2d2;
  }
}
@media (max-width: 575.98px) {
  .feedback {
    bottom: 10px;
    right: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    // align-items: center;
    // display: flex;
    // justify-content: center;
    box-shadow: 1px 1px 1px 1px #a5a5a5a3;
    .wedget {
      width: 100%;
      height: 100%;
      // border-radius: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0;
      .comment {
        .bi {
          font-size: 24px;
        }
      }
      .h5,
      .expand {
        display: none;
      }
    }
  }
  .feedback.active {
    width: 100%;
    height: auto;
    display: block;
    bottom: 0;
    right: 0;
    border-radius: 0;
    box-shadow: none;
    .wedget {
      justify-content: space-between;
      .comment {
        .bi {
          font-size: 1.2rem;
        }
      }
      .h5,
      .expand {
        display: inline-block;
      }
    }
  }
}
</style>


