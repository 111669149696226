<template>
  <div class="main" :class="lang">
    <top-header title="Trafikstörning" />
    <div class="container-fluid">
      <ul v-if="event != null">
        <li><b>Plats: </b>{{ event.fromtpl }} {{ event.totpl != null ? ' - ' + event.totpl : ''}}</li>
        <li><b>Typ: </b>{{ event.description }}</li>
        <li><b>Inträffade: </b>{{ event.start }}</li>
        <li><b>Prognos: </b>{{ event.bertid }}</li>
        <li><b>Senast uppdaterad: </b>{{ event.ts }}</li>
      </ul>
      <p v-if="$store.state.railway == 'y' && $store.state.authenticated && event != null">
        <span v-html="event.internal"></span>
      </p>
      <div class="operator table-responsive-md">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Tidpunkt</th>
              <th scope="col">Meddelande</th>
            </tr>
          </thead>
          <tbody v-if="history.length > 0">
            <tr v-for="(train, index) in history" :key="index">
              <td>{{ train.ts }}</td>
              <td>
                {{ train.msg.length > 0 ? train.msg : 'Ärendet avslutat.' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center text-secondary" v-if="loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopHeader from "@/components/TopHeader";
export default {
  name: "event",
  props: ["eventID"],
  data() {
    return {
      event: null,
      history: []
    };
  },

  computed: {
    ...mapState(["lang", "loading", "baseUrl", "apiKey"]),
  },

  components: {
    TopHeader,
  },

  created() {
    if (this.$route.query.lang) {
      this.$store.commit("SET_LOCAL_LANGUAGE", this.$route.query.lang);
    }

    this.$i18n.locale = this.lang;
  },

  mounted() {
    this.fetchOperator();
  },

  beforeUnmount() {
    clearTimeout(this.operatorInterval);
  },

  methods: {
    fetchOperator() {
      this.$store.commit("LOADING_STATE", true);
      this.$axios
        .get(
          this.baseUrl +
            "event.php?key=" +
            this.apiKey +
            "&eventID=" +
            this.eventID,
          { withCredentials: true }
        )
        .then((res) => {
          this.$store.commit("LOADING_STATE", false);
          this.history = res.data.history;
          this.event = res.data.event;
        })
        .catch((err) => {
          this.$store.commit("LOADING_STATE", false);
        });
    },

  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding-bottom: 150px;
}
.ti {
  .table {
    thead th {
      font-size: 0.8rem;
    }
    tbody .late,
    tbody .early {
      font-size: 0.73rem;
    }
  }
}
.operator {
  padding: 25px 20px 1px;
  margin: 20px 0;
  background: var(--background-color-secondary);
  .table {
    color: var(--text-primary-color);
    thead th {
      border-bottom: 0;
      svg {
        margin-left: 10px;
        font-size: 20px;
      }
    }
    td {
      .status {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .red,
      .orange,
      .yellow,
      .green {
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }
      .green {
        background: #27ae60;
      }
      .yellow {
        background: #f1c40f;
      }
      .orange {
        background: #e67e22;
      }
      .red {
        background: #e74c3c;
      }
    }
    .trainNr {
      a {
        font-weight: 600;
        font-size: 15px;
      }
    }
    .late,
    .early {
      font-size: 14px;
      font-weight: 600;
    }
    .late {
      color: #e74c3c;
    }
    .early {
      color: #27ae60;
    }
  }
}
@media (max-width: 575.98px) {
  .container-fluid {
    padding: 0;
  }
  .operator {
    padding: 0;
    .table {
      padding: 0;
      thead th {
        font-size: 0.59rem;
        padding: 10px 2px;
      }
      tbody {
        tr {
          td {
            font-size: 0.68rem;
            padding: 5px 1px;
            &:last-child {
              padding-right: 5px;
            }
          }
        }
        tr > th {
          padding: 5px 1px;
        }
        .status {
          padding: 0.05rem 0.3rem;
          margin-top: 0.1rem;
          .green,
          .yellow,
          .orange,
          .red {
            width: 10px;
            height: 10px;
          }
        }
        .trainNr a {
          font-size: 0.7rem;
        }
        .early,
        .late {
          font-size: 0.55rem;
        }
      }
    }
  }
}
@media (max-width: 1199.98px) {
  .h4 {
    font-size: 17px;
  }
  .table {
    th {
      font-size: 14px;
    }
  }
}
</style>


