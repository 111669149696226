<template>
  <div class="main">
    <top-header :title="$t('navbar.document')" />
    <div class="container-fluid">
      <div class="row">
        <div class="col folders">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="title">
                  <i class="bi bi-card-list"></i>
                  <p class="breadcrumb">
                    <template
                      v-for="(breadcrumb, index) in breadcrumbs"
                      :key="index"
                    >
                      <span class="separator" v-if="index > 0">/</span>
                      <button
                        class="btn btn-link"
                        :disabled="index == breadcrumbs.length - 1"
                        @click="breadcrumbPressed(breadcrumb)"
                      >
                        {{ breadcrumb.name }}
                      </button>
                    </template>
                  </p>
                </div>
                <button class="btn btn-success" @click="toggleUpload()">
                  <i class="bi bi-folder-plus"></i> Ny mapp
                </button>
              </div>
              <hr />
              <!-- <pre>{{ folder }}</pre> -->
              <div class="folder-content" v-if="!visible">
                <ul v-if="folder.subfolders.length > 0 || folder.files.length > 0">
                  <li
                    v-for="(folder, index) in folder.subfolders"
                    :key="index"
                    @click="selectFolder(folder)"
                  >
                    <i class="bi bi-folder-fill"></i>
                    <span>{{ folder.name }}</span>
                  </li>
                  <li
                    class="folder-file"
                    v-for="(file, key) in folder.files"
                    :key="key"
                    @click="loadFile(file)"
                    :class="{
                      selected: selectedFile && file.id == selectedFile.id,
                    }"
                  >
                    <i class="bi bi-file-earmark-pdf-fill"></i> {{ file.name }}
                  </li>
                </ul>
              </div>

              <div class="file-upload" v-if="visible">
                <file-upload @change="onChange($event)" />
                <div class="d-grid gap-2 d-md-block">
                  <!-- <button
                    class="btn btn-secondary me-md-2"
                    type="button"
                    @click="uploadFiles()"
                  >
                    <i class="bi bi-paperclip"></i> Spara
                  </button> -->
                  <button
                    class="btn btn-secondary me-md-2"
                    type="button"
                    :disabled="fileLoading"
                    @click="uploadFiles()"
                  >
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      v-if="fileLoading"
                    ></span>
                    <span v-if="fileLoading"> Loading...</span>
                    <span v-if="!fileLoading"
                      ><i class="bi bi-paperclip"></i>Spara</span
                    >
                  </button>
                  <button
                    class="btn btn-danger"
                    type="button"
                    @click="hideUpload()"
                  >
                    Avbryt
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="header" v-if="selectedFile">
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <div class="right-file-header">
                    <p class="m-0">
                      <strong>Namn:</strong> {{ selectedFile.name }}
                    </p>
                    <p class="m-0"><strong>Giltighetstid:</strong> {{ dateFromTime(selectedFile.startDate) }} -- {{ dateFromTime(selectedFile.endDate) }}</p>
                    <p class="m-0">
                      <strong>Skapade:</strong> {{ selectedFile.created_at }}
                    </p>
                  </div>
                  <div class="left-file-header">
                    <button class="btn btn-danger" @click="deleteFile()">
                      <i class="bi bi-trash"></i> Ta bort
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="preview mt-2">
            <div class="card">
              <div class="card-body">
                <template v-if="loading">
                  <div class="d-flex justify-content-center my-4">
                    <div class="spinner-border text-success" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </template>
                <div
                  class="d-flex justify-content-between page-header"
                  v-if="pdfSource"
                >
                  <div class="left">
                    <template v-if="showAllPages && pageCount">
                      <span class="counter"
                        >{{ pageCount }}
                        {{ pageCount > 1 ? "sidor" : "sida" }}</span
                      >
                    </template>
                    <template v-else>
                      <button
                        type="button"
                        class="btn btn-outline-secondary btn-sm"
                        :disabled="page <= 1"
                        @click="page--"
                      >
                        <i class="bi bi-chevron-left"></i>
                      </button>
                      <span class="counter"
                        >{{ page }} / {{ pageCount }}
                        {{ pageCount > 1 ? "sidor" : "sida" }}</span
                      >
                      <button
                        type="button"
                        class="btn btn-outline-secondary btn-sm"
                        :disabled="page >= pageCount"
                        @click="page++"
                      >
                        <i class="bi bi-chevron-right"></i>
                      </button>
                    </template>
                  </div>
                  <div class="right">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="show-pages"
                        v-model="showAllPages"
                      />
                      <label class="form-check-label" for="show-pages">
                        Visa alla sidor
                      </label>
                    </div>
                  </div>
                </div>
                <div class="pdf-content" v-if="pdfSource">
                  <vue-pdf-embed
                    ref="pdfRef"
                    :source="pdfSource"
                    :page="page"
                    @rendered="handleDocumentRender"
                  />
                </div>
                <div class="alert alert-secondary mt-3" v-if="!pdfSource">
                  Förhandsgranskning av vald fil.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader";
import FileUpload from "@/components/FileUpload";
import moment from "moment";
import VuePdfEmbed from "vue-pdf-embed";
import { mapState } from "vuex";
export default {
  name: "document",
  data() {
    return {
      visible: false,
      files: [],
      folders: [],
      documents: [],
      breadcrumbs: [],
      selectedFile: null,
      selectedFolder: 0,
      loading: false,
      page: 1,
      pageCount: 1,
      pdfSource: null,
      showAllPages: false,
      fileLoading: false,
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
    folder() {
      return {
        subfolders: this.folders.filter((f) => {
          return f.parent == this.selectedFolder;
        }),
        files: this.documents.filter((d) => {
          return d.directoryID == this.selectedFolder;
        }),
      };
    },
  },

  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },

  components: {
    TopHeader,
    FileUpload,
    VuePdfEmbed,
  },

  mounted() {
    this.breadcrumbs.push({ folderID: 0, name: "Dokument" });
    this.fetchDocuments();
  },

  methods: {
    dateFromTime(timestamp) {
      var d = moment.unix(timestamp).format("YYYY-MM-DD");
      return d == '9999-12-31' ? 't.v.' : d;
    },
    fetchDocuments() {
      this.$axios
        .get(this.baseUrl + "pro/dokumentadmin.php?key=" + this.apiKey)
        .then((res) => {
          this.folders = res.data.data.directories;
          this.documents = res.data.data.documents;
        });
    },
    toggleUpload() {
      this.visible = true;
    },

    hideUpload() {
      this.visible = false;
    },

    onChange(e) {
      this.files = e;
    },

    uploadFiles() {
      this.fileLoading = true;
      for (let i = 0; i < this.files.length; i++) {
        console.log(this.files[i]);
        if (i == this.files.length - 1) {
          setTimeout(() => {
            this.files = [];
            this.visible = false;
            this.fileLoading = false;
          }, 3000);
        }
        // let formData = new FormData();
        // formData.append("folderID", this.folder.id);
        // formData.append("file", this.files[i]);
        // formData.append("key", this.apiKey);

        // this.$axios
        //   .post(this.baseUrl + "upload.php", formData)
        //   .then((response) => {
        //     if (response.data.status == "ok") {
        //       this.files = [];

        //     }
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      }
    },

    selectFolder(folder) {
      this.pdfSource = null;
      this.selectedFolder = folder.id;

      let found = this.breadcrumbs.find(
        (breadcrumb) => breadcrumb.folderID == folder.id
      );
      if (!found) {
        this.breadcrumbs.push({ folderID: folder.id, name: folder.name });
      }
    },

    breadcrumbPressed(breadcrumb) {
      if (breadcrumb.folderID == 0) {
        this.breadcrumbs = [];
        this.breadcrumbs.push({ folderID: 0, name: breadcrumb.name });
        this.selectedFolder = 0;
      } else {
        this.selectedFolder = breadcrumb.folderID;
        let selectedIndex = null;
        for (let i = 0; i < this.breadcrumbs.length; i++) {
          let element = this.breadcrumbs[i];
          if (element.folderID == breadcrumb.folderID) {
            selectedIndex = i;
          }
          if (i > selectedIndex) {
            this.breadcrumbs.splice(i, 1);
          }
        }
      }
    },

    loadFile(file) {
      this.selectedFile = file;
      this.page = 1;
      this.pdfSource = this.baseUrl + "pro/getfile.php?type=doc&id=" + file.id + "&key=" + this.apiKey;

      // this.loading = true;
      // setTimeout(() => {
      //   this.pdfSource =
      //     "https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf";
      //   this.loading = false;
      // }, 5000);
    },

    handleDocumentRender() {
      this.loading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },

    deleteFile() {
      this.$swal
        .fire({
          icon: "warning",
          title: "Är du säker på att du vill ta bort filen?",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Radera",
          cancelButtonText: "Avbryt",
        })
        .then((result) => {
          if (result.isConfirmed) {
            console.log("File deleted...");
          }
        });
    },

    sizeParser(size) {
      let num = size / 1000;
      return Math.round(num) + " KB";
    },

    dateParser(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
.file-upload.active {
  // visibility: visible;
  display: block;
  // opacity: 1;
}
.files {
  // background: #fff;
  // padding: 25px;
  table {
    th {
      font-size: 12px;
      color: #5a6065;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-top: 0 !important;
    }
  }
}

.folders {
  .title {
    display: flex;
    align-items: center;
    .breadcrumb {
      align-items: center;
    }
    p {
      margin: 0;
      font-size: 20px;
      font-weight: 600;
    }
    .bi {
      margin-right: 7px;
      color: #f4d824;
      font-size: 22px;
    }
    .btn-link {
      color: #000;
      font-weight: 600;
      font-size: 18px;
      text-decoration: none;
    }
  }

  hr {
    margin: 7px 0;
  }
  .folder-content {
    padding: 10px;
    ul {
      list-style: none;
      padding-left: 0;
      li {
        margin-bottom: 5px;
        // background: #f1f1f1;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          // background: #f1f1f1;
          background-color: lightgreen;
          color: #2c3e50;
          .bi {
            color: #d3b910;
          }
          span {
            color: #5a6065;
          }
        }
        .bi {
          color: #f4d824;
          font-size: 20px;
          padding-right: 5px;
        }
        span {
          font-weight: 600;
          color: #2c3e50;
        }
        .bi-file-earmark-pdf-fill {
          color: #e74c3c;
        }
      }
      li.selected {
        font-weight: 600;
      }
    }
  }

  .file-upload {
    padding: 25px;
    background: #fff;
    border: 1px solid #d5d8dc;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

.page-header {
  .left {
    .counter {
      font-size: 15px;
      font-weight: 600;
      margin: 0 10px;
    }
  }
}
</style>
