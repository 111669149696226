<template>
  <div class="operators" :class="lang">
    <p class="h5"><train-icon /> {{ title }}</p>
    <div class="table-responsive-md">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" @click="sortBy('operator')">
              <div class="d-flex justify-content-between">
                <span class="title">{{ $t("operators.company") }}</span>
                <span class="d-flex flex-column carets">
                  <i class="bi bi-arrow-down-up"></i>
                </span>
              </div>
            </th>
            <th scope="col" @click="sortBy('antal')">
              <div class="d-flex justify-content-between">
                <span class="title">{{ $t("operators.intrafik") }}</span>
                <span class="d-flex flex-column carets">
                  <i class="bi bi-arrow-down-up"></i>
                </span>
              </div>
            </th>
            <th scope="col" @click="sortBy('sena')">
              <div class="d-flex justify-content-between">
                <span class="title">{{ $t("operators.delay") }}</span>
                <span class="d-flex flex-column carets">
                  <i class="bi bi-arrow-down-up"></i>
                </span>
              </div>
            </th>
            <th scope="col" @click="sortBy('procent')">
              <div class="d-flex justify-content-between">
                <span class="title">{{ $t("operators.ontime") }}</span>
                <span class="d-flex flex-column carets">
                  <i class="bi bi-arrow-down-up"></i>
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(operator, index) in operators" :key="index">
            <td>
              <span class="operator">
                <router-link
                  :to="{
                    name: 'operator',
                    params: { operatorID: operator.operatorID },
                    query: { name: operator.operator, lang: lang },
                  }"
                  >{{ operator.operator }}</router-link
                >
              </span>
            </td>
            <td>{{ operator.antal }}</td>
            <td>{{ operator.sena }}</td>
            <td>
              <span class="status">
                <span
                  :class="{
                    red: operator.procent < 50,
                    orange: operator.procent >= 50 && operator.procent < 75,
                    yellow: operator.procent >= 75 && operator.procent < 90,
                    green: operator.procent >= 90,
                  }"
                  >&nbsp;</span
                >
                <span class="percent">{{ operator.procent }}%</span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TrainIcon from "@/components/TrainIcon.vue";
import { mapGetters } from "vuex";
export default {
  props: ["operators", "title"],
  data() {
    return {
      sortKey: null,
      ascending: false,
    };
  },

  methods: {
    tCol(colName) {
      return this.$i18n.t(colName);
    },
  },

  computed: {
    ...mapGetters({
      lang: "getLang",
    }),
  },

  components: {
    TrainIcon,
  },

  methods: {
    sortBy(sortKey) {
      if (this.sortKey === sortKey) {
        this.ascending = !this.ascending;
      } else {
        this.ascending = true;
        this.sortKey = sortKey;
      }

      let ascending = this.ascending;

      this.operators.sort((a, b) => {
        if (a[sortKey] > b[sortKey]) {
          return ascending ? 1 : -1;
        } else if (a[sortKey] < b[sortKey]) {
          return ascending ? -1 : 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.operators.ti {
  .h4 {
    font-size: 0.956rem;
  }
  .table {
    thead th {
      font-size: 12px;
    }
    tbody tr th {
      font-size: 14px;
    }
  }
}
.operators {
  background: var(--background-color-secondary);
  padding: 15px;
  table {
    margin-top: 15px;
    color: var(--text-primary-color);
    th {
      padding: 0.7rem;
      border-top: 1px solid #ebedef;
      cursor: pointer;
      &:hover {
        color: var(--text-primary-color);
        background: var(--dropdown-hover-color);
      }
    }
    td {
      padding: 13px;
    }
    .operator {
      font-size: 15px;
      a {
        color: var(--link-color);
        font-weight: 600;
        text-decoration: none;
        &:hover {
          color: var(--main-hover-color);
          text-decoration: none;
        }
      }
    }
    .status {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .red,
      .orange,
      .yellow,
      .green {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .percent {
        font-weight: 600;
        font-size: 14px;
      }
      .green {
        background: #27ae60;
      }
      .yellow {
        background: #f1c40f;
      }
      .orange {
        background: #e67e22;
      }
      .red {
        background: #e74c3c;
      }
    }
  }

  @media (max-width: 575.98px) {
    .table {
      thead th {
        font-size: 0.75rem;
        font-weight: 600;
        padding: 5px;
      }
      tbody {
        th {
          font-size: 12px;
        }
        .status .percent {
          font-size: 12px;
        }
        tr > td {
          padding: 5px;
        }
        tr > th {
          padding: 5px;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .table {
      thead th {
        font-size: 0.75rem;
        font-weight: 600;
      }
      tbody {
        th {
          font-size: 12px;
        }
        .status .percent {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 1199.98px) {
    .h4 {
      font-size: 17px;
    }
    .table {
      th {
        font-size: 14px;
      }
    }
  }
}
</style>
