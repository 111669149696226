import { createApp, ref } from "vue"
import App from "./App.vue"
import router from "@/router"
import store from "@/store"
import i18n from "@/i18n"
import axios from "axios";
import "bootstrap";
import LoadScript from "vue-plugin-load-script";
import Es6Promise from 'es6-promise'
import { createMetaManager, defaultConfig, plugin as vueMetaPlugin } from 'vue-meta'
import VueSweetalert2 from "vue-sweetalert2";

const app = createApp({
  extends: App,
  beforeCreate() {
    this.$store.dispatch('initialiseStore');
  }
});

app.config.globalProperties.$axios = axios;
// app.config.productionTip = false;

const metaManager = createMetaManager()

app.use(router)
app.use(store)
app.use(i18n)
app.use(LoadScript)
app.use(vueMetaPlugin, { keyName: 'metaInfo' })
app.use(metaManager)
app.use(VueSweetalert2)

/* Internet Explorer */
if (typeof Object.assign != 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) { // .length of function is 2
      'use strict';
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

Es6Promise.polyfill()

app.mount("#app")
