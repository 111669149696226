
const subscribe = (store) => {
    store.subscribe((mutation) => {
        switch (mutation.type) {
            case 'FETCH_TPLS':
                localStorage.setItem('tpls', JSON.stringify(mutation.payload))
                break;
            case 'SET_LOCAL_LANGUAGE':
                localStorage.setItem('lang', JSON.stringify(mutation.payload))
                break;
            case 'TPLS_AGE':
                localStorage.setItem('tplsAge', JSON.stringify(mutation.payload))
                break;
            case 'AUTH_LOGIN':
                localStorage.setItem('_1409_name', mutation.payload['realname'])
                localStorage.setItem('_1409_apiKey', mutation.payload['key'])
                localStorage.setItem('_1409_railway', mutation.payload['railway'])
                localStorage.setItem('_1409_subscription', mutation.payload['validSubscription'])
                localStorage.setItem('_1409_hideMap', mutation.payload['hideMap'])
                break;
            case 'AUTH_LOGOUT':
                localStorage.removeItem('_1409_name')
                localStorage.removeItem('_1409_apiKey')
                localStorage.removeItem('_1409_railway')
                localStorage.removeItem('_1409_hideMap')
                localStorage.removeItem('_1409_subscription')
                break;
            default:
                break;
        }
    })
}

export default subscribe