<template>
  <div class="main">
    <top-header title="Stationshistorik" />
    <div class="container-fluid">
      <box style="margin-top: 15px; position: relative">
        <div class="row">
          <div class="col-md-6">
            <search-input
              :searched="tpl"
              :datum="date"
              @onPress="searchTrainStation"
              :showDatePicker="false"
              @toggle="toggleStation"
            />
            <radio-input :selected="typ" @changed="typChanged" />
          </div>
        </div>
      </box>
      <p class="lastupdate" v-if="updated">
        {{ tpl }}. {{ $t("curUpdate") }}:
        <span>{{ updated }}</span>
      </p>
      <div class="train-station table-responsive-md">
        <table class="table table-striped" :class="lang" v-if="typ === 'avg'">
          <thead>
            <tr>
              <th scope="col">{{ $t("table.avg") }}</th>
              <th scope="col">{{ $t("table.till") }}</th>
              <th scope="col">{{ $t("table.spar") }}</th>
              <th scope="col">{{ $t("table.operator") }}</th>
              <th scope="col" style="text-align: right">
                {{ $t("table.tag") }}
              </th>
              <th scope="col">{{ $t("table.pos") }}</th>
              <th scope="col">Just nu</th>
            </tr>
          </thead>
          <tbody v-if="avgdata.length > 0">
            <tr v-for="(avg, index) in avgdata" :key="index">
              <td>
                <span style="text-decoration: line-through">{{
                  avg.avgtid
                }}</span
                >&nbsp;
                <span
                  style="font-weight: bold"
                  :class="avg.delay < 0 ? 'sen' : 'tidig'"
                  >{{ avg.realtime }}</span
                >
              </td>
              <td>{{ avg.station }}</td>
              <td>
                <strong>{{
                  avg.slag == "Rst"
                    ? avg.sp
                    : avg.slag == "Gt"
                    ? "Godståg"
                    : "Tjänstetåg"
                }}</strong>
              </td>
              <td class="koncept">{{ avg.koncept }}</td>
              <td class="trainNr" style="text-align: right">
                <router-link
                  :to="{
                    name: 'position',
                    query: { tagNr: avg.tagNr, datum: avg.datum, lang: lang },
                  }"
                  >{{ avg.tagNr }}</router-link
                >
              </td>
              <td>
                {{ avg.curtpl ? avg.curtpl : ""
                }}{{ avg.nexttpl ? " - " + avg.nexttpl : "" }}
                {{ avg.speed ? "(" + avg.speed + " km/h)" : "" }}
              </td>
              <td
                class="ti"
                v-bind:class="{ sen: avg.delay < 0, tidig: avg.delay >= 0 }"
              >
                {{ avg.curtpl ? avg.delay &lt; 0 ?
                avg.delay == -1 ? '1' + $t('table.enminsen') : Math.abs(avg.delay) + $t('table.minsen') : avg.delay == 0 ? $t('table.itid') : avg.delay == 1 ? avg.delay + $t('table.enmintid') : avg.delay + $t('table.mintid') : '' }}
              </td>
              <td :class="lang">{{ avg.beskrivning }}</td>
            </tr>
            <tr class="loading" v-if="avgdata.length == 0 && loading">
              <td colspan="10">{{ $t("app.loading") }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table table-striped" :class="lang" v-if="typ == 'ank'">
          <thead>
            <tr>
              <th scope="col">{{ $t("table.ank") }}</th>
              <th scope="col">{{ $t("table.fran") }}</th>
              <th scope="col">{{ $t("table.spar") }}</th>
              <th scope="col">{{ $t("table.nytid") }}</th>
              <th scope="col">{{ $t("table.operator") }}</th>
              <th scope="col" style="text-align: right">
                {{ $t("table.tag") }}
              </th>
              <th scope="col">{{ $t("table.pos") }}</th>
              <th scope="col">{{ $t("table.status") }}</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody v-if="ankdata.length > 0">
            <tr v-for="(ank, index) in ankdata" :key="index">
              <td>{{ ank.anktid }}</td>
              <td>{{ ank.fromtpl }}</td>
              <td>
                <strong>{{ ank.sp }}</strong>
              </td>
              <td>
                <em>{{ ank.bertid ? ank.bertid : "" }}</em>
              </td>
              <td class="koncept">{{ ank.koncept }}</td>
              <td class="trainNr" style="text-align: right">
                <router-link
                  :to="{
                    name: 'position',
                    query: { tagNr: ank.tagNr, datum: ank.datum, lang: lang },
                  }"
                  >{{ ank.tagNr }}</router-link
                >
              </td>
              <td>
                {{ ank.curtpl ? ank.curtpl : ""
                }}{{ ank.nexttpl ? " - " + ank.nexttpl : "" }}
                {{ ank.speed ? "(" + ank.speed + " km/h)" : "" }}
              </td>
              <td
                class="ti"
                v-bind:class="{ sen: ank.delay < 0, tidig: ank.delay >= 0 }"
              >
                {{ ank.curtpl ? ank.delay &lt; 0 ?
                ank.delay == -1 ? '1' + $t('table.enminsen') : Math.abs(ank.delay) + $t('table.minsen') : ank.delay == 0 ? $t('table.itid') : ank.delay == 1 ? ank.delay + $t('table.enmintid') : ank.delay + $t('table.mintid') : '' }}
              </td>
              <td>{{ ank.beskrivning }}</td>
            </tr>
            <tr class="loading" v-if="avgdata.length == 0 && loading">
              <td colspan="10">{{ $t("app.loading") }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TopHeader from "@/components/TopHeader";
import SearchInput from "@/components/SearchInput";
import RadioInput from "@/components/RadioInput";
import SelectLanguage from "@/components/SelectLanguage";
import Box from "@/components/Box";
import { mapGetters, mapState } from "vuex";
export default {
  name: "stationHistory",
  props: ["urltpl", "urltyp"],
  metaInfo() {
    const title = this.title;
    return {
      title: title,
    };
  },
  
  data() {
    return {
      date: "",
      tpl: "",
      typ: "",
      avgdata: [],
      ankdata: [],
      loading: false,
      title: "Stationshistorik",
      spinfoInterval: null,
      updated: "",
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
    ...mapGetters({
      lang: "getLang",
    }),
  },

  components: {
    TopHeader,
    Box,
    SearchInput,
    RadioInput,
    SelectLanguage,
  },

  created() {
    if (this.$route.query.lang) {
      this.$store.commit("SET_LOCAL_LANGUAGE", this.$route.query.lang);
    }
    this.tpl = this.urltpl;
    this.typ = this.urltyp;
    this.$i18n.local = this.lang;
    this.fetchTrainStations();
  },

  beforeUnmount() {
    clearTimeout(this.spinfoInterval);
  },

  methods: {
    toggleStation(val, selectedDate) {
      if (val && !isNaN(val)) {
        this.$router.push({
          name: "position",
          query: { tagNr: val, datum: selectedDate, lang: this.lang },
        });
      }
      if (val && isNaN(val)) {
        this.tpl = val;
        this.date = selectedDate;
        this.fetchTrainStations();
        this.$router.push({
          name: "stationHistory",
          params: { urltpl: val, urltyp: this.typ },
          query: { lang: this.lang },
        });
      }
    },

    typChanged(val) {
      this.typ = val;
      this.$router.push({
        name: "stationHistory",
        params: { urltpl: this.tpl, urltyp: val },
        query: { lang: this.lang },
      });
      this.updateTitle();
    },

    searchTrainStation(value, selectedDate) {
      if (value && !isNaN(value)) {
        this.$router.push({
          name: "position",
          query: { tagNr: value, datum: selectedDate, lang: this.lang },
        });
      }
      if (value && isNaN(value)) {
        this.tpl = value;
        this.date = selectedDate;
        this.fetchTrainStations();
        this.$router.push({
          name: "stationHistory",
          params: { urltpl: this.tpl, urltyp: this.typ },
          query: { lang: this.lang },
        });
      }
    },

    fetchTrainStations() {
      if (this.tpl) {
        this.$axios
          .get(
            this.baseUrl +
              "spi_hist.php?tpl=" +
              this.tpl +
              "&lang=" +
              this.lang,
            { withCredentials: true }
          )
          .then((res) => {
            this.avgdata = res.data.avg;
            this.ankdata = res.data.ank;
            this.tpl = res.data.tpl;
            this.updateTitle();
            this.updated = moment().format("HH:mm:ss");
            this.spinfoInterval = setTimeout(() => {
              this.fetchTrainStations();
            }, 60000);
          });
      }
    },

    updateTitle() {
      if (this.typ === "ank") {
        this.title = this.$i18n.t("table.anktag") + " " + this.tpl + " - 1409";
      } else {
        this.title = this.$i18n.t("table.avgtag") + " " + this.tpl + " - 1409";
      }
    },

    setLanguage(lang) {
      this.$i18n.local = lang;
    },

    onLanguageSelected(lang) {
      this.$i18n.local = lang;
      this.$store.commit("SET_LOCAL_LANGUAGE", lang);
      this.updateTitle();
      this.fetchTrainStations();
    },
  },
};
</script>

<style lang="scss" scoped>
.nomobile {
  display: block;
}
#mobilerating {
  display: none;
}
.nobr {
  white-space: nowrap;
}
.container-fluid {
  padding-bottom: 150px;
  .lastupdate {
    margin: 0;
    font-size: 13px;
    margin-top: 15px;
    padding-left: 5px;
    span {
      font-weight: 600;
      font-size: 12px;
    }
  }
  .train-station {
    background: #fff;
    padding: 15px;
    margin-top: 15px;
    .table {
      thead th {
        border-bottom: 0;
      }
      tbody tr {
        .sen,
        .tidig {
          font-weight: 600;
          font-size: 15px;
        }
        .sen {
          color: #e74c3c;
        }
        .tidig {
          color: #27ae60;
        }
        .koncept {
          font-size: 15px;
        }
        .trainNr {
          a {
            font-weight: 600;
            color: #148f77;
          }
        }
      }
    }
    .table.ti {
      tr th,
      tr .ti {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 575.98px) {
    padding: 0px;
    .train-station {
      margin: 15px 0;
      padding: 5px 3px;
    }
    .table {
      thead th {
        font-size: 0.59rem;
        padding: 10px 2px;
        border: 0;
      }
      tbody {
        tr > td {
          font-size: 0.72rem;
          padding: 5px 1px;
        }
        tr > th {
          padding: 5px 1px;
        }
        .trainNr {
          font-size: 0.57rem;
        }
      }
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    .nomobile {
      display: none;
    }
    #mobilerating {
      display: inline;
    }
  }

  @media (max-width: 1199.98px) {
    .select {
      float: none;
      min-width: 100%;
      select {
        min-width: 100%;
      }
    }
  }
}
</style>


