<template>
  <div class="main">
    <top-header :title="$t('navbar.plus')" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="members">
            <h1 class="title"><i class="bi bi-person-fill-check"></i>
              {{ $t("plus.member-header") }}
            </h1>
            <div class="divider mb-3"></div>
            <p>
              {{ $t("plus.member-info") }}</p>
            <p>{{ $t("plus.member-limit") }}</p>
            <p>{{ $t("plus.support-info") }}<a href="mailto:support@tydalsystems.se">support@tydalsystems.se</a>

            </p>

            <div class="col-md-4">
              <button type="button" class="btn btn-secondary btn-block btn-lg mt-3" @click="logout()">
                {{ $t("plus.logout") }} <i class="bi bi-box-arrow-in-right" style="color:white;"></i>
              </button>
            </div>
          </div>
          <br />
          <div class="members">
            <h1 class="title"><i class="bi bi-gear-fill"></i>
              {{ $t("plus.settings") }}</h1>
            <p>
              {{ $t("plus.hide") }}
              <input type="checkbox" value="y" v-model="hideMap" @change="saveSettings()" />
            </p>
          </div>
          <br />
          <div class="members">
            <h1 class="title"><i class="bi bi-newspaper"></i>
              {{ $t("news.news-header") }}</h1>
            <div class="divider mb-3"></div>
            <p>
              <b>2022-10-04:</b> Tjänstetåg finns med i översikten per operatör.
            </p>
            <p>
              <b>2022-05-27:</b> Möjlighet att betala för mer än en månad åt
              gången.
            </p>
            <p>
              <b>2022-04-19:</b> Tabellen som visas när man klickar på en
              operatör kan nu sorteras efter valfri kolumn.
            </p>
            <p>
              <b>2022-01-13:</b> Operatörer visas tydligare på stationssidan,
              med logo.
            </p>
            <p>
              <b>2022-01-12:</b> Möjlighet att växla mellan ljus och mörk
              bakgrund.
            </p>
            <p>
              <b>2021-11-03:</b> Se en översikt över
              <router-link :to="{ name: 'cancelStart' }">inställda tåg</router-link>.
            </p>
            <p>
              <b>2021-03-20:</b> Möjlighet att dölja kartan på startsidan med
              inställningen ovan.
            </p>
            <p>
              <b>2020-12-15:</b> Nu kan du se tidigare trafikstörningar, klicka
              på "Arkiv" på startsidan intill aktuella trafikstörningar.
            </p>
            <p>
              <b>2020-10-20:</b> Ny version av apparna med Tågvy (följ tåg) för
              betalande användare.
            </p>
            <p>
              <b>2020-10-16:</b> Nu kan du som är inloggad som järnvägsföretag
              testa att se kvalitetsavgifter.
              <router-link :to="{ name: 'quality' }">Klicka här</router-link>.
            </p>
            <p>
              <b>2020-10-12:</b> Nya Android- och iOS-versioner på väg ut där
              man kan spara sitt användarnamn och lösenord i appen samt se
              godstågsöversikten.
            </p>
            <p>
              <b>2020-10-04:</b> Nya Android- och iOS-versioner släppta där
              kartuppdateringsbuggen är fixad.
            </p>
            <p><b>2020-09-20:</b> iOS-versionen med inloggning är släppt.</p>
            <p>
              <b>2020-09-19:</b> När man tagit fram detaljerna om ett tåg på
              kartan går det nu att klicka på tågnumret.
            </p>
            <p>
              <b>2020-09-18:</b> Androidversionen där man kan logga in är nu
              släppt och finns på Play. Samma inloggningsuppgifter som här.
              iOS-versionen kommer om någon dag.
            </p>
            <p>
              <b>2020-09-04:</b> Nu påbörjar vi vidareutvecklingen av 1409.
              Vilka funktioner vill du se? Använd feedbackrutan nere till höger!
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6" v-if="account != null && account.validSubscription != null">
          <div class="members">
            <h1 class="title"><i class="bi bi-geo-alt-fill"></i> 1409 Premium</h1>
            <div class="divider mb-3"></div>
            <div v-if="account.activeSubscription == 'n'">
              <p>
                {{ $t("premium.access") }}
                {{ subscriptionExpired() ? $t("premium.expired") : $t("premium.expires") }}
                {{ account.validSubscription }}.
              </p>
              <p>
                {{ $t("premium.prolong") }}
              </p>
              <h4> {{ $t("premium.subscribers") }} <i class="bi bi-shield-fill-check"></i>
              </h4>
              <p>
                {{ $t("premium.active-subscription") }}
              </p>
            </div>

            <div v-if="account.activeSubscription == 'y' && !subscriptionExpired()">
              <!--Översättning ska fortsätta här-->
              <p>
                {{ $t("premium.valid-subscription") }} {{ account.validSubscription }} {{ $t("premium.v-s-2") }}
                {{ account.months }} {{ $t("premium.month") }}{{ account.months > 1 ? "er" : "" }} {{
                  $t("premium.price-of") }} {{ account.price }} {{ $t("premium.until") }}
              </p>
              <h4>{{ $t("premium.subscribers") }} <i class="bi bi-shield-fill-check"></i></h4>
              <p>
                {{ $t("premium.active-subscription") }}
              </p>
              <strong>{{ $t("premium.cancel-subscription-text") }}</strong>
              <div class="col-md-6">
                <button class="btn btn-success btn-block btn-lg mt-3 subscribe" @click="unsubscribe()">
                  {{ $t("premium.cancel-subscription-button") }}
                </button>
              </div>
            </div>
            <div>
              <div v-if="account.activeSubscription == 'y' && subscriptionExpired()">
                <p>
                  {{ $t("premium.not-renewed") }} {{ account.validSubscription }}. {{ $t("premium.start-again") }}
                </p>
                <h4>{{ $t("premium.subscribers") }}</h4>
                <p>
                  {{ $t("premium.active-subscription") }}
                </p>


                <div class="col-md-6">
                  <button class="btn btn-success btn-block btn-lg mt-3 subscribe" @click="unsubscribe()">
                    {{ $t("premium.cancel-subscription-button") }}
                  </button>
                </div>
              </div>
            </div>
            <br />
            <div class="card" v-if="account.activeSubscription != 'y'">
              <div class="card-body">
                <div class="icon">
                  <i class="bi bi-shield-fill-check" style="font-size:72px;"></i>
                </div>
                <div class="content">
                  <h2>{{ $t("price.price-level") }} {{ this.pricelist[30] }} {{ $t("price.sek-month") }}</h2>
                  <ul>
                    <li>{{ $t("price.no-ads") }}</li>
                    <li>{{ $t("price.more-func") }}</li>
                    <li>{{ $t("price.money-to") }}</li>
                  </ul>

                </div>
              </div>
            </div>
            <div class="card mt-3">
              <div class="card-body">
                <div class="content">
                  <h2>{{ $t("timeperiod.choose-time") }} *</h2>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="time-period-30"
                      v-model="timePeriod" value="30" />
                    <label class="form-check-label" for="time-period-30">1 {{ $t("timeperiod.month") }} {{ pricelist[30]
                    }} kr.</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="time-period-90"
                      v-model="timePeriod" value="90" />
                    <label class="form-check-label" for="time-period-90">3 {{ $t("timeperiod.months") }} {{ pricelist[90]
                    }} kr.</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="time-period-182"
                      v-model="timePeriod" value="182" />
                    <label class="form-check-label" for="time-period-182">6 {{ $t("timeperiod.months") }} {{
                      pricelist[182] }} kr.</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="time-period-365"
                      v-model="timePeriod" value="365" />
                    <label class="form-check-label" for="time-period-365">12 {{ $t("timeperiod.months") }} {{
                      pricelist[365] }} kr.</label>
                  </div>
                  <div class="row col-12" v-if="account.activeSubscription == 'n'">
                    <div class="col-5 buy-box">
                      <div class="row mob-small">{{ $t("timeperiod.renewed") }} <br />
                        <br />
                        {{ $t("timeperiod.cancel") }}
                      </div>
                      <div class="row col-8 mob-small"><button type="button" class="btn btn-success btn-buy"
                          @click="subscribe()">{{ $t("timeperiod.btn-buy1") }} <i class="bi bi-check"
                            style="color:white;"></i>
                        </button>
                      </div>
                    </div>

                    <div class="col-5 buy-box">
                      <div class="row  mob-small">{{ $t("timeperiod.limited") }}</div>
                      <div class="row"><br /><br /><br /></div>
                      <div class="row col-8 mob-small"><button type="button" class="btn btn-success btn-buy"
                          @click="pay()">{{ $t("timeperiod.btn-buy2") }} <i class="bi bi-check" style="color:white;"></i>
                        </button>
                      </div>
                    </div>
                    <p class="mt-2">* {{ $t("timeperiod.choose") }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!--  <div class="row" v-if="account.activeSubscription == 'n'">
              <div class="col-md-3 col-lg-3">
                <button class="btn btn-success btn-block btn-lg mt-3 subscribe" @click="subscribe()">
                  Prenumerera
                </button>
              </div>
              <div class="col-md-2 col-lg-">
                <button class="btn btn-success btn-block btn-lg mt-3 subscribe" @click="pay()">
                  1 gång
                </button>
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6" v-if="account != null && account.validSubscription == null">
          <div class="members">
            <h1 class="title">Vill du prenumerera?</h1>
            <div class="divider mb-3"></div>
            <div class="card">
              <div class="card-body">
                <div class="icon">
                  <i class="bi bi-check-circle-fill"></i>
                </div>
                <div class="content">
                  <h2>{{ this.pricelist[30] }} kr/månad</h2>
                  <ul>
                    <li>Ingen reklam.</li>
                    <li>Fler funktioner.</li>
                    <li>Pengarna går till att vidareutveckla 1409.</li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <p>
              Om du prenumerar betalas ett belopp automatiskt varje
              månad/kvartal/halvår/år tills du väljer att avsluta
              prenumerationen. Du kan enkelt avsluta med en knapptryckning på
              den här sidan. Om du hellre vill sköta all betalning manuellt kan
              du välja att bara betala en gång. Du kan när som helst fylla på
              kontot med en period till genom att betala en gång till.
            </p>
            <h4>Som prenumerant är du garderad mot framtida prishöjningar</h4>
            <p>
              I takt med att vi introducerar fler funktioner kommer priset för
              1409 att stiga. Som prenumerant behåller du samma låga pris som
              när du startade prenumerationen, så länge som den är aktiv.
              Givetvis får du även tillgång till alla nya funktioner.
            </p>
            <div class="card">
              <div class="card-body">
                <div class="content">
                  <h2 class="mb-2">Välj tidsperiod</h2>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="time-period-30"
                      v-model="timePeriod" value="30" />
                    <label class="form-check-label" for="time-period-30">1 månad à {{ pricelist[30] }} kr.</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="time-period-90"
                      v-model="timePeriod" value="90" />
                    <label class="form-check-label" for="time-period-90">3 månader à {{ pricelist[90] }} kr.</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="time-period-182"
                      v-model="timePeriod" value="182" />
                    <label class="form-check-label" for="time-period-182">6 månader à {{ pricelist[182] }} kr.</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="time-period-365"
                      v-model="timePeriod" value="365" />
                    <label class="form-check-label" for="time-period-365">12 månader à {{ pricelist[365] }} kr.</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-lg-6">
                <button class="btn btn-success btn-block btn-lg mt-3 subscribe" @click="subscribe()">
                  Prenumerera
                </button>
              </div>
              <div class="col-md-6 col-lg-6">
                <button class="btn btn-success btn-block btn-lg mt-3 subscribe" @click="pay()">
                  1 gång
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopHeader from "@/components/TopHeader";
import Box from "@/components/Box";
import moment from "moment";

export default {
  name: "plus",
  metaInfo() {
    const title = this.title;
    return {
      title: title,
    };
  },

  data() {
    return {
      trainIndustry: null,
      email: null,
      hideMap: null,
      title: this.$i18n.t("navbar.plus"),
      submitStatus: null,
      account: null,
      updateTimer: null,
      pricelist29: { 30: 29.0, 90: 85.0, 182: 170.0, 365: 339.0 },
      pricelist39: { 30: 39.0, 90: 109.0, 182: 215.0, 365: 429.0 },
      pricelist49: { 30: 49.0, 90: 139.0, 182: 269.0, 365: 499.0 },
      pricelist: null,
      timePeriod: 30,
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  components: {
    TopHeader,
    Box,
  },

  mounted() {
    this.fetchAccount();
  },

  unmounted() {
    clearTimeout(this.updateTimer);
  },

  methods: {
    pay() {
      this.$router.push({
        name: "pay",
        params: { timePeriod: this.timePeriod },
      });
    },

    subscribe() {
      this.$router.push({
        name: "subscribe",
        params: { timePeriod: this.timePeriod },
      });
    },

    subscriptionExpired() {
      return moment(this.account.validSubscription) < moment();
    },

    saveSettings() {
      this.$axios
        .get(
          this.baseUrl +
          "settings.php?key=" +
          this.apiKey +
          "&hideMap=" +
          this.hideMap
        )
        .then((response) => {
          localStorage.setItem("_1409_hideMap", this.hideMap ? "y" : "n");
          this.fetchAccount();
        })
        .catch((error) => {
          this.logout();
        });
    },

    unsubscribe() {
      if (!confirm("Är du säker på att du vill säga upp prenumerationen?")) {
        return;
      }
      this.$axios
        .get(this.baseUrl + "pay/unsubscribe.php?key=" + this.apiKey)
        .then((response) => {
          this.fetchAccount();
        })
        .catch((error) => {
          this.logout();
        });
    },

    fetchAccount() {
      if (this.updateTimer != null) {
        clearTimeout(this.updateTimer);
      }

      this.$axios
        .get(this.baseUrl + "account.php?key=" + this.apiKey)
        .then((response) => {
          this.account = response.data;
          this.hideMap = this.account.hideMap;
          if (this.account.subscriptionRate == 29) {
            this.pricelist = this.pricelist29;
          } else if (this.account.subscriptionRate == 39) {
            this.pricelist = this.pricelist39;
          } else {
            this.pricelist = this.pricelist49;
          }
          if (this.account.subscriptionDays) {
            this.account.months = Math.round(
              this.account.subscriptionDays / 30
            );
            this.timePeriod = this.account.subscriptionDays;
          } else {
            this.account.months = 1;
            this.account.subscriptionDays = 30;
          }
          this.account.price = this.pricelist[this.account.subscriptionDays];
          this.updateTimer = setTimeout(() => {
            this.fetchAccount();
          }, 10000);
        })
        .catch((error) => {
          this.logout();
        });
    },

    logout() {
      this.$axios
        .get(this.baseUrl + "logout.php?key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$store.commit("AUTH_LOGOUT");
            location.reload(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: grey;
}

.bi {
  color: #f1c40f;
}

.form-check-input:checked {
  background-color: #6d6d6d;
  border-color: #6d6d6d;
}

.members {
  background: var(--background-color-secondary);
  padding: 3rem 30px;

  .title {
    font-size: 30px;
    font-weight: 900;
  }

  #email::placeholder {
    font-size: 15px;
  }

  .invalid-feedback {
    text-align: left;
    padding-left: 10px;
  }

  .divider {
    border-top: 1px solid #ddd;
  }

  .card {
    color: var(--text-primary-color);

    .card-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background: var(--accent-color);

      .icon {
        flex: 1;
        text-align: center;

        svg {
          font-size: 35px;
        }
      }

      .content {
        flex: 4;
        text-align: left;

        h2 {
          font-size: 22px;
          font-weight: 900;
          margin: 0;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .subscribe {
    font-weight: 600;
    background-color: #f1c40f;
    border-color: #f1c40f;


    &:focus {
      box-shadow: none;
    }

    &:active {
      background-color: #f1c40f;
      border-color: #f1c40f;
    }
  }
}

.buy-box {
  background-color: var(--background-buy-box);
  border-radius: 4px;
  margin: 5px;
  min-height: 150px;
  padding: 20px;
}

.btn-buy {
  margin-left: 120px;
  margin-top: 30px;
}





@media (max-width: 575.98px) {

  .btn-buy {
    font-size: 9px;
    padding: 1px;
    margin-left: 0px;
    position: absolute;
    margin-top: 10px;
  }

  .buy-box {
    min-height: 300px;
    padding: 20px;
  }
}

@media (max-width: 991.98px) {
  .btn-buy {
    padding: 5px;
    margin-left: 10px;
    position: absolute;
    max-width: 180px;
    margin-top: 10px;
  }

  .buy-box {
    min-height: 170px;
  }
}

@media (max-width: 1199.98px) {
  .btn-buy {
    padding: 5px;
    margin-left: 0px;
    position: absolute;
    max-width: 220px;
    margin-top: 10px;
  }

  .buy-box {
    min-height: 170px;
  }
}

@media (max-width: 1760px) {
  .btn-buy {
    font-size: 12px;
    padding: 5px;
    margin-left: 0px;
    position: absolute;
    max-width: 150px;
    margin-top: 10px;
  }

  .buy-box {
    min-height: 220px;
  }

  .mob-small {
    font-size: 12px;
  }
}
</style>
