import { createStore } from "vuex"
import { langs } from '@/Langs';
import axios from 'axios';
import subscribe from "./modules/subscribe";

export default createStore({
    state: {
        baseUrl: process.env.VUE_APP_BASE_URL,
        apiKey: localStorage.getItem('_1409_apiKey') || null,
        hideMap: localStorage.getItem('_1409_hideMap') || 'n',
        authenticated: (localStorage.getItem('_1409_apiKey') != undefined),
        railway: localStorage.getItem('_1409_railway') || null,
        subscription: localStorage.getItem('_1409_subscription') || null,
        langs: langs || [],
        lang: JSON.parse(localStorage.getItem('lang')) || 'sv',
        tpls: JSON.parse(localStorage.getItem('tpls')) || [],
        tplsAge: JSON.parse(localStorage.getItem('tplsAge')) || '',
        loading: false,
        name: localStorage.getItem('_1409_name') || null
    },

    plugins: [subscribe],

    modules: {

    },

    mutations: {
        AUTH_LOGIN(state, payload) {
            state.authenticated = true
            state.apiKey = payload['key']
            state.name = payload['realname']
            state.railway = payload['railway']
            state.subscription = payload['validSubscription']
            state.hideMap = payload['hideMap']
        },

        AUTH_LOGOUT(state) {
            state.authenticated = false
            state.apiKey = null
            state.railway = null
            state.subscription = null
        },
        FETCH_TPLS(state, payload) {
            state.tpls = payload
        },

        SET_LOCAL_LANGUAGE(state, payload) {
            state.lang = payload
        },

        LOADING_STATE(state, payload) {
            state.loading = payload
        },

        TPLS_AGE(state, payload) {
            state.tplsAge = payload
        }
    },

    getters: {
        getTpls(state) {
            return state.tpls
        },

        getLang(state) {
            return state.lang
        },

        getTplsAge(state) {
            return state.tplsAge
        },

        getUser(state) {
            return state.name
        }
    },

    actions: {
        initialiseStore({ state, commit }) {
            if (state.apiKey) {
                state.authenticated = true
            } 
            
            // else {
            //     console.log('Initial store...')
            //     commit('AUTH_LOGOUT')
            // }
        },

        login({ commit, state }, formData) {
            return new Promise((resolve, reject) => {
                axios.post(state.baseUrl + 'api.php?action=login', {
                    email: formData.email,
                    password: formData.password
                })
                    .then((response) => {
                        if (response.data.status == 'ok') {
                            commit('AUTH_LOGIN', response.data)
                            resolve(response)
                        }
                    })
                    .catch((error) => {
                        commit('AUTH_LOGOUT')
                        reject(error)
                    })
            })
        },

        register({ commit, state }, formData) {
            return new Promise((resolve, reject) => {
                axios.post(state.baseUrl + 'api.php?action=register', {
                    name: formData.name,
                    email: formData.email,
                    password: formData.password
                })
                    .then((response) => {
                        if (response.data.status == 'ok') {
                            resolve(response)
                        }
                    })
                    .catch((error) => {
                        commit('AUTH_LOGOUT')
                        reject(error)
                    })
            })
        },

        fetchTpls({ state, commit }) {
            return new Promise((resolve, reject) => {
                axios.get(state.baseUrl + 'tpls.php')
                    .then((res) => {
                        commit('FETCH_TPLS', res.data)
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        }
    }
})