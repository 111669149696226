<template>
  <section class="train-position table-responsive-md">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" class="head">
            {{ $t("operators.company") }}
          </th>
          <th scope="col" class="head">
            {{ $t("operator.distance") }}
          </th>
        </tr>
      </thead>
      <tbody v-if="trainres">
        <tr v-for="(r, index) in trainres" :key="index">
          <td><a href="#" @click="$emit('newTrain', r.trainID)"> {{ r.company }} ({{ r.uicID }})</a></td>
          <td>{{ r.fromtpl }} - {{ r.totpl }}</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["trainres", "tagNr", "date"],

  computed: {
    ...mapGetters({
      lang: "getLang",
    }),
  },
};
</script>

<style lang="scss" scoped>
.about {
  padding: 20px;
  margin-bottom: 15px;
  background: var(--background-color-secondary);

  .h4 {
    font-weight: 600;
    margin-bottom: 15px;
  }
}

.company {
  background-image: url(../assets/bg_4.png);
  padding: 4em 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.card-pro {
  -webkit-box-shadow: 0 4px 30px -5px black;
  box-shadow: 0 4px 30px -5px black;
  border-radius: 7px;
  margin: 10px;
}

.mb-3 {
  color: #181818;
  letter-spacing: 0, 1px;
  margin-bottom: 1rem !important;
}


.btn {
  cursor: pointer;
  border-radius: 0px;
  border: 2px solid transparent;
  font-size: 14px;
  letter-spacing: .2em;
  text-transform: uppercase;
  color: #999999;
  -webkit-box-shadow: 0 5px 20px -5px rgb(0 0 0 / 50%);
  box-shadow: 0 5px 20px -5px rgb(0 0 0 / 50%);
}

.btn.btn-success {
  background-color: #28a745;
  border: 2px solid #28a745;
  color: #fff;
}

.btn :hover {
  background-color: black;
}

@media (max-width: 575.98px) {
  .card-pro {
    min-width: 100%;
  }

  .btn {
    min-width: 100%;
    margin: 0px;
  }
}
</style>


