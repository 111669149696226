<template>
  <div class="main" :class="lang">
    <top-header @changed="selectLangChange" />
    <section class="top container-fluid">
      <div class="row">
        <div class="col-md-6 leftcol">
          <div class="search" id="search-box">
            <search-input searched :datum="date" @inputValue="inputValue" @selectedDate="selectedDate"
              @onPress="searchTrainPosition" :showDatePicker="false" @toggle="selectedStation" />
          </div>
          <div class="row">
            <div class="col-md-6">
              <box id="reg-box" styles="height: auto" v-if="$store.state.authenticated == false">
                <p class="h5">{{ $t("register.register") }}</p>
                <p>
                  {{ $t("register.member") }}
                </p>
                <button type="button" class="btn btn-dark black-round">
                  <router-link :to="{ name: 'register' }">{{ $t("register.reg") }}</router-link>
                </button>
                <button type="button" class="btn btn-warning yellow-round">
                  <a href="https://info.1409.se" target="_blank">{{ $t("register.pro-train") }}</a>

                </button>
              </box>
              <box styles="height: auto" v-else-if="
                $store.state.authenticated && !$store.state.subscription
              ">
                <p class="h5">Stöd oss</p>
                <p>
                  Som betalade medlem slipper du reklam och får tillgång till
                  fler funktioner. Pengarna går till utveckling av 1409. Du kan
                  betala för 1, 3, 6 eller 12 månader på en gång.
                  <router-link :to="{ name: 'plus' }">Stöd oss</router-link>
                </p>
              </box>
              <box styles="height: auto" v-else>
                <p class="h5">{{ $t("start-news.title") }}</p>
                <p>
                  {{ $t("start-news.on") }}
                  <router-link :to="{ name: 'plus' }">{{ $t("start-news.profile") }}</router-link>
                  {{ $t("start-news.info-text") }}.
                </p>
              </box>
            </div>
            <div class="col-md-6">
              <box styles="height: auto" id="app-box">
                <p>
                  {{ $t("download.download") }}
                  <a href="https://play.google.com/store/apps/details?id=se.tydal.app1409">Android</a>
                  {{ $t("download.and") }}
                  <a href="https://apps.apple.com/se/app/1409/id1475461107">iPhone/iPad</a>.
                </p>
                <a href="https://play.google.com/store/apps/details?id=se.tydal.app1409"><img
                    alt="Ladda ned på Google Play" src="/google-play-badge.png" width="165" height="64" /></a>
                <a href="https://apps.apple.com/se/app/1409/id1475461107"><img alt="Ladda ned på App Store"
                    src="/appstore.svg" width="132" height="44" /></a>
              </box>
            </div>
          </div>
          <box styles="height: auto">
            <p class="h5">
              <i class="bi bi-info-circle-fill"></i>
              <span>{{ $t("info.title") }}</span>
            </p>
            <hr />
            <p class="msg" v-html="
              $t('info.message', [
                totalTrains,
                totalTrainPercent,
                totalFreights,
                totalFreightPercent,
              ])
            "></p>
          </box>
          <trafic-messages :messages="messages" />
          <div v-if="$store.state.hideMap != 'y'">
            <box styles="height: auto" v-if="apiKey">
              <div class="form-check form-check-inline">
                <label class="form-check-label" for="Rst"><a href="#" style="font-weight: bold"
                    @click.prevent="setOperator('Rst')">{{ $t("category.passenger") }}</a></label>
                <input type="radio" value="Rst" name="Rst" v-model="slag" class="form-check-input"
                  @change="setSlag($event)" />
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label" for="Gt"><a href="#" style="font-weight: bold"
                    @click.prevent="setOperator('Gt')">{{ $t("category.freight") }}</a></label>
                <input type="radio" value="Gt" v-model="slag" class="form-check-input" @change="setSlag($event)" />
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label" for="Tjt"><a href="#" style="font-weight: bold"
                    @click.prevent="setOperator('Tjt')">{{ $t("category.non-revenue") }}</a></label>
                <input type="radio" value="Tjt" v-model="slag" class="form-check-input" @change="setSlag($event)" />
              </div>
            </box>
          </div>
          <train-operators v-if="$store.state.hideMap != 'y'" :operators="chosenOperators" :title="chosenTitle" />
        </div>
        <div class="col-md-6 v-map" v-if="$store.state.hideMap != 'y'">
          <box class="map">
            <trafic-status :key="lang" />
          </box>
        </div>
        <div v-else class="col-md-6">
          <box styles="height: auto" v-if="apiKey">
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="Rst"><a href="#" style="font-weight: bold"
                  @click.prevent="setOperator('Rst')">Persontåg</a></label>
              <input type="radio" value="Rst" name="Rst" v-model="slag" class="form-check-input"
                @change="setSlag($event)" />
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="Gt"><a href="#" style="font-weight: bold"
                  @click.prevent="setOperator('Gt')">Godståg</a></label>
              <input type="radio" value="Gt" v-model="slag" class="form-check-input" @change="setSlag($event)" />
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="Tjt"><a href="#" style="font-weight: bold"
                  @click.prevent="setOperator('Tjt')">Tjänstetåg</a></label>
              <input type="radio" value="Tjt" v-model="slag" class="form-check-input" @change="setSlag($event)" />
            </div>
          </box>
          <train-operators v-if="apiKey" :operators="chosenOperators" :title="chosenTitle" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SearchInput from "@/components/SearchInput";
import TraficMessages from "@/components/TraficMessages";
import TrainOperators from "@/components/TrainOperators";
import Box from "@/components/Box";
import TopHeader from "@/components/TopHeader";
import SelectLanguage from "@/components/SelectLanguage";
import TraficStatus from "@/views/TraficStatus";
import { mapState, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "home",
  metaInfo() {
    const title = this.title;
    return {
      title: title,
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          name: "title",
          content: "1409 | " + title,
        },
        {
          vmid: "og:description",
          property: "og:description",
          name: "description",
          content:
            "1409.se är en sida för resenärer som vill hålla koll på var tågen befinner sig, ankomster och avgångar för olika stationer, och vad förseningar beror på.",
        },
      ],
    };
  },

  data() {
    return {
      messages: [],
      keyword: "",
      date: moment().format("YYYY-MM-DD"),
      operators: [],
      freightOperators: [],
      serviceOperators: [],
      chosenOperators: [],
      chosenTitle: null,
      slag: "Rst",
      totalTrains: 0,
      totalTrainPercent: 0,
      totalFreights: 0,
      title: this.$i18n.t("traficInfo"),
      totalFreightPercent: 0,
      statsInterval: null,
      storInterval: null,
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
    ...mapGetters({
      lang: "getLang",
    }),
  },

  components: {
    SearchInput,
    TraficMessages,
    TrainOperators,
    TopHeader,
    Box,
    TraficStatus,
    SelectLanguage,
  },

  created() {
    this.setLocalLanguage();
  },

  mounted() {
    this.fetchTrainOperators();
    this.computeTotalTrainPercentage();
    this.fetchTraficMessages();
    var val = this.$route.params.trainType;
    if (val) {
      this.slag = val;
      this.setOperator(val);
    }
  },

  beforeUnmount() {
    clearTimeout(this.storInterval);
    clearTimeout(this.statsInterval);
  },

  methods: {
    setLocalLanguage() {
      const storageLang = JSON.parse(localStorage.getItem("lang"));
      var language = window.navigator.language;
      var lang = language.split("-");
      this.$i18n.local = lang[0];

      if (!storageLang) {
        this.$store.commit("SET_LOCAL_LANGUAGE", lang[0]);
      }
    },

    setOperator(val) {
      if (val == "Rst") {
        this.chosenOperators = this.operators;
        this.chosenTitle = this.$i18n.t("operators.title");
      } else if (val == "Gt") {
        this.chosenOperators = this.freightOperators;
        this.chosenTitle = this.$i18n.t("operators.titleFreight");
      } else if (val == "Tjt") {
        this.chosenOperators = this.serviceOperators;
        this.chosenTitle = this.$i18n.t("operators.titleOthers");
      }
      this.$router.push({
        name: "homeWithTrainType",
        params: { trainType: val },
      });
      if (this.slag != val) {
        this.slag = val;
      }
    },

    setSlag(event) {
      this.setOperator(event.target.value);
    },

    inputValue(value, date) {
      this.keyword = value;
    },

    selectedDate(date) {
      this.date = date;
    },

    selectedStation(val, selectedDate) {
      this.searchTrainPosition(val, selectedDate);
    },

    searchTrainPosition(selectedValue, selectedDate) {
      this.keyword = selectedValue;
      if (selectedDate) {
        this.date = selectedDate;
      } else {
        this.date = new Date().toLocaleDateString();
      }

      if (this.keyword) {
        if (this.keyword && parseInt(this.keyword)) {
          this.$router.push({
            name: "position",
            params: { trainNr: this.keyword },
            query: {
              tagNr: this.keyword,
              datum: moment(this.date).format("YYYY-MM-DD"),
              lang: this.lang,
            },
          });
        } else {
          this.$router.push({
            name: "station",
            params: { urltpl: this.keyword, urltyp: "avg" },
            query: { lang: this.lang },
          });
        }
      }
    },

    fetchTraficMessages() {
      this.$axios
        .get(
          this.baseUrl +
          "new_stor3.php?finished=false&lang=" +
          this.lang +
          "&key=" +
          this.apiKey
        )
        .then((res) => {
          this.messages = res.data.data;
          this.storInterval = setTimeout(() => {
            this.fetchTraficMessages();
          }, 60000);
        });
    },

    fetchTrainOperators() {
      this.setOperator(this.slag);
      this.$axios
        .get(this.baseUrl + "new_stats.php")
        .then((res) => {
          this.operators = res.data;
          this.setOperator(this.slag);
          this.computeTotalTrainPercentage(res.data);
          this.statsInterval = setTimeout(() => {
            this.fetchTrainOperators();
          }, 60000);
        })
        .catch((err) => {
          // Log error
        });
      this.$axios
        .get(this.baseUrl + "gt.php")
        .then((res) => {
          this.totalFreights = res.data.antal;
          this.totalFreightPercent = Math.round(
            ((this.totalFreights - res.data.sena) / this.totalFreights) * 100
          );
        })
        .catch((err) => {
          // Log error
        });
      if (this.$store.state.authenticated) {
        this.$axios
          .get(this.baseUrl + "gt_stats.php?key=" + this.apiKey)
          .then((res) => {
            this.freightOperators = res.data;
            this.setOperator(this.slag);
          })
          .catch((err) => {
            this.$store.commit("AUTH_LOGOUT");
            this.$router.push({ name: "login" });
          });
        this.$axios
          .get(this.baseUrl + "tjt_stats.php?key=" + this.apiKey)
          .then((res) => {
            this.serviceOperators = res.data;
            this.setOperator(this.slag);
          })
          .catch((err) => {
            this.$store.commit("AUTH_LOGOUT");
            this.$router.push({ name: "login" });
          });
      }
    },

    computeTotalTrainPercentage() {
      if (this.operators.length > 0) {
        let late = 0;
        let total = 0;
        this.operators.forEach((operator) => {
          total += operator.antal;
          late += operator.sena;
        });
        this.totalTrains = total;
        this.totalTrainPercent = Math.round(((total - late) / total) * 100);
      }
    },

    selectLangChange(selectedLang) {
      clearTimeout(this.storInterval);
      this.fetchTraficMessages();
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: grey;
}

a:visited {
  color: grey;
}

a:hover {
  color: rgb(81, 80, 80);
}

a:active {
  color: rgb(91, 91, 91);
}

.form-check-input:checked {
  background-color: #6d6d6d;
  border-color: #6d6d6d;
}

.black-round {
  padding-top: 5px;
  border-radius: 50px;
  min-width: 150px;
  border: 1px solid white;
}

.black-round a {
  color: white;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.5pt
}

.yellow-round {
  margin-left: 10px;
  background-color: #f5dc2a;
  border: none;
  padding-top: 5px;
  border-radius: 50px;
  min-width: 150px;
}

.yellow-round a {
  color: #373737;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.5pt
}

.main {
  margin: 0 auto;
}

.main.ti {
  .search {
    .form-control {
      &::placeholder {
        font-size: 1rem;
      }
    }
  }

  .leftcol {
    .box {
      .h5 {
        span {
          font-size: 0.9rem;
        }

        .bi {
          margin-right: 5px;
        }
      }

      .msg {
        font-size: 0.845rem;
      }
    }
  }

  .v-map {
    .h6 {
      font-size: 0.8rem;
    }
  }
}

.v-navbar {
  background-color: var(--background-color-primary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #dee2e6;

  .langs {
    span {
      font-size: 1rem;
      font-weight: 600;
      color: #757575;
    }
  }
}

.container-fluid {
  padding-bottom: 150px;
}

.top {
  margin: 15px auto;

  .leftcol {
    .h5 {
      font-weight: 600;

      .bi {
        margin-right: 5px;
      }
    }

    .box,
    .trafic-messages,
    .operators {
      margin: 15px 0;
    }
  }

  .search {
    padding: 20px;
    background: var(--background-color-secondary);
  }

  .v-map {
    .h6 {
      font-size: 0.96rem;
    }

    .spectrum {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .rec {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 40px;
      }

      .perc {
        font-size: 0.7rem;
        font-weight: 600;
        padding-top: 5px;
      }

      .green,
      .light-green,
      .yellow,
      .light-orange,
      .orange,
      .light-red,
      .red {
        width: 100%;
        height: 100%;
      }

      .green {
        background: #008000;
      }

      .light-green {
        background: #aef463;
      }

      .yellow {
        background: #fdf40c;
      }

      .light-orange {
        background: #fdcd0c;
      }

      .orange {
        background: #ffa500;
      }

      .light-red {
        background: #fd700c;
      }

      .red {
        background: #ff0000;
      }
    }
  }
}


@media (max-width: 575.98px) {

  #search-box {
    margin-top: -20px;
    max-height: 100px;
  }

  #reg-box {
    margin-top: 10px;

    height: 240px;
  }

  #app-box {
    margin-top: -5px;

    max-height: 120px;
  }

  .black-round {
    width: 205px;
  }

  .yellow-round {
    margin-top: 5px;
    margin-left: -0px;
  }

  .top {
    .search {
      height: 150px;
    }

    .v-map {
      min-height: auto;

      .box {
        height: auto;
        margin-bottom: 15px;
      }

      .spectrum {
        .perc {
          font-size: 0.5rem;
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {}

@media (max-width: 1199.98px) {

  .top {
    margin-top: 0;

    .leftcol {
      margin-top: 15px;

      .h5 {
        font-size: 17px;
      }

      P {
        font-size: 15px;
      }
    }

    .v-map {
      margin-top: 15px;
    }
  }
}</style>

