<template>
  <div class="main" :class="lang">
    <top-header title="Förseningar" />
    <select style="margin-left: 16px" v-model="tplID" @change="fetchOperator()">
    <option :value="null">Välj trafikcentral</option>
      <option v-for="(t, index) in dlc" :key="index" :value="t.tplID">
        {{ t.dlc }}
      </option>
    </select>
    <div class="container-fluid">
      <div class="operator table-responsive-md">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Ann. tågnr</th>
              <th scope="col">Operatör</th>
              <th scope="col">Position</th>
              <th scope="col">Status</th>
              <th scope="col">Uppdaterat</th>
              <th scope="col">Nästa uppdatering</th>
            </tr>
          </thead>
          <tbody v-if="res.length > 0">
            <template v-for="(train, index) in res" :key="'res' + index">
              <tr>
                <td class="trainNr">
                  <router-link
                    :to="{
                      name: 'position',
                      query: { tagNr: train.atagNr, datum: train.depdate },
                    }"
                    >{{ train.atagNr }}</router-link
                  >
                </td>
                <td>{{ train.operator }}</td>
                <td>{{ train.namn }} ({{ train.tpl }})</td>
                <td class="late">{{ timeDelay(train.delay) }}</td>
                <td>
                  {{ timeFormat(train.timeAtLocation) }}
                </td>
                <td>{{ train.next }}</td>
              </tr>
              <tr>
                <td colspan="6">
                  <table class="table">
                    <thead>
                      <th scope="col">Tekn. tågnr</th>
                      <th scope="col">Uppehåll</th>
                      <th scope="col">Plan ank</th>
                      <th scope="col">Ny tid</th>
                      <th scope="col">Skyltad diff</th>
                    </thead>
                    <tbody>
                      <tr v-for="(t, i) in train.stations" :key="'stat' + i">
                        <td>{{ t.tagNr }}</td>
                        <td>{{ t.namn }} ({{ t.tpl }})</td>
                        <td>{{ timeFormat(t.anktid) }}</td>
                        <td>
                          <em>{{
                            t.bertid ? timeFormat(t.bertid) : "(ingen)"
                          }}</em>
                        </td>
                        <td class="late">
                          {{
                            t.bertid ? timeDelay(diff(t.anktid, t.bertid)) : ""
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5" v-if="tplID">
                Inga förseningar större än fem minuter som inte har
                prognosticerats.
              </td>
              <td colspan="5" v-else>
              Välj en trafikcentral ovan.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center text-secondary" v-if="loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Laddar...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopHeader from "@/components/TopHeader";
import moment from "moment";
export default {
  name: "operator",
  metaInfo() {
    const title = this.title;
    return {
      title: title,
    };
  },

  data() {
    return {
      dlc: [
        { tplID: 121, dlc: "Boden" },
        { tplID: 425, dlc: "Gävle" },
        { tplID: 436, dlc: "Göteborg" },
        { tplID: 445, dlc: "Hallsberg" },
        { tplID: 883, dlc: "Malmö" },
        { tplID: 992, dlc: "Norrköping" },
        { tplID: 1278, dlc: "Stockholm" },
        { tplID: 1620, dlc: "Ånge" },
      ],
      operator: null,
      res: [],
      title: "Förseningar",
      operatorInterval: null,
      tplID: null,
    };
  },

  computed: {
    ...mapState(["lang", "loading", "baseUrl", "apiKey"]),
  },

  components: {
    TopHeader,
  },

  created() {
    if (this.$route.query.lang) {
      this.$store.commit("SET_LOCAL_LANGUAGE", this.$route.query.lang);
    }
    this.$i18n.locale = this.lang;
  },

  mounted() {
    this.init();
  },

  beforeUnmount() {
    clearTimeout(this.operatorInterval);
  },

  methods: {
    getDlc() {
      for (var i = 0; i < this.dlc.length; i++) {
        if (this.tplID == this.dlc[i].tplID) {
          return this.dlc[i].dlc;
        }
      }
    },
    getTplID(dlc) {
      for (var i = 0; i < this.dlc.length; i++) {
        if (this.dlc[i].dlc == dlc) {
          return this.dlc[i].tplID;
        }
      }
    },
    init() {
      if (this.$route.params.dlc) {
        this.tplID = this.getTplID(this.$route.params.dlc);
        this.fetchOperator();
      }
    },
    fetchOperator() {
      if (!this.tplID) {
        return;
      }
      if (this.$route.params.dlc != this.getDlc()) {
        this.$router.push({
          name: "trvdlc",
          params: { dlc: this.getDlc() },
        });
      }

      this.$store.commit("LOADING_STATE", true);
      this.$axios
        .get(this.baseUrl + "trv_delays.php?tplID=" + this.tplID)
        .then((res) => {
          this.$store.commit("LOADING_STATE", false);
          this.res = res.data.data;
          if (this.operatorInterval != null) {
            clearTimeout(this.operatorInterval);
          }
          this.operatorInterval = setTimeout(() => {
            this.fetchOperator();
          }, 60000);
        })
        .catch((err) => {
          this.$store.commit("LOADING_STATE", false);
        });
    },

    timeDelay(time) {
      if (time == 0) {
        return this.$t("table.itid");
      } else if (time == 1) {
        return time + this.$t("table.enmintid");
      } else if (time > 1) {
        return time + this.$t("table.mintid");
      } else if (time == -1) {
        return Math.abs(time) + this.$t("table.enminsen");
      } else if (time < -1) {
        return Math.abs(time) + this.$t("table.minsen");
      }
    },

    diff(t1, t2) {
      var duration = moment.duration(moment(t2).diff(moment(t1)));
      return duration.asMinutes() * -1;
    },

    timeFormat(date) {
      if (date) {
        return moment(date).format("HH:mm");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding-bottom: 150px;
}
.ti {
  .table {
    thead th {
      font-size: 0.8rem;
    }
    tbody .late,
    tbody .early {
      font-size: 0.73rem;
    }
  }
}
.operator {
  padding: 25px 20px 1px;
  margin: 20px 0;
  background: var(--background-color-secondary);
  .table {
    color: var(--text-primary-color);
    thead th {
      border-bottom: 0;
      svg {
        margin-left: 10px;
        font-size: 20px;
      }
    }
    td {
      .status {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .red,
      .orange,
      .yellow,
      .green {
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }
      .green {
        background: #27ae60;
      }
      .yellow {
        background: #f1c40f;
      }
      .orange {
        background: #e67e22;
      }
      .red {
        background: #e74c3c;
      }
    }
    .trainNr {
      a {
        font-weight: 600;
        // color: #148F77;
        font-size: 15px;
      }
    }
    .late,
    .early {
      font-size: 14px;
      font-weight: 600;
    }
    .late {
      color: #e74c3c;
    }
    .early {
      color: #27ae60;
    }
  }
}
@media (max-width: 575.98px) {
  .container-fluid {
    padding: 0;
  }
  .operator {
    padding: 0;
    .table {
      padding: 0;
      thead th {
        font-size: 0.59rem;
        padding: 10px 2px;
      }
      tbody {
        tr {
          td {
            font-size: 0.68rem;
            padding: 5px 1px;
            &:last-child {
              padding-right: 5px;
            }
          }
        }
        tr > th {
          padding: 5px 1px;
        }
        .status {
          padding: 0.05rem 0.3rem;
          margin-top: 0.1rem;
          .green,
          .yellow,
          .orange,
          .red {
            width: 10px;
            height: 10px;
          }
        }
        .trainNr a {
          font-size: 0.7rem;
        }
        .early,
        .late {
          font-size: 0.55rem;
        }
      }
    }
  }
}
@media (max-width: 1199.98px) {
  .h4 {
    font-size: 17px;
  }
  .table {
    th {
      font-size: 14px;
    }
  }
}
</style>
