<template>
  <div class="top-header" :class="lang">
    <div class="box">
      <div class="brand" v-if="$route.name == 'home' || $route.name == 'homeWithTrainType'">
        <img src="@/assets/1409T.png" alt="1409" height="42" width="42" style="margin-right: 5px" />
        <a href="/">
          1409
          <span class="info">{{ $t("traficInfo") }}</span>
        </a>
      </div>
      <div class="breadcrumbs" v-if="$route.name != 'home' && $route.name != 'homeWithTrainType'">
        <a href="/">
          <img src="@/assets/1409T.png" alt="1409" height="42" width="42" style="margin-right: 5px" />
        </a>
        <span class="separator"></span>
        <span class="title">{{ title }}</span>
      </div>
      <div class="nav-right" v-show="width > 1199.98">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link :to="{ name: 'home' }" class="nav-link">{{
                $t("navbar.start")
            }}</router-link>
          </li>
          
          <li class="nav-item">
            <router-link :to="{ name: 'document' }" class="nav-link">{{
                $t("navbar.document")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'about' }" class="nav-link">{{
                $t("navbar.about")
            }}</router-link>
          </li><!--
          <li class="nav-item">
            <router-link :to="{ name: 'faq' }" class="nav-link">{{
                $t("navbar.faq")
            }}</router-link>
          </li>-->
          <li class="nav-item">
            <router-link :to="{ name: 'feedback' }" class="nav-link">{{
                $t("navbar.feedback")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'plus' }" class="nav-link">{{
                $store.state.authenticated
                  ? $store.state.name
                  : $t("navbar.login")
            }}</router-link>
          </li>
          <li class="nav-item">
            <input @change="toggleTheme" id="checkbox" type="checkbox" class="switch-checkbox" />
            <label for="checkbox" class="switch-label">
              <span><svg id="Component_5_1" data-name="Component 5 – 1" xmlns="http://www.w3.org/2000/svg" width="25"
                  height="25" viewBox="0 0 25 25">
                  <circle id="Ellipse_20" data-name="Ellipse 20" cx="12.5" cy="12.5" r="12.5" fill="#262626" />
                  <path id="Icon_weather-moon-alt-first-quarter" data-name="Icon weather-moon-alt-first-quarter"
                    d="M4.488,9.924A6.009,6.009,0,0,1,4.97,7.556a6.157,6.157,0,0,1,1.3-1.945,6.092,6.092,0,0,1,6.682-1.3,6.181,6.181,0,0,1,1.951,1.3,6.092,6.092,0,0,1,1.3,6.682,6.181,6.181,0,0,1-1.3,1.951,6.1,6.1,0,0,1-6.687,1.3,6.157,6.157,0,0,1-1.945-1.3A6.189,6.189,0,0,1,4.488,9.924Zm5.993,5.457h.1a5.332,5.332,0,0,0,2.119-.434,5.478,5.478,0,0,0,1.739-1.165,5.57,5.57,0,0,0,1.165-1.739,5.239,5.239,0,0,0,.434-2.119,5.325,5.325,0,0,0-.434-2.113,5.478,5.478,0,0,0-1.165-1.739A5.57,5.57,0,0,0,12.7,4.906a5.239,5.239,0,0,0-2.119-.434h-.1Z"
                    transform="translate(23.088 22.927) rotate(180)" fill="#f3f3f3" />
                  <path id="Icon_ionic-md-sunny" data-name="Icon ionic-md-sunny"
                    d="M12.7,2.25H10.879V4.976H12.7V2.25Zm5.5,1.861L16.561,5.747,17.834,7.02,19.47,5.385,18.2,4.111Zm-12.812,0L4.111,5.385,5.747,7.02,7.02,5.747,5.385,4.111Zm.954,7.679Zm14.992-.907H18.605V12.7h2.726V10.883Zm-16.355,0H2.25V12.7H4.976V10.883Zm12.858,5.677-1.273,1.273L18.2,19.47,19.47,18.2l-1.636-1.636Zm-12.088,0L4.111,18.2,5.385,19.47,7.02,17.834,5.747,16.561ZM12.7,18.605H10.879v2.726H12.7V18.605Z"
                    transform="translate(0.71 1.209)" fill="#f3f3f3" />
                </svg>

              </span>
              <span><svg id="Component_6_1" data-name="Component 6 – 1" xmlns="http://www.w3.org/2000/svg" width="25"
                  height="25" viewBox="0 0 25 25">
                  <circle id="Ellipse_5" data-name="Ellipse 5" cx="12.5" cy="12.5" r="12.5" fill="#fff" />
                  <path id="Icon_weather-moon-alt-first-quarter" data-name="Icon weather-moon-alt-first-quarter"
                    d="M4.488,9.924A6.009,6.009,0,0,1,4.97,7.556a6.157,6.157,0,0,1,1.3-1.945,6.092,6.092,0,0,1,6.682-1.3,6.181,6.181,0,0,1,1.951,1.3,6.092,6.092,0,0,1,1.3,6.682,6.181,6.181,0,0,1-1.3,1.951,6.1,6.1,0,0,1-6.687,1.3,6.157,6.157,0,0,1-1.945-1.3A6.189,6.189,0,0,1,4.488,9.924Zm5.993,5.457h.1a5.332,5.332,0,0,0,2.119-.434,5.478,5.478,0,0,0,1.739-1.165,5.57,5.57,0,0,0,1.165-1.739,5.239,5.239,0,0,0,.434-2.119,5.325,5.325,0,0,0-.434-2.113,5.478,5.478,0,0,0-1.165-1.739A5.57,5.57,0,0,0,12.7,4.906a5.239,5.239,0,0,0-2.119-.434h-.1Z"
                    transform="translate(23.088 22.927) rotate(180)" />
                  <path id="Icon_ionic-md-sunny" data-name="Icon ionic-md-sunny"
                    d="M12.7,2.25H10.879V4.976H12.7V2.25Zm5.5,1.861L16.561,5.747,17.834,7.02,19.47,5.385,18.2,4.111Zm-12.812,0L4.111,5.385,5.747,7.02,7.02,5.747,5.385,4.111Zm.954,7.679Zm14.992-.907H18.605V12.7h2.726V10.883Zm-16.355,0H2.25V12.7H4.976V10.883Zm12.858,5.677-1.273,1.273L18.2,19.47,19.47,18.2l-1.636-1.636Zm-12.088,0L4.111,18.2,5.385,19.47,7.02,17.834,5.747,16.561ZM12.7,18.605H10.879v2.726H12.7V18.605Z"
                    transform="translate(0.709 1.209)" />
                </svg></span>
              <div class="switch-toggle" :class="{ 'switch-toggle-checked': userTheme == 'dark-theme' }"></div>
            </label>
          </li>
        </ul>
        <select-language @selected="selectedLanguage" />
      </div>
      <span class="bars" @click="toggleOpen">
        <i class="bi bi-list"></i>
      </span>
    </div>
    <hr class="divider" />

    <div class="overlay" :class="{ active: visible }">
      <span class="close-overlay" @click="toggleClose">&times;</span>
      <div class="overlay-content">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a href="#" @click="goTo('home')" class="nav-link">{{
                $t("navbar.start")
            }}</a>
          </li>
          <li class="nav-item">
            <a href="#" @click="goTo('about')" class="nav-link">{{
                $t("navbar.about")
            }}</a>
          </li>
          <li class="nav-item">
            <a href="#" @click="goTo('feedback')" class="nav-link">{{
                $t("navbar.feedback")
            }}</a>
          </li>
          <li class="nav-item">
            <a href="#" @click="goTo('plus')" class="nav-link">{{
                $store.state.authenticated
                  ? $store.state.name
                  : $t("navbar.login")
            }}</a>
          </li>
          <li class="nav-item" style="display: flex; justify-content: center; padding: 17px 0 10px">
            <input @change="toggleTheme" id="checkbox" type="checkbox" class="switch-checkbox" />
            <label for="checkbox" class="switch-label">
              <span><svg id="Component_5_1" data-name="Component 5 – 1" xmlns="http://www.w3.org/2000/svg" width="25"
                  height="25" viewBox="0 0 25 25">
                  <circle id="Ellipse_20" data-name="Ellipse 20" cx="12.5" cy="12.5" r="12.5" fill="#262626" />
                  <path id="Icon_weather-moon-alt-first-quarter" data-name="Icon weather-moon-alt-first-quarter"
                    d="M4.488,9.924A6.009,6.009,0,0,1,4.97,7.556a6.157,6.157,0,0,1,1.3-1.945,6.092,6.092,0,0,1,6.682-1.3,6.181,6.181,0,0,1,1.951,1.3,6.092,6.092,0,0,1,1.3,6.682,6.181,6.181,0,0,1-1.3,1.951,6.1,6.1,0,0,1-6.687,1.3,6.157,6.157,0,0,1-1.945-1.3A6.189,6.189,0,0,1,4.488,9.924Zm5.993,5.457h.1a5.332,5.332,0,0,0,2.119-.434,5.478,5.478,0,0,0,1.739-1.165,5.57,5.57,0,0,0,1.165-1.739,5.239,5.239,0,0,0,.434-2.119,5.325,5.325,0,0,0-.434-2.113,5.478,5.478,0,0,0-1.165-1.739A5.57,5.57,0,0,0,12.7,4.906a5.239,5.239,0,0,0-2.119-.434h-.1Z"
                    transform="translate(23.088 22.927) rotate(180)" fill="#f3f3f3" />
                  <path id="Icon_ionic-md-sunny" data-name="Icon ionic-md-sunny"
                    d="M12.7,2.25H10.879V4.976H12.7V2.25Zm5.5,1.861L16.561,5.747,17.834,7.02,19.47,5.385,18.2,4.111Zm-12.812,0L4.111,5.385,5.747,7.02,7.02,5.747,5.385,4.111Zm.954,7.679Zm14.992-.907H18.605V12.7h2.726V10.883Zm-16.355,0H2.25V12.7H4.976V10.883Zm12.858,5.677-1.273,1.273L18.2,19.47,19.47,18.2l-1.636-1.636Zm-12.088,0L4.111,18.2,5.385,19.47,7.02,17.834,5.747,16.561ZM12.7,18.605H10.879v2.726H12.7V18.605Z"
                    transform="translate(0.71 1.209)" fill="#f3f3f3" />
                </svg></span>
              <span><svg id="Component_6_1" data-name="Component 6 – 1" xmlns="http://www.w3.org/2000/svg" width="25"
                  height="25" viewBox="0 0 25 25">
                  <circle id="Ellipse_5" data-name="Ellipse 5" cx="12.5" cy="12.5" r="12.5" fill="#fff" />
                  <path id="Icon_weather-moon-alt-first-quarter" data-name="Icon weather-moon-alt-first-quarter"
                    d="M4.488,9.924A6.009,6.009,0,0,1,4.97,7.556a6.157,6.157,0,0,1,1.3-1.945,6.092,6.092,0,0,1,6.682-1.3,6.181,6.181,0,0,1,1.951,1.3,6.092,6.092,0,0,1,1.3,6.682,6.181,6.181,0,0,1-1.3,1.951,6.1,6.1,0,0,1-6.687,1.3,6.157,6.157,0,0,1-1.945-1.3A6.189,6.189,0,0,1,4.488,9.924Zm5.993,5.457h.1a5.332,5.332,0,0,0,2.119-.434,5.478,5.478,0,0,0,1.739-1.165,5.57,5.57,0,0,0,1.165-1.739,5.239,5.239,0,0,0,.434-2.119,5.325,5.325,0,0,0-.434-2.113,5.478,5.478,0,0,0-1.165-1.739A5.57,5.57,0,0,0,12.7,4.906a5.239,5.239,0,0,0-2.119-.434h-.1Z"
                    transform="translate(23.088 22.927) rotate(180)" />
                  <path id="Icon_ionic-md-sunny" data-name="Icon ionic-md-sunny"
                    d="M12.7,2.25H10.879V4.976H12.7V2.25Zm5.5,1.861L16.561,5.747,17.834,7.02,19.47,5.385,18.2,4.111Zm-12.812,0L4.111,5.385,5.747,7.02,7.02,5.747,5.385,4.111Zm.954,7.679Zm14.992-.907H18.605V12.7h2.726V10.883Zm-16.355,0H2.25V12.7H4.976V10.883Zm12.858,5.677-1.273,1.273L18.2,19.47,19.47,18.2l-1.636-1.636Zm-12.088,0L4.111,18.2,5.385,19.47,7.02,17.834,5.747,16.561ZM12.7,18.605H10.879v2.726H12.7V18.605Z"
                    transform="translate(0.709 1.209)" />
                </svg></span>
              <div class="switch-toggle" :class="{ 'switch-toggle-checked': userTheme == 'dark-theme' }"></div>
            </label>
          </li>
        </ul>
        <select-language @selected="selectedLanguage" />
      </div>
    </div>
  </div>
</template>

<script>
import SelectLanguage from "@/components/SelectLanguage";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: () => null,
    },
  },

  data() {
    return {
      visible: false,
      width: 0,
      userTheme: "light-theme",
    };
  },

  mounted() {
    var initUserTheme = localStorage.getItem("user-theme")
      ? localStorage.getItem("user-theme")
      : "light-theme";
    this.setTheme(initUserTheme);
  },

  computed: {
    ...mapGetters({
      lang: "getLang",
      tpls: "getTpls",
      tplsage: "getTplsAge",
    }),
  },

  components: {
    SelectLanguage,
  },

  created() {
    window.addEventListener("resize", this.handelWindowSize);
    this.handelWindowSize();

    let tplsage = moment.unix(this.tplsage);
    if (
      !this.tplsage ||
      this.tpls.length == 0 ||
      moment().diff(tplsage, "hours") >= 1
    ) {
      this.$store.commit("TPLS_AGE", moment().unix());
      this.$store.dispatch("fetchTpls");
    }
  },

  unmounted() {
    window.removeEventListener("resize", this.handelWindowSize);
  },

  methods: {
    goTo(name) {
      this.visible = false;
      if (this.$route.name != name) {
        this.$router.push({ name: name });
      }
    },
    selectedLanguage(lang) {
      this.$emit("changed", lang);
    },

    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },

    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
      } else {
        this.setTheme("light-theme");
      }
    },

    toggleOpen() {
      this.visible = !this.visible;
    },

    toggleClose() {
      this.visible = !this.visible;
    },

    handelWindowSize() {
      this.width = window.innerWidth;
    },
  },
};
</script>

<style lang="scss">
.ti {
  .box {
    .logo {
      font-size: 18px;
    }

    .brand .info {
      font-size: 1rem;
      text-decoration: none;
    }

    .breadcrumbs .title {
      font-size: 15px;
    }
  }

  .nav-right {
    .nav-item .nav-link {
      font-size: 0.85rem;
    }
  }
}

.switch-checkbox {
  display: none;
}

.switch-label {
  width: var(--element-size);
  border-radius: var(--element-size);
  border: calc(var(--element-size) * 0.025) solid var(--accent-color);
  padding: calc(var(--element-size) * 0.1);
  font-size: calc(var(--element-size) * 0.3);
  height: calc(var(--element-size) * 0.35);

  align-items: center;
  background: var(--text-primary-color);
  cursor: pointer;
  display: flex;
  position: relative;
  transition: background 0.5s ease;
  justify-content: space-between;
  z-index: 1;
}

svg#Component_5_1 {
  margin-bottom: 3px;
}

svg#Component_6_1 {
  margin-bottom: 3px;
}

.switch-toggle {
  position: absolute;
  background-color: var(--background-color-primary);
  border-radius: 50%;
  top: -4;
  left: 0;
  height: calc(var(--element-size) * 0.34);
  width: calc(var(--element-size) * 0.34);
  transform: translateX(0);
  transition: transform 0.3s ease, background-color 0.5s ease;
}

.switch-toggle-checked {
  transform: translateX(calc(var(--element-size) * 0.6)) !important;
}

.top-header {
  margin-bottom: 15px;

  .box {
    background-image: linear-gradient(var(--background),
        var(--background-color-secondary));
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    height: 70px;

    // margin: 0 15px;
    .brand {
      display: flex;
      flex-direction: row;

      // padding-left: 10px;
      &:hover {

        a,
        .info {
          color: var(--main-hover-color);
        }
      }

      a,
      .info {
        font-weight: 600;
        color: var(--main-title-color);
        text-decoration: none;
      }

      a {
        margin-top: 7px;
        font-size: 1.3rem;

        &:hover {
          text-decoration: none;
        }
      }

      .info {
        font-size: 1.2rem;
      }
    }

    .breadcrumbs {
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
      margin: 0;

      .separator {
        border-right: 2px solid #d0d0d0;
        margin: 0 10px;
      }

      a {
        color: var(--link-hover-color);
        font-size: 20px;
      }

      .title {
        color: var(--main-title-color);
        font-size: 18px;
      }
    }

    .nav-right {
      display: flex;
      flex-direction: row;

      .navbar-nav {
        flex-direction: row;
        margin-right: 20px;
        align-items: center;

        .nav-item {
          padding: 5px 15px;

          a {
            color: var(--main-title-color);
            font-weight: 600;
          }

          /*   &:hover {
            background: var(--background-color-primary);
          }*/
        }
      }
    }

    .bars {
      display: none;
      font-size: 25px;
      margin-right: 10px;
    }
  }

  .divider {
    margin: 0;
  }

  .overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;

    .close-overlay {
      // padding: 25px 35px;
      // text-align: right;
      // svg {
      //     color: #fff;
      //     font-size: 35px;
      // }
      position: absolute;
      top: 50px;
      right: 20px;
      font-size: 45px;
      font-weight: 500;
      color: #fff;
    }

    .overlay-content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      top: 25%;
      width: 100%;
      text-align: center;

      .navbar-nav {
        width: 60%;

        .nav-item {
          border-bottom: 1px solid #444;

          .nav-link {
            font-size: 22px;
            font-weight: 600;
            color: #fff;
            padding: 17px;
          }
        }
      }

      .lang {
        padding-top: 12px;
      }
    }
  }

  .overlay.active {
    width: 100%;
  }

  @media (max-width: 575.98px) {
    .box .lang {
      display: none;
    }

    .bars {
      display: block;
    }
  }

  @media (max-width: 767.98px) {
    .box .lang {
      display: none;
    }

    .bars {
      display: block;
    }
  }

  @media (max-width: 1199.98px) {
    .box {
      padding: 0 10px;

      .left svg {
        font-size: 2rem;
      }

      .right svg {
        font-size: 1.5rem;
      }

      .brand {
        .info {
          display: none;
        }
      }

      .breadcrumbs {
        img {
          width: 30px;
          height: 30px;
        }

        .logo,
        .separator {
          display: none;
        }

        .title {
          font-size: 1rem;
        }
      }

      .nav-right {
        display: none;
      }

      .bars {
        display: block;
      }
    }
  }
}
</style>
