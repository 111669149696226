<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width="22"
    height="22"
    viewBox="0 0 512 512"
    fill="currentColor"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M1595 4970 c-236 -37 -458 -162 -599 -339 -103 -128 -165 -261 -197 -418 -18 -88 -19 -151 -17 -1335 l3 -1243 32 -67 c38 -81 102 -146 181 -185 l57 -28 1505 0 1505 0 57 28 c79 39 143 104 181 185 l32 67 3 1243 c2 1184 1 1247 -17 1335 -73 360 -335 638 -696 739 l-80 23 -950 1 c-522 1 -972 -2 -1000 -6z m765 -1495 l0 -525 -545 0 -545 0 0 518 c0 285 3 522 7 525 3 4 249 7 545 7 l538 0 0 -525z m1488 -2 l2 -523 -545 0 -545 0 0 525 0 525 543 -2 542 -3 3 -522z m-2196 -1130 c191 -104 162 -390 -46 -459 -93 -31 -200 -2 -266 72 -45 52 -60 93 -60 167 0 196 200 314 372 220z m2014 22 c104 -31 176 -130 175 -243 0 -71 -16 -115 -61 -166 -118 -134 -334 -104 -414 56 -58 116 -17 261 92 325 74 44 130 51 208 28z"
      />
      <path
        d="M1038 713 c-146 -192 -304 -399 -352 -460 l-86 -113 240 0 239 0 122 160 122 160 1237 0 1237 0 122 -160 122 -160 239 0 240 0 -86 113 c-48 61 -206 268 -352 460 l-265 347 -239 0 c-228 0 -239 -1 -226 -18 7 -10 48 -63 91 -119 42 -56 77 -105 77 -108 0 -3 -432 -5 -960 -5 -528 0 -960 2 -960 5 0 3 35 52 78 108 42 56 83 109 90 119 13 17 2 18 -226 18 l-239 0 -265 -347z"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
svg {
  color: var(--text-primary-color);
}
</style>
