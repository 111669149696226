<template>
  <div class="main" :class="lang">
    <top-header
      icon="map-marker-alt"
      :title="$t('trainPosition')"
      @changed="onLanguageSelected"
    />
    <div class="container-fluid">
      <p>Senaste sex dagarna: {{ totalDelay }} kr.</p>
      <section class="train-position table-responsive-md">
        <table class="table table-striped" v-if="!error">
          <thead>
            <tr>
              <th scope="col">Tåg</th>
              <th scope="col">{{ $t("table.plats") }}</th>
              <th scope="col" class="head spar">Kod</th>
              <th scope="col">Beskrivning</th>
              <th scope="col" style="text-align: center">Försening</th>
              <th scope="col" class="head">Händelserapport</th>
              <th scope="col" class="head" style="text-align: center">
                Rapporterades
              </th>
              <th scope="col">Uppdaterat</th>
            </tr>
          </thead>
          <tbody v-if="positions.length > 0">
            <tr v-for="(pos, index) in positions" :key="index">
              <td class="km">
                <router-link
                  :to="{
                    name: 'position',
                    query: { tagNr: pos.tagNr, datum: pos.depdate, lang: lang },
                  }"
                  >{{ pos.tagNr }}</router-link
                >
              </td>
              <td class="station">
                {{ pos.eventNamn }}
              </td>
              <td class="spar">{{ pos.delayCode }}</td>
              <td class="tider">{{ pos.description }}</td>
              <td style="text-align: center" class="sen">
                {{ (pos.delaytime &lt; 0) ? Math.abs(pos.delaytime) : pos.delaytime }}
              </td>
              <td class="tider">
                {{ pos.internal }}
              </td>
              <td class="tider">
                {{ pos.start }}
              </td>
              <td class="tider">
                {{ pos.ts }}
              </td>
              <td :class="lang" v-if="pos.events == null">
                {{
                  pos.beskrivning && pos.beskrivning.length
                    ? pos.beskrivning
                    : ""
                }}
              </td>
              <td :class="lang" v-if="pos.events != null">
                <p v-for="(event, idx) in pos.events" :key="idx">
                  {{ event.from }}
                  {{
                    event.to != null && event.to != event.from
                      ? "- " + event.to
                      : ""
                  }}
                  ({{ event.code }} {{ event.description }})
                  <br />
                  <small>{{ event.internal }}</small>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <template v-if="error">
          <div class="alert alert-info" role="alert">
            {{ $t("error_1") }}
          </div>
        </template>
      </section>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TopHeader from "@/components/TopHeader";
import Box from "@/components/Box";
import SearchInput from "@/components/SearchInput";
import SelectLanguage from "@/components/SelectLanguage";
import { mapGetters } from "vuex";
import { mapState } from "vuex";

export default {
  name: "position",
  metaInfo() {
    const title = this.title;
    return {
      title: title,
    };
  },

  data() {
    return {
      selected: null,
      trainNr: "",
      speed: "",
      titleTrain: "",
      title: this.$i18n.t("trainPosition") + " - 1409",
      operator: "",
      updated: "",
      positions: [],
      date: moment().format("YYYY-MM-DD"),
      tforInterval: null,
      error: false,
      traindata: null,
      annonseras: 0,
      totalDelay: 0,
    };
  },

  computed: {
    ...mapGetters({
      lang: "getLang",
    }),
    ...mapState(["apiKey", "baseUrl"]),
  },

  components: {
    TopHeader,
    Box,
    SearchInput,
    SelectLanguage,
  },

  created() {
    if (this.$route.query.lang) {
      this.$store.commit("SET_LOCAL_LANGUAGE", this.$route.query.lang);
    }

    if (this.$route.query.tagNr) {
      this.trainNr = this.$route.query.tagNr;
    }
    if (this.$route.query.datum) {
      this.date = this.$route.query.datum;
    }
  },

  mounted() {
    if (this.datum) {
      this.date = this.datum;
    }
    this.fetchTrainPosition();
  },

  beforeUnmount() {
    clearTimeout(this.tforInterval);
  },

  methods: {
    fetchTrainPosition() {
      if (this.tforInterval != null) {
        clearTimeout(this.tforInterval);
      }
      this.$axios
        .get(this.baseUrl + "quality.php?key=" + this.apiKey, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            this.error = false;
            this.positions = res.data;
            this.totalDelay = 0;
            var sum = 0;
            for (var i = 0; i < this.positions.length; i++) {
              sum += this.positions[i].delaytime * 75;
            }
            this.totalDelay = sum.toLocaleString("sv");
            this.tforInterval = setTimeout(() => {
              this.fetchTrainPosition();
            }, 60000);
          }
        })
        .catch((err) => {
          this.positions = [];
          this.error = true;
        });
    },

    selectedStation(val, selectedDate) {
      this.searchTrainPosition(val, selectedDate);
    },

    searchTrainPosition(value, selectedDate) {
      if (selectedDate) {
        this.date = moment(selectedDate).format("YYYY-MM-DD");
      } else {
        this.date = moment().format("YYYY-MM-DD");
      }

      if (value && !isNaN(value)) {
        this.trainNr = value;
        this.$router.push({
          name: "position",
          query: { tagNr: value, datum: this.date, lang: this.lang },
        });
        this.fetchTrainPosition();
      }

      if (value && isNaN(value)) {
        this.$router.push({
          name: "station",
          params: { urltpl: value, urltyp: "avg" },
          query: { lang: this.lang },
        });
      }
    },

    onLanguageSelected() {
      this.$router.push({
        name: "position",
        query: { tagNr: this.trainNr, datum: this.date, lang: this.lang },
      });
      this.fetchTrainPosition();
    },
  },
};
</script>

<style lang="scss">
.cancelled {
  color: red;
}
.ti .container-fluid {
  .lastupdate {
    font-size: 10px;
  }
  .table {
    tr th,
    tr .ti {
      font-size: 11px;
    }
    tr {
      .spar strong {
        font-size: 10px;
      }
    }
  }
}
.container-fluid {
  padding-bottom: 150px;
  .lastupdate {
    margin: 0;
    font-size: 13px;
    margin-top: 15px;
    padding-left: 5px;
    span {
      font-weight: 600;
      font-size: 12px;
    }
  }
  .train-position {
    background: var(--background-color-secondary);
    padding: 15px 15px 1px;
    margin-top: 15px;
    .table {
      thead {
        tr th {
          font-weight: 600;
        }
        .head {
          font-weight: 600;
          border-right-style: solid;
          border-right-width: 1px;
          border-right-color: #dee2e6;
        }
        .head.centered {
          text-align: center;
        }
        th {
          border-bottom: 0;
        }
      }
      tbody tr {
        .station {
          a {
            font-weight: 600;
            color: #148f77;
            &:hover {
              color: #1abc9c;
            }
          }
        }
      }
      .sen,
      .tidig {
        font-weight: 600;
      }
      .sen {
        color: #e74c3c;
      }
      .tidig {
        color: #27ae60;
      }
    }
  }

  @media (max-width: 575.98px) {
    padding: 0;
    .train-position {
      padding: 0;
    }
    .box {
      height: 190px;
    }
    .box reg-box{
      height: 230px;
    }
    .select {
      margin-top: 15px;
      float: none;
      min-width: 100%;
      select {
        min-width: 100%;
      }
    }
    .table {
      thead {
        th {
          font-size: 0.5rem;
          // font-weight: 600;
          padding: 10px 2px;
        }
        .spar {
          text-align: center;
        }
      }
      tbody {
        tr > td {
          font-size: 0.8rem;
          padding: 5px 0;
        }
        tr > th {
          padding: 5px 1px;
        }
        td.km {
          padding: 0 5px;
        }
        .station,
        .tider {
          font-size: 0.55rem;
        }
        .spar {
          text-align: center;
          min-width: 55px;
          strong {
            font-size: 0.5rem;
          }
          span {
            font-size: 0.55rem;
          }
        }
      }
    }
    .ti .container-fluid {
      .table {
        tr th {
          font-size: 8px;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media (max-width: 1199.98px) {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
</style>
