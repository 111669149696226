<template>
    <div class="typ">
        <label for="avg">
            <input type="radio" name="typ" value="avg" id="avg" v-model="typ" @change="onChange"> {{ $t('avg') }}
        </label>
        <label for="ank">
            <input type="radio" name="typ" value="ank" id="ank" v-model="typ" @change="onChange"> {{ $t('ank') }}
        </label>
    </div>
</template>

<script>
export default {
    props: {
        selected: {
            type: String,
            required: false,
            default: () => null
        }
    },
    data() {
        return {
            typ: ''
        }
    },

    created() {
        this.typ = (this.selected) ? this.selected : 'avg'
    },

    methods: {
        onChange(e) {
            this.$emit('changed', e.target.value)
        },
    }
}
</script>

<style lang="scss" scoped>
.typ {
    margin-top: 10px;
    label {
        margin-right: 15px;
        cursor: pointer;
    }
}
</style>

