import { createRouter, createWebHistory } from "vue-router";
import store from '@/store'
import Home from '@/views/Home.vue'
import Map from '@/views/Map.vue'
import Operator from '@/views/Operator.vue'
import Cancel from '@/views/Cancel.vue'
import CancelPro from '@/views/CancelPro.vue'
import EventArchive from '@/views/EventArchive.vue'
import TrainPosition from '@/views/TrainPosition.vue'
import TrainStation from '@/views/TrainStation.vue'
import ShowFeedback from '@/views/ShowFeedback.vue'
import Subscribe from '@/views/Subscription.vue'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import PlusMembers from '@/views/PlusMembers.vue'
import Pay from '@/views/Pay.vue'
import Passwordrequest from '@/views/Passwordrequest.vue'
import Resetpassword from '@/views/ResetPassword.vue'
import Quality from '@/views/Quality.vue'
import TrainStationHistory from '@/views/TrainStationHistory.vue'
import Event from '@/views/Event.vue'
import Trv from '@/views/Trv.vue'
import Document from '@/views/Documents'
import Faq from '@/views/Faq'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/documents',
        name: 'document',
        component: Document,
    },
    {
        path: '/trains/:trainType',
        name: 'homeWithTrainType',
        component: Home,
        props: true
    },
    {
        path: '/operator/:operatorID',
        name: 'operator',
        component: Operator,
        props: true
    },
    {
        path: '/trv',
        name: 'trv',
        component: Trv,
        props: true
    },
    {
        path: '/trv/:dlc',
        name: 'trvdlc',
        component: Trv,
        props: true
    },
    {
        path: '/installt/:template/:dag',
        name: 'cancel',
        component: Cancel,
        props: true
    },
    {
        path: '/pro/installt/',
        name: 'cancelProStart',
        component: CancelPro,
        props: true
    },
    {
        path: '/pro/installt/:operator/:date',
        name: 'cancelProOperator',
        component: CancelPro,
        props: true
    },
    {
        path: '/installt',
        name: 'cancelStart',
        component: Cancel,
        props: true
    },
    {
        path: '/event/:eventID',
        name: 'event',
        component: Event,
        props: true
    },
    {
        path: '/eventArchive/:date',
        name: 'eventArchive',
        component: EventArchive,
        props: true
    },
    {
        path: '/position',
        name: 'position',
        component: TrainPosition,
        props: true
    },
    {
        path: '/map',
        name: 'map',
        component: Map,
        props: true
    },
    {
        path: '/station/:urltpl/:urltyp',
        name: 'station',
        component: TrainStation,
        props: true
    },
    {
        path: '/stationHistory/:urltpl/:urltyp',
        name: 'stationHistory',
        component: TrainStationHistory,
        props: true
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: ShowFeedback,
        props: true
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('@/views/Faq.vue')
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Terms.vue')
    },
    {
        path: '/subscribe/:timePeriod',
        name: 'subscribe',
        component: Subscribe,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        props: true,
        meta: { requiresGuest: true }
    },
    {
        path: '/passwordrequest',
        name: 'passwordrequest',
        component: Passwordrequest,
        props: true,
        meta: { requiresGuest: true }
    },
    {
        path: '/resetPassword',
        name: 'resetpassword',
        component: Resetpassword,
        props: true,
        meta: { requiresGuest: true }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        props: true,
        meta: { requiresGuest: true }
    },
    {
        path: '/plus',
        name: 'plus',
        component: PlusMembers,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/pay/:timePeriod',
        name: 'pay',
        component: Pay,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/quality',
        name: 'quality',
        component: Quality,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/404',
        name: 'PageNotExist',
        component: () => import('@/views/404.vue'),
        meta: { requiresGuest: true, requiresAuth: false },
    },
    {
        path: "/:catchAll(.*)",
        redirect: "/404",
    },
]

const router = new createRouter({
    history: createWebHistory(process.env.BASE_URL),
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.state.authenticated) {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
        if (store.state.authenticated) {
            next({
                path: '/',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router;