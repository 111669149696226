<template>
  <div
    class="modal fade"
    id="fsModal"
    ref="fsModal"
    data-bs-backdrop="static"
    data-bs-keyboard="true"
  >
    <div class="modal-dialog" :class="size">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="fsModalLabel">{{ title }}</h5>
          <button type="button" class="btn btn-light" @click="close()">
            <i class="bi bi-x-lg"></i>
          </button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer" v-if="footer">
          <button type="button" class="btn btn-secondary" @click="close()">
            Avbryt
          </button>
          <button type="button" class="btn btn-primary" @click="$emit('save')">
            Spara
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
export default {
  name: "fsModal",
  props: {
    size: {
      type: String,
      required: false,
      default: "modal-lg",
    },
    title: {
      type: String,
      required: false,
      default: "Modal title...",
    },
    footer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modal: null,
    };
  },

  mounted() {
    this.modal = new Modal(this.$refs.fsModal);
  },

  methods: {
    open() {
      this.modal.show();
    },

    close() {
      this.$emit("hidden");
      this.modal.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  h5 {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
  }
  button {
    background: transparent;
    border: 0;
  }
}
</style>
