<template>
  <div class="main">
    <top-header title="Nytt lösenord" />
    <div class="container-fluid login-page">
      <div class="row">
        <div
          class="
            col-sm-12 col-md-12 col-lg-6
            offset-lg-3 offset-ms-0 offset-md-0
          "
        >
          <div class="login mt-5">
            <div class="header">
            </div>
            <div class="form">
              <div
                class="alert alert-success"
                role="alert"
                v-if="successMessage != null"
              >
                {{ successMessage }}
              </div>
              <div
                class="alert alert-danger"
                role="alert"
                v-if="errorMessage != null"
              >
                {{ errorMessage }}
              </div>
              <p>
                Om du behöver ett nytt lösenord anger du din e-postadress här så
                får du ett mail med en länk du kan använda för att välja ett
                nytt lösenord.
              </p>
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control form-control-lg"
                  id="email"
                  @keyup.enter="sendMail()"
                  placeholder="Ange e-postadress"
                  v-model="email"
                  :class="{
                    'is-invalid': submitStatus == 'ERROR' && v$.email.$error,
                  }"
                />
                <div class="invalid-feedback" v-if="v$.email.$error">
                  Du måste ange e-postadress
                </div>
                <div class="invalid-feedback" v-if="v$.email.email">
                  Ange giltig e-postadress
                </div>
              </div>
              <div class="d-grid gap-3">
                <button
                  class="btn btn-success btn-block btn-lg mt-3"
                  @click="sendMail()"
                >
                  Skicka länk
                </button>
              </div>
              <p class="register mt-3">
                Har du inget konto än?
                <router-link :to="{ name: 'register' }"
                  >Registrera dig.</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopHeader from "@/components/TopHeader";
import Box from "@/components/Box";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
export default {
  name: "passwordrequest",
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      email: null,
      submitStatus: null,
      errorMessage: null,
      successMessage: null,
    };
  },

  validations() {
    return {
      email: { required, email },
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  components: {
    TopHeader,
    Box,
  },

  mounted() {
    var vs = this.$route.query.validationStatus;
    if (vs != null) {
      if (vs == "ok") {
        this.successMessage =
          "Din e-postadress har validerats och du kan nu logga in!";
      } else if (vs == 1) {
        this.errorMessage =
          "Valideringslänken har redan använts eller är för gammal - du behöver beställa en ny.";
      } else if (vs == 2) {
        this.errorMessage =
          "Kontrollera valideringslänken och försök igen, alla tecken kom inte med.";
      } else {
        this.errorMessage =
          "Ett fel har inträffat som kräver att du kontaktar oss på synpunkter@tydalsystems.se";
      }
    }
  },

  methods: {
    sendMail() {
      this.errorMessage = null;
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        this.$axios
          .post(this.baseUrl + "passwordRequest.php", {
            email: this.email,
          })
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "OK";
              this.successMessage =
                "Klicka på länken i e-postmeddelandet för att välja ett nytt lösenord.";
            } else if (response.data.status == "error") {
              this.submitStatus = "ERROR";
              this.errorMessage =
                "E-postadressen finns inte registrerad. Prova en annan eller registrera dig.";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  background: var(--background-color-secondary);
  padding: 3rem 30px;
  text-align: center;
  .form {
    max-width: 60%;
    margin: 0 auto;
    input::placeholder {
      font-size: 15px;
    }
    .invalid-feedback {
      text-align: left;
      padding-left: 10px;
    }
    .alert {
      text-align: left;
    }
  }
  button {
    font-weight: 600;
    background-color: #f5dc2a;
    border-color: #f5dc2a;
    color: #373737;
    &:focus {
      box-shadow: none;
    }
    &:active {
      background-color: #f1c40f;
      border-color: #f1c40f;
    }
  }
  .btn-success:not(:disabled):not(.disabled):active {
    background-color: #f1c40f;
    border-color: #f1c40f;
  }
  .register {
    font-size: 14px;
    font-weight: 600;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .form {
      max-width: 100%;
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    .form {
      max-width: 100%;
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    .form {
      max-width: 100%;
    }
  }

  @media (max-width: 1199.98px) {
    .form {
      max-width: 100%;
    }
  }
}
</style>
