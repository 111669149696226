<template>
  <div class="main">
    <top-header :title="$t('navbar.plus')" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <h1>{{ months }} månad{{ months > 1 ? "er" : "" }}</h1>
          <span v-html="snippet"></span>
          <div class="text-center text-secondary" v-if="snippet == null">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopHeader from "@/components/TopHeader";
import Box from "@/components/Box";

export default {
  name: "pay",
  metaInfo() {
    const title = this.title;
    return {
      title: title,
    };
  },

  data() {
    return {
      title: this.$i18n.t("navbar.plus"),
      trainIndustry: null,
      email: null,
      submitStatus: null,
      snippet: null,
      checkoutId: null,
      months: 1,
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  components: {
    TopHeader,
    Box,
  },

  mounted() {
    this.months = Math.round(this.$route.params.timePeriod / 30);
    this.$axios
      .get(
        this.baseUrl +
          "pay/checkout.php?key=" +
          this.apiKey +
          "&subscriptionDays=" +
          this.$route.params.timePeriod
      )
      .then((response) => {
        this.snippet = response.data.snippet;
        this.checkoutId = response.data.checkoutId;
        this.$loadScript(
          "https://embedded.payson.se/Content/payson.js?v2"
        ).then(() => {});
      });
  },

  methods: {
    logout() {
      this.$axios
        .get(this.baseUrl + "logout.php?key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$store.commit("AUTH_LOGOUT");
            location.reload(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.members {
  background: #fff;
  padding: 3rem 30px;

  .title {
    font-size: 30px;
    font-weight: 900;
  }

  #email::placeholder {
    font-size: 15px;
  }

  .invalid-feedback {
    text-align: left;
    padding-left: 10px;
  }

  .divider {
    border-top: 1px solid #ddd;
  }

  .card {
    .card-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background: #f1f1f1;
      .icon {
        flex: 1;
        text-align: center;
        svg {
          font-size: 35px;
        }
      }
      .content {
        flex: 4;
        text-align: left;
        h2 {
          font-size: 22px;
          font-weight: 900;
          margin: 0;
        }
        p {
          margin: 0;
        }
      }
    }
  }
  .subscribe {
    font-weight: 600;
    background-color: #f5dc2a;
    border-color: #f5dc2a;
    color: #fff;
    &:focus {
      box-shadow: none;
    }
    &:active {
      background-color: #f1c40f;
      border-color: #f1c40f;
    }
  }
}
</style>
