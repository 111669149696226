<template>
  <div class="input-fields">
    <div class="input-group">
      <input
        type="text"
        class="form-control searchfield"
        ref="search"
        name="search"
        v-model="keyword"
        :placeholder="$t('searchfield.placeholder')"
        @input="inputValue"
        @change="inputChanged"
        autocomplete="off"
        @keydown.enter="nativeKeyEvents"
      />
      <button
        type="button"
        class="btn btn-light input-group-text btn-info"
        @click="clear"
      >
        <i class="bi bi-backspace-fill"></i>
      </button>
      <input
        v-if="showDatePicker"
        type="date"
        class="form-control form-control-lg"
        v-model="date"
        :placeholder="$t('searchfield.date_placeholder')"
        @change="pressed()"
        :min="minDate"
        :max="maxDate"
      />
      <button
        type="button"
        class="btn btn-info input-group-text"
        @click="pressed"
      >
        <i class="bi bi-search"></i> {{ $t("searchfield.button") }}
      </button>
    </div>
    <div class="autocomplete">
      <ul
        class="autocomplete-results"
        v-show="visible"
        :class="{ borderless: items.length < 1 }"
      >
        <li
          class="autocomplete-result"
          :class="{ selected: index == selectedIndex }"
          v-for="(item, index) in items"
          :key="index"
          @click="toggleItem(item)"
        >
          <i class="bi bi-geo-alt-fill"></i> {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { isMobile } from "mobile-device-detect";

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export default {
  props: {
    searched: {
      type: String || Number,
      required: false,
      default: () => null,
    },
    datum: {
      type: String,
      required: false,
      default: () => new Date().toLocaleDateString(),
    },
    showDatePicker: {
      type: Boolean,
      required: true,
      default: () => true,
    },
  },
  data() {
    return {
      minDate: "2018-07-01",
      maxDate: new Date().addDays(1).toLocaleDateString(),
      keyword: "",
      date: null,
      query: "",
      items: [],
      visible: false,
      selected: {},
      searchvalue: "",
      typ: "avg",
      selectedIndex: -1, // no item selected
    };
  },

  watch: {
    searched: function (val) {
      this.searchvalue = val;
    },
  },

  computed: {
    ...mapState(["lang", "tpls"]),

    selectedDate() {
      return this.datum;
    },
  },

  created() {
    this.items = [];
    if (this.datum) {
      this.date = this.datum;
    } else {
      this.date = new Date().toLocaleDateString();
    }

    if (this.searched && parseInt(this.searched)) {
      this.keyword = this.searched;
      this.searchvalue = this.searched;
    } else {
      this.searchvalue = this.searched;
    }
    this.addKeyHandler();
  },

  mounted() {
    this.selectedIndex = -1;
    if (!isMobile) {
      this.$refs.search.focus();
    }
  },

  unmounted() {
    this.removeKeyHandler();
  },

  methods: {
    tomorrow() {
      return new Date().addDays(1).toLocaleDateString();
    },

    addKeyHandler() {
      window.addEventListener("keydown", this.keyHandler);
    },

    removeKeyHandler() {
      window.removeEventListener("keydown", this.keyHandler);
    },

    clear() {
      this.keyword = "";
      this.searchvalue = "";
      this.$refs["search"].focus();
    },

    keyHandler(event) {
      if (event.which === 40) {
        this.handlerKeyDown();
      } else if (event.which === 38) {
        this.handlerKeyUP();
      } else if (event.which === 13) {
        this.handlerKeyEnter();
      }
    },

    handlerKeyDown() {
      if (
        this.selectedIndex < 0 ||
        this.selectedIndex === this.items.length - 1
      ) {
        this.selectedIndex = 0;
      } else if (
        this.selectedIndex >= 0 &&
        this.selectedIndex < this.items.length - 1
      ) {
        this.selectedIndex++;
      }
    },

    handlerKeyUP() {
      if (this.selectedIndex <= 0) {
        this.selectedIndex = this.items.length - 1;
      } else if (
        this.selectedIndex > 0 &&
        this.selectedIndex <= this.items.length - 1
      ) {
        this.selectedIndex--;
      }
    },

    handlerKeyEnter() {
      if (this.selectedIndex === -1) {
        this.pressed();
      } else {
        let selectedItem = this.items[this.selectedIndex];
        this.toggleItem(selectedItem);
        this.selectedIndex = -1;
      }
    },

    nativeKeyEvents(event) {
      if (this.query && !isNaN(this.query)) {
        this.search();
      }
    },

    filterItems() {
      if (this.query && this.tpls.length > 0) {
        let char = this.query.charAt(0);
        let filteredItems = this.tpls.filter((item) => {
          if (item != null) {
            let str = item.split(" ");
            if (str[0].toLowerCase().charAt(0) === char.toLowerCase()) {
              return str[0].toLowerCase().includes(this.query.toLowerCase());
            }
          }
        });
        this.items = filteredItems;
        // this.items = this.tpls.filter((item) => item.toLowerCase().includes(this.query.toLowerCase()))
      } else {
        this.query = "";
        this.visible = false;
        this.items = [];
      }
    },

    toggleItem(item) {
      this.query = "";
      this.keyword = item;
      this.items = [];
      this.visible = false;
      this.$emit("toggle", this.keyword, this.date);
    },

    inputChanged(val) {
      setTimeout(() => {
        this.visible = false;
      }, 100);
    },

    inputValue(val) {
      this.visible = true;
      this.query = val ? val.target.value : null;
      this.keyword = val.target.value;
      if (val && isNaN(val)) {
        this.filterItems();
      } else {
        this.items = [];
      }
      this.$emit("inputValue", val.target.value);
    },

    dateValue() {
      this.$emit("selectedDate", this.date);
    },

    search() {
      this.$emit("search", this.keyword, this.date);
    },

    pressed() {
      this.$emit("onPress", this.keyword, this.date);
    },

    customFormatter(date) {
      return date.toLocaleDateString();
    },
  },
};
</script>

<style lang="scss">
.input-fields {
  .form-control {
    border-color: #707070;
    border-width: 1px;
    border-radius: 3px;
    padding: 10px 25px;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--text-color);

    &::placeholder {
      color: #afafaf;
      font-size: 1.3rem;
    }
  }

  .btn-secondary {
    padding: 0 15px;

    .bi {
      font-size: 20px;
    }
  }

  .btn-info {
    background-color: #f3f3f3;
    border-color: #404040;
    color: #404040;
    font-weight: 600;

    .bi {
      font-size: 19px;
    }
  }

  input,
  .vdp-datepicker input {
    border-radius: 0;
    background-color: var(--background-color-secondary);
    color: var(--text-primary-color);

    &:focus {
      box-shadow: none;
      color: var(--text-primary-color);
      background-color: var(--background-color-secondary);
      // border: 1px solid #ced4da;
    }
  }

  .vdp-datepicker input {
    border-left: 0;
  }

  .autocomplete {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1000;

    .card-items {
      max-width: 421px;
      max-height: 250px;
    }

    .autocomplete-results.borderless {
      border: 0;
    }

    .autocomplete-results {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      margin: 0;
      padding: 0;
      max-width: 442px;
      max-height: 250px;
      overflow: auto;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);

      .autocomplete-result {
        list-style: none;
        font-weight: 600;
        padding: 10px 15px;
        text-align: left;
        padding: 0.75rem 1.25rem;
        margin-bottom: -1px;
        background-color: var(--background-color-secondary);
        border: 1px solid rgba(0, 0, 0, 0.125);
        cursor: pointer;

        &:first-child {
          border-top: 0;
        }

        &:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        }

        &:hover {
          background: #fff;
        }

        .bi {
          margin-right: 5px;
        }
      }

      .selected {
        background: #fff;
      }
    }
  }

  .input-group-append {
    button {
      font-size: 19px;
      font-weight: 600;

      &:focus {
        box-shadow: none;
      }
    }
  }

  @media (max-width: 575.98px) {
    height: 110px;

    .form-control.searchfield {
      margin-left: -12px;
    }

    input,
    .vdp-datepicker {
      width: 100%;
    }

    .vdp-datepicker input {
      border-left: 1px;
    }

    .vdp-datepicker {
      .input-group {
        border-left: 1px;

        input {
          min-width: 50%;
          border-left: 1px solid #ced4da;
          border-top: 0;
        }

        .input-group-text {
          border-radius: 0;
          border-top: 0;
        }
      }
    }

    .btn-search {
      margin-top: 15px;
      min-width: 100%;

      button {
        min-width: 100%;
        border-radius: 0;
      }
    }

    .autocomplete {
      // margin-top: -105px;
      margin-top: -57px;
    }
  }

  @media (max-width: 991.98px) {
    .box {
      .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  @media (max-width: 1199.98px) {
    // height: 100%;
  }
}
</style>
