<template>
  <div class="main">
    <top-header :title="$t('navbar.register')" />
    <div class="container-fluid">
      <div class="row">
        <div class="
                col-sm-12 col-md-12 col-lg-6
                offset-lg-3 offset-ms-0 offset-md-0
              ">
          <div class="register">
            <div class="header mb-3">
              <h3> <i class="bi bi-geo-alt-fill"></i>1409</h3>
            </div>
            <div class="form">
              <div class="alert alert-success" role="alert" v-if="successMessage != null && submitStatus == 'OK'">
                {{ successMessage }}
              </div>
              <p class="small-text">Registrera dig för att få tillgång till mer information och bli
                först med att testa ny funktionalitet!</p>
              <p class="login mt-3">
                Har du redan ett konto?
                <router-link :to="{ name: 'login' }">Logga in.</router-link>
              </p>
              <div class="mb-3">
                <label for="name">Namn</label>
                <input type="text" class="form-control form-control-lg" id="name" placeholder="För- och efternamn"
                  v-model="realname" :class="{
                    'is-invalid': submitStatus == 'ERROR' && v$.realname.$error,
                  }" />
                <div class="invalid-feedback" v-if="v$.realname.$error">
                  Ange ditt fullständiga namn
                </div>

                <div class="invalid-feedback" v-if="v$.realname.minLength">
                  Minst fyra tecken
                </div>
              </div>
              <div class="mb-3">
                <label for="sex">Kön</label>
                <br />
                <div style="
                        border-width: 1px;
                        border-color: #ced4da;
                        border-radius: 0.3rem;
                        border-style: solid;
                        padding: 0.5rem 1rem;
                      " :class="{
                        'is-invalid': submitStatus == 'ERROR' && v$.sex.$error,
                      }">
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input" name="sex" v-model="sex" value="F" id="F" />
                    <label class="form-check-label" for="F">kvinna</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input" name="sex" value="M" v-model="sex" id="M" />
                    <label class="form-check-label" for="M">man</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input" name="sex" value="-" v-model="sex" id="U" />
                    <label class="form-check-label" for="U">vill inte uppge</label>
                  </div>
                </div>
                <div class="invalid-feedback" v-if="v$.sex.$error">
                  Välj ett alternativ
                </div>
              </div>
              <div class="mb-3">
                <label for="year">Födelseår</label>
                <select name="year" v-model="year" :class="{
                  'form-select': true,
                  'is-invalid': submitStatus == 'ERROR' && v$.year.$error,
                }" v-if="years.length > 0">
                  <option value>Välj årtal</option>
                  <option v-for="(y, key) in years" :value="y" :key="key">
                    {{ y }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="v$.year.required">
                  Välj ett alternativ
                </div>
              </div>
              <div class="mb-3">
                <label for="email">E-postadress</label>
                <input type="email" class="form-control form-control-lg" id="email" @keyup="checkDomain"
                  placeholder="Ange e-postadress" v-model="email" :class="{
                    'is-invalid': submitStatus == 'ERROR' && v$.email.$error,
                  }" />
                <p class="small-text">Om du anger en e-postadress som tillhör ett järnvägsföretag
                  så kommer du att få tillgång till branschintern
                  information.</p>
                <p class="small-text" style="font-weight: 600;">{{ domaininfo }}</p>
                <button v-if="noRailDomain" class="btn btn-secondary" type="button" id="reportButton"
                  :disabled="!reportButtonEnabled" @click="report">
                  <span class="spinner-border spinner-border-sm" role="status" v-if="loading"></span>
                  {{ reportButtonText }}
                </button>
                <div class="invalid-feedback" v-if="v$.email.required">
                  Du måste ange e-postadress
                </div>
                <div class="invalid-feedback" v-if="v$.email.email">
                  Ange giltig e-postadress
                </div>
              </div>
              <div class="mb-3">
                <input type="password" class="form-control form-control-lg" id="password" placeholder="Välj lösenord"
                  v-model="password" :class="{
                    'is-invalid': submitStatus == 'ERROR' && v$.password.$error,
                  }" />
                <div class="invalid-feedback" v-if="v$.password.$error">
                  Du måste ange lösenord
                </div>
                <div class="invalid-feedback" v-if="v$.password.minLength">
                  Minst sex tecken
                </div>
              </div>
              <div class="mb-3">
                <input type="password" class="form-control form-control-lg" id="password2" placeholder="Upprepa lösenord"
                  v-model="password2" :class="{
                    'is-invalid':
                      submitStatus == 'ERROR' && v$.password2.$error,
                  }" />
                <div class="invalid-feedback" v-if="v$.password2.$error">
                  Du måste ange lösenordet igen
                </div>
                <div class="invalid-feedback" v-if="!v$.password2.sameAsPassword">
                  Lösenorden är inte likadana
                </div>
              </div>
              <div class="mb-3">
                <label for="gdpr">Personuppgifter</label>
                <br />
                <div style="
                        border-width: 1px;
                        border-color: #ced4da;
                        border-radius: 0.3rem;
                        border-style: solid;
                        padding: 0.5rem 1rem;
                      " :class="{
                        'is-invalid': submitStatus == 'ERROR' && v$.gdpr.$error,
                      }">
                  <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" name="gdpr" value="y" v-model="gdpr" id="gdpr" />
                    <label class="form-check-label" for="U">
                      <small>
                        Jag samtycker till
                        <a href="https://1409.se/sekretess.html" target="_blank">behandlingen av personuppgifter</a>.
                      </small>
                    </label>
                  </div>
                </div>
                <div class="invalid-feedback" v-if="!v$.gdpr.required">
                  Vi behöver ditt samtycke för att kunna leverera tjänsten.
                </div>
              </div>
              <div class="alert alert-danger" role="alert" v-if="errorMessage != null && submitStatus == 'ERROR'">
                {{ errorMessage }}
              </div>
              <div class="alert alert-danger" role="alert" v-if="showLinks">
                <router-link :to="{ name: 'login' }">Logga in</router-link> -
                <router-link :to="{ name: 'login', query: { validationStatus: 8 } }">Nytt lösenord</router-link>
              </div>
              <div class="d-grid gap-3">
                <button class="btn btn-success btn-block btn-lg mt-3" @click="register()">
                  Registrera
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopHeader from "@/components/TopHeader";
import Box from "@/components/Box";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
export default {
  name: "register",
  metaInfo() {
    const title = this.title;
    return {
      title: title,
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      realname: null,
      email: null,
      password: null,
      password2: null,
      submitStatus: null,
      errorMessage: null,
      successMessage: null,
      sex: null,
      title: this.$i18n.t("navbar.register"),
      years: [],
      year: "",
      domains: [],
      domaininfo: "",
      noRailDomain: false,
      reportButtonText: "Rapportera",
      loading: false,
      maildomain: "",
      reportButtonEnabled: true,
      gdpr: null,
      showLinks: false,
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  validations() {
    return {
      realname: { required, minLength: minLength(4) },
      email: { required, email },
      password: { required },
      sex: { required },
      year: { required },
      password: { required, minLength: minLength(6) },
      password2: { sameAsPassword: sameAs(this.password), required },
      gdpr: { required },
    };
  },

  components: {
    TopHeader,
    Box,
  },

  mounted() {
    for (var i = 2020; i >= 1900; i--) {
      this.years.push(i);
    }
    this.years.push("");
    this.$axios.get(this.baseUrl + "domains.php").then((response) => {
      this.domains = response.data;
    });
  },

  methods: {
    report() {
      this.reportButtonText = "Rapporterar...";
      this.loading = true;
      var postdata = new FormData();
      postdata.append("domain", this.maildomain);
      this.$axios
        .post(this.baseUrl + "domainrequest.php", postdata)
        .then((response) => {
          this.loading = false;
          this.reportButtonText = "Rapporterat!";
        });
    },

    checkDomain() {
      if (
        this.email != null &&
        this.email.length > 5 &&
        this.email.indexOf("@") > 0
      ) {
        this.noRailDomain = false;
        this.loading = false;
        this.domaininfo = "";
        this.maildomain = this.email.substring(this.email.indexOf("@") + 1);
        if (this.maildomain.indexOf(".") > 0) {
          var tld = this.maildomain.substring(this.maildomain.indexOf(".") + 1);
          if (tld != null && tld.length > 1) {
            var found = false;
            for (var i = 0; i < this.domains.length; i++) {
              if (this.domains[i] == this.maildomain.toLowerCase()) {
                found = true;
              }
            }
            if (found) {
              this.domaininfo =
                this.maildomain + " tillhör ett järnvägsföretag.";
              this.noRailDomain = false;
            } else {
              this.noRailDomain = true;
              this.reportButtonText = "Rapportera";
              this.reportButtonEnabled = true;
              this.domaininfo =
                this.maildomain +
                " känns inte igen som ett järnvägsföretag. Det är okej, du kommer att få ett vanligt konto, men om du anser att det är ett järnvägsföretag, rapportera det till oss så granskar vi det och uppgraderar ditt konto om så är fallet.";
            }
          }
        }
      }
    },

    register() {
      console.log(this.password)
      console.log(this.password2)

      this.v$.$touch();
      if (this.v$.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        this.$axios
          .post(this.baseUrl + "register.php", {
            realname: this.realname,
            sex: this.sex,
            email: this.email,
            password: this.password,
            year: this.year,
          })
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "OK";
              this.$router.push({
                name: "login",
                query: { validationStatus: 6 },
              });
            } else if (response.data.status == "error") {
              this.submitStatus = "ERROR";
              if (response.data.error == 1) {
                this.showLinks = true;
                this.errorMessage =
                  "E-postadressen är redan registrerad. Gå till inloggningssidan och prova att logga in eller begär ett nytt lösenord.";
              } else if (response.data.error == 2) {
                this.errorMessage =
                  "Det gick inte att registrera, prova igen eller kontakta oss på synpunkter@tydalsystems.se";
              }
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.small-text {
  color: var(--small-text-color);
  margin-top: 0.25rem;
  font-size: .875em;

}

.register {
  background: var(--background-color-secondary);
  padding: 3rem 30px;

  .form {
    max-width: 60%;
    margin: 0 auto;



    input::placeholder {
      font-size: 15px;
    }

    small,
    .invalid-feedback {
      text-align: left;
      padding-left: 5px;
    }

    .alert {
      text-align: left;
    }
  }

  button {
    font-weight: 600;
    background-color: #f5dc2a;
    border-color: #f5dc2a;
    color: #373737;

    &:focus {
      box-shadow: none;
    }

    &:active {
      background-color: #f5dc2a;
      border-color: #f5dc2a;
    }
  }

  .header {
    text-align: center;
  }

  .login {
    font-size: 14px;
    font-weight: 600;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .form {
      max-width: 100%;
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    .form {
      max-width: 100%;
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    .form {
      max-width: 100%;
    }
  }

  @media (max-width: 1199.98px) {
    .form {
      max-width: 100%;
    }
  }
}
</style>
