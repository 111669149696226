<template>
  <div id="app">
    <router-view />
    <feed-back />
    <v-footer v-if="$route.name != 'map'" />
  </div>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `1409 | ${content}` : `1409`
    }}</template>
  </metainfo>
</template>

<script>
import FeedBack from "@/components/FeedBack";
import Footer from "@/components/Footer";
import moment from "moment";

export default {
  components: {
    FeedBack,
    "v-footer": Footer,
  },

  mounted() {
    var loadAds = true;
    if (this.$store.state.subscription != null) {
      if (moment(this.$store.state.subscription) > moment()) {
        loadAds = false;
      }
    }
    if (loadAds) {
      let adsense = document.createElement("script");
      adsense.setAttribute("data-ad-client", "ca-pub-1810161045846669");
      adsense.setAttribute(
        "src",
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      );
      document.head.appendChild(adsense);
    }
  },
};
</script>

<style lang="scss">
@import "bootstrap/dist/css/bootstrap.min.css";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "sweetalert2/dist/sweetalert2.min.css";

:root.dark-theme {
  --background-color-primary: #1e1e1e;
  --background-color-secondary: #2d2d30;
  --accent-color: #3f3f3f;
  --text-primary-color: #dddddd;
  --small-text-color: #b2b2b2;
  --link-color: #148f77;
  --link-hover-color: #878889cf;
  --main-title-color: #dadada;
  --main-hover-color: #efefef;
  --dropdown-hover-color: #4c4c4c;
  --background: black;
  --background-image-pro: src(../assets/bg_4.png);
  --foreground: white;
  --element-size: 6rem;
  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg)
    saturate(0.3) brightness(0.7);
  --background-buy-box: rgba(43, 43, 43, 0.837);
}
:root {
  --background-color-primary: #f3f3f3;
  --background-color-secondary: #ffffff;
  --accent-color: #dadada;
  --text-primary-color: #000000;
  --small-text-color: #181818;
  --link-hover-color: #464445cf;
  --link-color: #148f77;
  --main-title-color: #717274;
  --main-hover-color: #4a4a4a;
  --dropdown-hover-color: #e9ecef;
  --background: white;
  --background-image-pro: src(../assets/bg_3.png);
  --element-size: 6rem;
  --foreground: black;
  --background-buy-box: #f3f3f3;
}
.leaflet-tile {
  filter: var(--map-tiles-filter, none);
}
html,
body {
  height: 100%;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary-color);
  background: var(--background-color-primary);
  min-height: 100%;
  .bi-geo-alt-fill {
    color: #f1c40f;
  }
  a {
    text-decoration: none;
  }

  .v-map {
    max-height: 1030px;
  }

  .odd {
    background-color: var(--accent-color);
    color: var(--accent-color);
  }
  .sen {
    color: red;
  }
  .tidig {
    color: green;
  }
  .tbheader {
    background-color: #777777;
    color: white;
  }
  .main {
    min-height: calc(100vh - 100px);
    overflow: auto;
  }
  table {
    thead {
      tr {
        background: #fff !important;
        th {
          padding: 0.75rem;
          background: var(--background-color-secondary);
          border-top: 1px solid #ebedef;
          color: var(--main-title-color);
        }
      }
    }
    tbody {
      border-top: 1px solid #ddd;
      tr {
        td {
          color: var(--text-primary-color);
          padding: 0.75rem;
          background: var(--background-color-secondary);
        }
        &:last-child {
          td {
            border: 0;
          }
        }
        .late {
          color: #e74c3c;
        }
        .early {
          color: #27ae60;
        }
      }
    }
  }
  @media (max-width: 575.98px) {
    .sen {
      font-size: 8px;
    }
    .tidig {
      font-size: 8px;
    }
    .koncept {
      font-size: 0.525rem;
    }
  }
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: var(--link-hover-color);
    &.router-link-exact-active {
      color: var(--link-color);
    }
  }
}
</style>
