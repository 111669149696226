<template>
  <div>
    <div class="btn-group btn-group-toggle" data-toggle="buttons" style="margin-bottom: 8px;">
      <label class="btn btn-outline-secondary" v-bind:class="[show == 'train' ? 'active' : '']">
        <input type="radio" class="btn-check" v-model="show" name="show" id="train" value="train" autocomplete="off"
          checked @click="setTrain" />
        {{ $t('tagNr') }}
      </label>
      <label class="btn btn-outline-secondary" v-bind:class="[show == 'destination' ? 'active' : '']">
        <input type="radio" class="btn-check" v-model="show" name="show" value="destination" id="destination"
          autocomplete="off" @click="setDestination" />
        {{ $t('trainStation') }}
      </label>
    </div>&nbsp; &nbsp;
    <a class="btn btn-light btn-legend" href="#" @click="toggle"><svg xmlns="http://www.w3.org/2000/svg" width="18"
        height="18" viewBox="0 0 9.938 9.938">
        <path id="Icon_awesome-info-circle" data-name="Icon awesome-info-circle"
          d="M5.531.563A4.969,4.969,0,1,0,10.5,5.531,4.97,4.97,0,0,0,5.531.563Zm0,2.2a.841.841,0,1,1-.841.841A.841.841,0,0,1,5.531,2.766ZM6.653,7.855a.24.24,0,0,1-.24.24H4.65a.24.24,0,0,1-.24-.24V7.374a.24.24,0,0,1,.24-.24h.24V5.852H4.65a.24.24,0,0,1-.24-.24V5.131a.24.24,0,0,1,.24-.24H5.932a.24.24,0,0,1,.24.24v2h.24a.24.24,0,0,1,.24.24Z"
          transform="translate(-0.563 -0.563)" />
      </svg>
      {{ $t('legend') }}</a>&nbsp;
    <small>{{ $t('curUpdate') }} {{ mapUpdated }}</small>
    <router-link :to="{ name: 'map' }" id="fullscreen">
      <i class="bi bi-arrows-angle-expand"></i>
    </router-link>
    <div v-if="legend == true">
      <table>
        <tbody>
          <tr>
            <td style="background-color: #0000ff; border: 1 px solid black; color: white; padding: 8px;">&nbsp;</td>
            <td>{{ $t('blue') }}</td>
          </tr>
          <tr>
            <td style="background-color: green; border: 1 px solid black; color: white; padding: 8px;">&nbsp;</td>
            <td>{{ $t('green') }}</td>
          </tr>
          <tr>
            <td style="background-color: #cfcf00; border: 1 px solid black; color: white; padding: 8px;">&nbsp;</td>
            <td>{{ $t('yellow') }}</td>
          </tr>
          <tr>
            <td style="background-color: #ff9b00; border: 1 px solid black; color: white; padding: 8px;">&nbsp;</td>
            <td>{{ $t('orange') }}</td>
          </tr>
          <tr>
            <td style="background-color: #ff0000; border: 1 px solid black; color: white; padding: 8px;">&nbsp;</td>
            <td>{{ $t('red') }}</td>
          </tr>
          <tr>
            <td style="border-width: 3px; border-style: dashed; border-color: black; padding: 8px;">{{ $t('tagNr') }}
            </td>
            <td>{{ $t('gps') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="map" id="map"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import moment from "moment";
import routes from "@/assets/routes.json";
export default {
  data() {
    return {
      map: null,
      data: null,
      storIcon: null,
      plotlayers: [],
      mapInterval: null,
      show: "train",
      legend: false,
      location: null,
      mapUpdated: ''
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
    ...mapGetters({
      lang: "getLang",
    }),
  },

  mounted() {
    this.initMap();
  },

  created() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.location = pos;
        if (this.map != null) {
          this.map.setView(
            new L.LatLng(pos.coords.latitude, pos.coords.longitude),
            11
          );
        }
      });
    }
  },

  beforeUnmount() {
    clearTimeout(this.mapInterval);
  },

  methods: {
    initMap() {
      this.map = new L.map("map");
      let osmUrl = "https://osm.tydalsystems.se/hot/{z}/{x}/{y}.png";
      let osmAttrib =
        'Map data (c) <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
      let osm = new L.TileLayer(osmUrl, {
        minZoom: 6,
        maxZoom: 15,
        attribution: osmAttrib,
      });
      if (this.location != null) {
        this.map.setView(
          new L.LatLng(
            this.location.coords.latitude,
            this.location.coords.longitude
          ),
          11
        );
      } else {
        this.map.setView(new L.LatLng(59.968, 15.08792), 6);
      }
      this.map.addLayer(osm);

      L.geoJSON(routes, {
        style: function (feature) {
          return {
            weight: feature.properties.tracks * 2,
            color: "black",
          };
        },
      }).addTo(this.map);

      this.storIcon = L.icon({
        iconUrl: require("@/assets/stor.png"),
        iconSize: [64, 64],
        iconAnchor: [32, 32],
        popupAnchor: [48, 8],
      });
      this.updateData();
    },
    toggle() {
      this.legend = !this.legend;
    },

    setTrain() {
      this.show = "train";
      this.updateMarkers();
    },
    setDestination() {
      this.show = "destination";
      this.updateMarkers();
    },

    updateMarkers() {
      this.removeMarkers();
      let data = this.data;
      for (let i = 0; i < data.length; i++) {
        let latlng = new L.LatLng(data[i].lat, data[i].lng, true);
        let plotmark = new L.Marker(latlng, {
          icon: new L.divIcon({
            className: "none",
            html:
              '<span style="background-color: ' +
              this.getDelayColor(data[i].delay) +
              "; " +
              (data[i].status == 3 ? "font-weight: bold;" : "") +
              (data[i].speed == null
                ? " border-style: solid; border-color: black; border-width: 1px;"
                : "border-style: dashed; border-color: black; border-width: 3px;") +
              ' color: white;">' +
              (this.show == "destination"
                ? data[i].destination
                : this.getText(data[i])) +
              "</span>",
          }),
        });
        var delayText = "";
        if (data[i].delays.length) {
          for (let j = 0; j < data[i].delays.length; j++) {
            delayText +=
              "<br>" +
              data[i].delays[j].location +
              ": " +
              data[i].delays[j].description;
          }
        }
        if (this.$store.state.authenticated && this.$store.state.railway == 'y') {
          plotmark.bindPopup(
            '<a href="/position?tagNr=' +
            data[i].tagNr +
            "&datum=" +
            data[i].depdate +
            "&lang=" +
            this.lang +
            '">' +
            data[i].operator +
            " " +
            data[i].tagNr +
            "</a>" +
            "<br>" +
            data[i].destination +
            "<br>" +
            (data[i].delay == 0
              ? "i tid."
              : (data[i].delay < 0
                ? data[i].delay * -1 + " minut"
                : data[i].delay + " minut") +
              (data[i].delay == 1 || data[i].delay == -1 ? " " : "er ") +
              (data[i].delay < 0 ? "sen." : "tidig.")) +
            (data[i].speed != null ? "<br>" + data[i].speed + " km/h." : "") +
            delayText
          );
        } else if (this.$store.state.authenticated && data[i].slag != "Rst") {
          plotmark.bindPopup(
            data[i].operator +
            " " +
            data[i].tagNr +
            "<br>" +
            data[i].destination +
            "<br>" +
            (data[i].delay == 0
              ? "i tid."
              : (data[i].delay < 0
                ? data[i].delay * -1 + " minut"
                : data[i].delay + " minut") +
              (data[i].delay == 1 || data[i].delay == -1 ? " " : "er ") +
              (data[i].delay < 0 ? "sen." : "tidig.")) +
            (data[i].speed != null ? "<br>" + data[i].speed + " km/h." : "") +
            delayText
          );
        } else {
          plotmark.bindPopup(
            (data[i].slag == "Rst"
              ? '<a href="/position?tagNr=' +
              data[i].tagNr +
              "&datum=" +
              data[i].depdate +
              "&lang=" +
              this.lang +
              '">' +
              data[i].operator +
              " " +
              data[i].atagNr +
              "</a>"
              : data[i].operator) +
            "<br>" +
            data[i].destination +
            "<br>" +
            (data[i].delay == 0
              ? "i tid."
              : (data[i].delay < 0
                ? data[i].delay * -1 + " minut"
                : data[i].delay + " minut") +
              (data[i].delay == 1 || data[i].delay == -1 ? " " : "er ") +
              (data[i].delay < 0 ? "sen." : "tidig.")) +
            (data[i].speed != null
              ? "<br>" + data[i].speed + " km/h."
              : "") +
            delayText
          );
        }
        this.map.addLayer(plotmark);
        this.plotlayers.push(plotmark);
      }
    },

    updateData() {
      this.$axios.get(this.baseUrl + "rt3.php?key=" + this.apiKey).then((res) => {
        if (res.status >= 200 && res.status < 300) {
          this.data = res.data;
          this.updateMarkers();
          this.mapInterval = setTimeout(() => {
            this.updateData();
          }, 30000);
          this.mapUpdated = moment().format('HH:mm:ss');
          this.updateStor();
        }
      });
    },

    getText(tag) {
      if (this.$store.state.authenticated) {
        return tag.tagNr;
      } else {
        if (tag.slag == "Gt") {
          return this.$i18n.t("freight");
        } else if (tag.slag == "Tjt") {
          return "Spec";
        } else {
          return tag.atagNr;
        }
      }
    },

    getDelayColor(delay) {
      if (delay > 4) {
        return "#0000ff";
      } else if (delay > -2) {
        return "green";
      } else if (delay > -6) {
        return "#cfcf00";
      } else if (delay > -16) {
        return "#ff9b00";
      } else {
        return "#ff0000";
      }
    },

    updateStor() {
      this.$axios
        .get(
          this.baseUrl +
          "new_stor3.php?finished=false&lang=" +
          this.lang +
          "&key=" +
          this.apiKey
        )
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            let data = res.data.data;
            for (let i = 0; i < data.length; i++) {
              let latlng = new L.LatLng(
                data[i].lat,
                parseFloat(data[i].lng) + 0.01,
                true
              );
              let plotmark = new L.marker(latlng, {
                icon: this.storIcon,
                zIndexOffset: 999,
              });
              plotmark.bindPopup(
                "<b>" +
                data[i].location +
                "</b> (" +
                data[i].type +
                ")<hr>" +
                data[i].msg
              );
              this.map.addLayer(plotmark);
              this.plotlayers.push(plotmark);
            }
          }
        });
    },

    removeMarkers() {
      for (var i = 0; i < this.plotlayers.length; i++) {
        this.map.removeLayer(this.plotlayers[i]);
      }
      this.plotlayers = [];
    },

    getColor(value) {
      if (value < 0.1) {
        return "green";
      }
      if (value < 0.2) {
        return "#aef463";
      }
      if (value < 0.3) {
        return "#fdf40c";
      }
      if (value < 0.4) {
        return "#fdcd0c";
      }
      if (value < 0.5) {
        return "orange";
      }
      if (value < 0.7) {
        return "#fd700c";
      }
      return "red";
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-legend {
  border: 1px solid #707070;
  margin-bottom: 8px;
  border-radius: 8px;
}

.map {
  height: 1000px;
}

td {
  padding: 8px;
}

tr {
  padding: 4px;
}

#fullscreen {
  float: right;
  color: var(--foreground);
}

.bi-arrows-angle-expand {
  font-size: 20px;
}
@media (max-width: 575.98px) {
  .btn-legend {
    font-size: 12px;
    padding:9px;
  }
}
</style>

