<template>
  <div class="trafic-messages" :class="lang">
    <p class="h5" style="display: inline">
      <i class="bi bi-bell-fill"></i>
      {{ $t("disruptions.title") }}
    </p>
    <p style="float: right" v-if="$store.state.authenticated">
      <router-link :to="{ name: 'eventArchive', params: { date: getToday() } }"
        >Arkiv</router-link
      >
    </p>
    <div class="table-responsive-md">
      <table class="table table-striped" v-if="messages.length > 0">
        <thead>
          <tr>
            <th scope="col">&nbsp;</th>
            <th scope="col">{{ $t("disruptions.starttime") }}</th>
            <th scope="col">{{ $t("disruptions.endtime") }}</th>
            <th scope="col">{{ $t("disruptions.msg") }}</th>
          </tr>
        </thead>
        <tbody v-if="messages.length > 0">
          <tr v-for="(msg, index) in messages" :key="index">
            <td class="stats">
              <span v-if="msg.stats.length > 0" @click="showModal(index)">
                <i class="bi bi-graph-up-arrow"></i>
              </span>
            </td>
            <td>{{ timeFormat(msg.start) }}</td>
            <td>
              {{
                msg.bertid.length > 0 ? "Prognos " + timeFormat(msg.bertid) : ""
              }}
            </td>
            <td
              v-if="$store.state.railway == 'y'"
              v-html="
                '<b>' + msg.location + '</b> ' + msg.msg + ' (' + msg.code + ')'
              "
            ></td>
            <td v-if="$store.state.railway != 'y'">{{ msg.msg }}</td>
          </tr>
        </tbody>
      </table>
      <span v-if="messages.length === 0">
        <hr />
        <p>{{ $t("disruptions.none") }}</p>
      </span>
    </div>

    <modal ref="refGraph" :title="$t('diagram.title')" v-if="visible">
      <p>{{ $t("diagram.content") }}</p>
      <div class="charts">
        <stats-graph :stats="stats" :start="start" />
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import StatsGraph from "@/components/StatsGraph";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  props: {
    messages: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      start: null,
      stats: [],
      visible: false,
    };
  },

  components: {
    Modal,
    StatsGraph,
  },

  computed: {
    ...mapGetters({
      lang: "getLang",
    }),
  },

  methods: {
    timeFormat(date) {
      if (moment(date).format("YYYY-MM-DD") != this.getToday()) {
        return moment(date).format("D/M") + " " + moment(date).format("HH:mm");
      } else {
        return moment(date).format("HH:mm");
      }
    },

    getToday() {
      return moment().format("YYYY-MM-DD");
    },

    showModal(selectedIndex) {
      this.start = this.messages[selectedIndex].start;
      this.stats = this.messages[selectedIndex].stats;
      this.visible = true;
      setTimeout(() => {
        this.$refs.refGraph.open();
      }, 100);
    },

    hideModal() {
      this.visible = false;
      // this.$refs.refGraph.close()
    },
  },
};
</script>

<style lang="scss">
a:link {
  color: grey;
}

a:visited {
  color: grey;
}

a:hover {
  color: rgb(81, 80, 80);
}

a:active {
  color: rgb(91, 91, 91);
}

.trafic-messages {
  background: var(--background-color-secondary);
  padding: 15px;

  table {
    margin-top: 15px;
    border-top: 0;
    color: var(--text-primary-color);

    tbody {
      tr {
        span {
          cursor: pointer;

          .bi {
            font-size: 25px;

            &:hover {
              color: #ffc107;
            }
          }
        }
      }
    }
  }

  .statistic {
    .modal {
      padding: 0 15px;
    }
  }

  .charts {
    overflow: auto;
  }

  @media (max-width: 575.98px) {
    .table {
      thead th {
        font-size: 0.75rem;
        font-weight: 600;
        padding: 5px;
      }

      tbody {
        th {
          font-size: 12px;
        }

        tr {
          td {
            padding: 5px;
          }

          .stats {
            padding: 5px 7px;

            span .bi {
              font-size: 20px;
            }
          }
        }

        tr > th {
          padding: 5px;
        }
      }
    }
  }

  @media (min-width: 576px) {
  }

  @media (max-width: 767.98px) {
    .statistic {
      .modal {
        padding: 0 15px;

        .modal-dialog {
          max-width: 100% !important;
        }
      }
    }
  }

  @media (max-width: 1199.98px) {
    .h4 {
      font-size: 17px;
    }

    .table {
      th {
        font-size: 14px;
      }
    }
  }
}

.trafic-messages.ti {
  .h4 {
    font-size: 1rem;
  }

  table {
    tr th {
      font-size: 12px;
    }
  }
}
</style>
