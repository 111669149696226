<template>
  <div class="map" id="map">
    <div id="logo">
      <router-link :to="{ name: 'home' }" id="fullscreen">
        <i class="bi bi-arrows-angle-contract"></i>
      </router-link>
    </div>
    <div style="position: fixed; bottom: 0; left: 0; z-index: 999">
      <div
        class="btn-group btn-group-toggle"
        data-toggle="buttons"
        style="margin-bottom: 8px"
      >
        <label
          class="btn btn-outline-secondary"
          v-bind:class="[show == 'train' ? 'active' : '']"
        >
          <input
            type="radio"
            v-model="show"
            name="show"
            id="train"
            value="train"
            autocomplete="off"
            checked
            @click="setTrain"
          />
          {{ $t("tagNr") }}
        </label>
        <label
          class="btn btn-outline-secondary"
          v-bind:class="[show == 'destination' ? 'active' : '']"
        >
          <input
            type="radio"
            v-model="show"
            name="show"
            value="destination"
            id="destination"
            autocomplete="off"
            @click="setDestination"
          />
          {{ $t("trainStation") }}
        </label>
        <small style="margin-left: 8px"
          >{{ $t("curUpdate") }} {{ mapUpdated }}</small
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import moment from "moment";
import routes from "@/assets/routes.json";
export default {
  data() {
    return {
      map: null,
      data: null,
      signalLayer: null,
      storIcon: null,
      plotlayers: [],
      mapInterval: null,
      show: "train",
      legend: false,
      mapUpdated: "",
      location: null,
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
    ...mapGetters({
      lang: "getLang",
    }),
  },

  mounted() {
    this.initMap();
  },

  created() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.location = pos;
        if (this.map != null) {
          this.map.setView(
            new L.LatLng(pos.coords.latitude, pos.coords.longitude),
            11
          );
        }
      });
    }
  },

  beforeUnmount() {
    clearTimeout(this.mapInterval);
  },

  methods: {
    initMap() {
      this.map = L.map("map", { zoomAnimation: false });
      let osmUrl = "https://osm.tydalsystems.se/hot/{z}/{x}/{y}.png";
      let osmAttrib =
        'Map data (c) <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
      let osm = new L.TileLayer(osmUrl, {
        minZoom: 6,
        maxZoom: 19,
        attribution: osmAttrib,
      });
      if (this.location != null) {
        this.map.setView(
          new L.LatLng(
            this.location.coords.latitude,
            this.location.coords.longitude
          ),
          11
        );
      } else {
        this.map.setView(new L.LatLng(59.968, 15.08792), 6);
      }
      this.map.addLayer(osm);
      if (
        !this.$store.state.authenticated ||
        this.$store.state.railway != "y"
      ) {
        L.geoJSON(routes, {
          style: function (feature) {
            return {
              weight: feature.properties.tracks * 2,
              color: "black",
            };
          },
        }).addTo(this.map);
      }
      this.storIcon = L.icon({
        iconUrl: require("@/assets/stor.png"),
        iconSize: [64, 64],
        iconAnchor: [32, 32],
        popupAnchor: [48, 8],
      });
      this.updateData();
    },
    toggle() {
      this.legend = !this.legend;
    },

    setTrain() {
      this.show = "train";
      this.updateMarkers();
    },
    setDestination() {
      this.show = "destination";
      this.updateMarkers();
    },

    updateMarkers() {
      this.removeMarkers();
      let data = this.data;
      for (let i = 0; i < data.length; i++) {
        let latlng = new L.LatLng(data[i].lat, data[i].lng, true);
        let plotmark = new L.Marker(latlng, {
          icon: new L.divIcon({
            className: "none",
            html:
              '<span style="background-color: ' +
              this.getDelayColor(data[i].delay) +
              "; " +
              (data[i].status == 3 ? "font-weight: bold;" : "") +
              (data[i].speed == null
                ? " border-style: solid; border-color: black; border-width: 1px;"
                : "border-style: dashed; border-color: black; border-width: 3px;") +
              ' color: white;">' +
              (this.show == "destination"
                ? data[i].destination
                : this.getText(data[i])) +
              "</span>",
          }),
        });
        var delayText = "";
        if (data[i].delays.length) {
          for (let j = 0; j < data[i].delays.length; j++) {
            delayText +=
              "<br>" +
              data[i].delays[j].location +
              ": " +
              data[i].delays[j].description;
          }
        }

        if (
          this.$store.state.authenticated &&
          this.$store.state.railway == "y"
        ) {
          plotmark.bindPopup(
            '<a href="/position?tagNr=' +
              data[i].tagNr +
              "&trainID=" +
              data[i].trainID +
              "&datum=" +
              data[i].depdate +
              "&lang=" +
              this.lang +
              '">' +
              data[i].operator +
              " " +
              data[i].tagNr +
              "</a>" +
              "<br>" +
              data[i].destination +
              "<br>" +
              (data[i].delay == 0
                ? "i tid."
                : (data[i].delay < 0
                    ? data[i].delay * -1 + " minut"
                    : data[i].delay + " minut") +
                  (data[i].delay == 1 || data[i].delay == -1 ? " " : "er ") +
                  (data[i].delay < 0 ? "sen." : "tidig.")) +
              (data[i].speed != null ? "<br>" + data[i].speed + " km/h." : "") +
              delayText
          );
        } else if (this.$store.state.authenticated) {
          plotmark.bindPopup(
            data[i].operator +
              " " +
              data[i].tagNr +
              "<br>" +
              data[i].destination +
              "<br>" +
              (data[i].delay == 0
                ? "i tid."
                : (data[i].delay < 0
                    ? data[i].delay * -1 + " minut"
                    : data[i].delay + " minut") +
                  (data[i].delay == 1 || data[i].delay == -1 ? " " : "er ") +
                  (data[i].delay < 0 ? "sen." : "tidig.")) +
              (data[i].speed != null ? "<br>" + data[i].speed + " km/h." : "") +
              delayText
          );
        } else {
          plotmark.bindPopup(
            (data[i].slag == "Rst"
              ? '<a href="/position?tagNr=' +
                data[i].tagNr +
                "&datum=" +
                data[i].depdate +
                "&lang=" +
                this.lang +
                '">' +
                data[i].operator +
                " " +
                data[i].atagNr +
                "</a>"
              : data[i].operator) +
              "<br>" +
              data[i].destination +
              "<br>" +
              (data[i].delay == 0
                ? "i tid."
                : (data[i].delay < 0
                    ? data[i].delay * -1 + " minut"
                    : data[i].delay + " minut") +
                  (data[i].delay == 1 || data[i].delay == -1 ? " " : "er ") +
                  (data[i].delay < 0 ? "sen." : "tidig.")) +
              (data[i].speed != null ? "<br>" + data[i].speed + " km/h." : "") +
              delayText
          );
        }
        this.map.addLayer(plotmark);
        this.plotlayers.push(plotmark);
      }
    },

    updateData() {
      this.$axios
        .get(this.baseUrl + "rt3.php?key=" + this.apiKey)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            this.data = res.data;
            this.updateMarkers();
            this.mapInterval = setTimeout(() => {
              this.updateData();
            }, 30000);
            this.mapUpdated = moment().format("HH:mm:ss");
            this.updateStor();
            if (
              this.$store.state.authenticated &&
              this.$store.state.railway == "y"
            ) {
              this.updateSignals();
            }
          }
        });
    },

    getText(tag) {
      if (this.$store.state.authenticated) {
        return tag.tagNr;
      } else {
        if (tag.slag == "Gt") {
          return this.$i18n.t("freight");
        } else if (tag.slag == "Tjt") {
          return "Spec";
        } else {
          return tag.atagNr;
        }
      }
    },

    getDelayColor(delay) {
      if (delay > 4) {
        return "#0000ff";
      } else if (delay > -2) {
        return "green";
      } else if (delay > -6) {
        return "#cfcf00";
      } else if (delay > -16) {
        return "#ff9b00";
      } else {
        return "#ff0000";
      }
    },

    updateStor() {
      this.$axios
        .get(
          this.baseUrl +
            "new_stor3.php?finished=false&lang=" +
            this.lang +
            "&key=" +
            this.apiKey
        )
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            let data = res.data.data;
            for (let i = 0; i < data.length; i++) {
              let latlng = new L.LatLng(
                data[i].lat,
                parseFloat(data[i].lng) + 0.01,
                true
              );
              let plotmark = new L.marker(latlng, {
                icon: this.storIcon,
                zIndexOffset: 999,
              });
              plotmark.bindPopup(
                "<b>" +
                  data[i].location +
                  "</b> (" +
                  data[i].type +
                  ")<hr>" +
                  data[i].msg
              );
              this.map.addLayer(plotmark);
              this.plotlayers.push(plotmark);
            }
          }
        });
    },

    updateSignals() {
      this.$axios
        .get(this.baseUrl + "signalstatus.php?key=" + this.apiKey)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            let data = res.data;
            let layer = new L.geoJSON(data, {
              style: function (feature) {
                return {
                  color:
                    feature.properties.status == "O" ? "#ea0405" : "#05ea04",
                };
              },
              onEachFeature: function (feature, ly) {
                ly.setText(feature.properties.tagNr);
              },
            });
            if (this.signalLayer != null) {
              this.map.removeLayer(this.signalLayer);
            }
            this.signalLayer = layer;
            this.map.addLayer(this.signalLayer);
          }
        });
    },

    removeMarkers() {
      for (var i = 0; i < this.plotlayers.length; i++) {
        this.map.removeLayer(this.plotlayers[i]);
      }
      this.plotlayers = [];
    },

    getColor(value) {
      if (value < 0.1) {
        return "green";
      }
      if (value < 0.2) {
        return "#aef463";
      }
      if (value < 0.3) {
        return "#fdf40c";
      }
      if (value < 0.4) {
        return "#fdcd0c";
      }
      if (value < 0.5) {
        return "orange";
      }
      if (value < 0.7) {
        return "#fd700c";
      }
      return "red";
    },
  },
};
</script>

<style lang="scss" scoped>
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
#logo {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  padding: 4px;
}
#fullscreen {
  color: black;
  .bi {
    font-size: 24px;
    margin-right: 15px;
  }
}
</style>
