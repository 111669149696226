<template>
  <div class="dropdown lang">
    <button
      class="btn btn-light dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img
        :src="selected.img"
        class="flag mr-2"
        alt=""
        width="30"
        height="30"
      />
      <span>{{ selected.lang ? selected.lang : "Välj språk" }}</span>
    </button>
    <ul class="dropdown-menu">
      <li>
        <a
          class="dropdown-item"
          href="#"
          v-for="(lang, index) in langs"
          :key="index"
          @click="selectLang(lang)"
          ><img :src="lang.img" alt="" width="30" height="30" />
          {{ lang.lang }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      selected: {},
    };
  },

  computed: {
    ...mapState(["lang", "langs"]),
  },

  created() {
    this.setLanguage();
  },

  methods: {
    selectLang(selectedLang) {
      this.$i18n.locale = selectedLang.code;
      this.selected = selectedLang;
      this.$store.commit("SET_LOCAL_LANGUAGE", selectedLang.code);
      this.$emit("selected", selectedLang);
    },

    setLanguage() {
      if (this.langs) {
        this.langs.filter((language) => {
          if (language.code == this.lang) {
            this.$i18n.locale = language.code;
            this.selected = language;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.lang {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .dropdown-toggle {
    background: transparent !important;
    color: var(--main-title-color);
    font-weight: 600;
  }

  .flag {
    max-width: 30px;
    margin-right: 10px;
  }

  ls {
    list-style: none;
  }

  .dropdown-toggle {
    background: #fff;
    border: 0;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .dropdown-menu {
    background: var(--background-color-secondary);
    border-radius: 0;
    min-width: 13rem;
    border: 0;
    box-shadow: 1px 1px 1px 1px rgba(52, 58, 64, 0.24);
    width: 210px;

    .dropdown-item {
      margin-left: 0;
      color: var(--text-primary-color);
      margin-bottom: 5px;

      img {
        margin-right: 7px;
      }
    }

    .dropdown-item:hover {
      background: var(--dropdown-hover-color);
    }

    .dropdown-item.ar {
      font-size: 18px;
    }

    .dropdown-item.ti {
      font-size: 15px;
    }
  }

  .nav-item {
    list-style: none;

    .nav-link {
      font-size: 16px;
      font-weight: 600;
      color: var(--link-hover-color);
      padding: 0.5rem;

      span {
        font-size: 15px;
        font-weight: 600;
        color: var(--link-hover-color);
      }
    }
  }
}
</style>


